import { Button, Col, Form, FormInstance, FormProps, Input, Row, Select } from "antd";
import React, { useState } from "react";
import { Scalars } from "../../models";
import { ApplicantSelect } from "../form/ApplicantSelect";
import { JobPackageSelect } from "../form/JobPackageSelect";
import { JobPackageTaskSelect } from "../form/JobPackageTaskSelect";
import { SupervisorSelect } from "../form/SupervisorSelect";

export type TrialdayFormData = {
    date: Scalars["Date"]
    jobpackage: string
    additionalinformation: string
    applicant: string
    supervisors: string[]
    tasklist: string
}

interface OuterProps {
    formtype: "edit" | "create"
}

type Props = OuterProps & FormProps<TrialdayFormData>

export const TrialdayForm = React.forwardRef<FormInstance, Props>((props, ref) => {

    
    const [jobpackageId, setjobpackageId] = useState('')

    const onJobPackageChange = (value: string) => {
        setjobpackageId(value as string)
        
        if (ref && (ref as any).current) {
            const formInstance = (ref as any).current as FormInstance
            formInstance.resetFields(['tasklist'])
        }
    }

    return (
        <Form ref={ref} {...props}>
            <Row>
                <Col lg={8} xs={24}>
                    <Form.Item
                        className="wr-styled-input"
                        name="date"
                        label="Date"
                        rules={[{
                            required: true,
                            message: "Date is required!"
                    }]} >
                        <Input autoFocus type="date" />
                    </Form.Item>
                </Col>
                <Col lg={8} xs={24}>
                    <Form.Item
                        className="wr-styled-input"
                        name="jobpackage"
                        label="Job Package"
                        rules={[{
                            required: true,
                            message: "Job Package is required!"
                        }]} >
                            <JobPackageSelect onChange={onJobPackageChange} />
                    </Form.Item>
                </Col>
                <Col lg={8} xs={24}>
                    <Form.Item
                        className="wr-styled-input"
                        name="tasklist"
                        label="Task"
                        rules={[{
                            required: true,
                            message: "Task is required!"
                        }]} >
                            <JobPackageTaskSelect 
                            disabled={jobpackageId === ''}
                            jobpackage={jobpackageId} />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
            <Col lg={8} xs={24}>
                    <Form.Item
                        className="wr-styled-input"
                        name="applicant"
                        label="Applicant"
                        rules={[{
                            required: true,
                            message: "Applicant is required!"
                        }]} >
                            <ApplicantSelect/>
                    </Form.Item>
                </Col>
                <Col lg={8} xs={24}>
                    <Form.Item
                        className="wr-styled-input"
                        name="supervisors"
                        label="Supervisors"
                        rules={[{
                            required: true,
                            message: "Supervisor is required!"
                        }]} >
                            <SupervisorSelect mode="multiple" />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col lg={24} xs={24}>
                    <Form.Item
                        className="wr-styled-input"
                        name="additionalinformation"
                        label="Additional Information" >
                        <Input.TextArea  />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            { props.formtype === "edit" ? "Edit Trial Day" : "Create Trial Day"}
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
})