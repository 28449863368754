import { Layout, PageHeader, Typography } from "antd";
import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { UserInfoProps, withUserInfo } from "../models";
import './Unauthorized.less';


const { Sider, Content, Header } = Layout;

interface OuterProps {
    title: string
}

interface State {
    path: string[]
}

type Props = OuterProps & UserInfoProps & RouteComponentProps;

class UnauthorizedLayout extends React.Component<Props, State> {

    state: State = {
        path: ["/"]
    };

    private renderContent = () => {
        const { children } = this.props;
        if (children) {
          return children;
        }
        return;
      };

    render () {
        const { title, history } = this.props;

        return (
            <Layout>
                <Sider className="base-sider">
                    <Header className="logo-wrapper">
                        <Link to="/home">
                            <img 
                                className="logo" 
                                src="/webrunners_logo.svg" 
                                alt="Webrunners GmbH Logo" 
                            />
                        </Link>
                    </Header>                        
                </Sider>
                <Layout className="base-layout">
                    <PageHeader
                        className="unauthorized-header"
                        onBack={() => {history.goBack()}}
                        title={title}
                    />
                    <Content>{this.renderContent()}</Content>
                </Layout>
            </Layout>
            )
        }
}

export default compose<Props, OuterProps>(
    withUserInfo(),
    withRouter
)(UnauthorizedLayout);