import { Button, notification } from "antd";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import SetPasswordForm from "../../components/auth/SetPasswordForm";
import ResultComponent from "../../components/base/ResultComponent";
import { apolloResponseErrorHandler } from "../../helper/errors";
import { AuthLayout } from "../../layouts/AuthLayout";
import { UseResetProps, withUseReset } from "../../models";

interface RouteParams {
    slug: string
}

interface State {
    passwordSet: boolean | undefined
}

interface OuterProps { }

type Props = OuterProps & RouteComponentProps<RouteParams> & UseResetProps;

class PasswordSetView extends React.Component<Props, State> {

    state: State = {
        passwordSet: undefined
    }

    private onFormFinish = async (values: { password: string, passwordConfirm: string }) => {
        const { mutate } = this.props
        const { password, passwordConfirm } = values;

        if (password === passwordConfirm) {
            const response = await mutate({
                variables: {
                    password: password,
                    slug: this.props.match.params.slug
                }
            }).catch(apolloResponseErrorHandler);

            if (response.data?.useReset?.user) {
                this.setState({
                    passwordSet: true
                });
            } else {
                this.setState({
                    passwordSet: false
                })
            }
        } else {
            notification.error({
                key: "match-password",
                message: "Passwords must match!"
            });
        }
    }

    private renderContent = () => {
        const { passwordSet } = this.state;

        switch (passwordSet) {
            case true:
                return (
                    <ResultComponent
                        title="Password set successfully!"
                        type="success"
                        extras={[
                            <Button type="primary" onClick={() => this.props.history.push("/auth/login")}>Go to Login</Button>
                        ]} />
                );
            case false:
                return (
                    <ResultComponent
                        title="Invalid URL. Try to generate a new Link!"
                        type="error" />
                );
            default:
                return <SetPasswordForm onFinish={this.onFormFinish} />;
        }
    }

    render() {
        return <AuthLayout title="Set new Password">
            {this.renderContent()}
        </AuthLayout>
    }
}

export default compose<Props, OuterProps>(
    withRouter,
    withUseReset()
)(PasswordSetView);