import { Button, Col, Form, FormInstance, FormProps, Input, Row, Upload } from 'antd'
import React from 'react'
import { Scalars } from '../../models'
import { IconUpload } from '../form/IconUpload'

export type ServiceFormData = {
    name: string
    url: string
    icon?: Scalars['Upload']
}

interface OuterProps {
    formtype: "edit" | "create"
}

type Props = OuterProps & FormProps<ServiceFormData>

export const ServiceForm = React.forwardRef<FormInstance, Props>((props, ref) => {

    return <Form<ServiceFormData> ref={ref} {...props}>
        <Row>
            <Col lg={8} xs={24}>
                <Form.Item
                    className="wr-styled-input"
                    name="name"
                    label="Name"
                    rules={[{
                        required: true
                    }]} >
                    <Input autoFocus type="text" placeholder="Service Name" />
                </Form.Item>
            </Col>
            <Col lg={8} xs={24}>
                <Form.Item
                    className="wr-styled-input"
                    name="url"
                    label="URL"
                    rules={[{
                        required: true
                    }]}>
                    <Input autoFocus type="url" placeholder="URL" />
                </Form.Item>
            </Col>
            {/* TODO: Implement Upload Backend */}
            {/* <Col lg={8} xs={24}>
                <Form.Item
                    className="wr-styled-input"
                    name="icon"
                    label="Icon">
                    <IconUpload />
                </Form.Item>
            </Col> */}
        </Row>
        <Row>
            <Col>
                <Form.Item>
                    <Button type="primary" htmlType="submit">
                        { props.formtype === "edit" ? "Update Service" : "Create Service" }
                    </Button>
                </Form.Item>
            </Col>
        </Row>
    </Form >
})
