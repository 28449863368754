
import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { apolloResponseErrorHandler } from "../../../helper/errors";
import { withAuthentication } from "../../../hoc/isAuthendicated";
import { AllApplicantsProps, withAllApplicants, RequestResetProps, withRequestReset, AnonymizeUserProps, withAnonymizeUser, AllApplicantsDocument, DeleteUserProps,withDeleteUser, InviteUserProps, withInviteUser } from "../../../models";
import { SortOrder } from "antd/lib/table/interface";
import { Button, notification, Popconfirm, Table, Typography } from "antd";
import ApCard from "../../base/ap-card";
import { DeleteOutlined, EditOutlined, EyeOutlined, UserDeleteOutlined } from "@ant-design/icons";
import ApplicantAdminActionSelect from "./ApplicantAdminActionSelect";

interface State {
    selectedRowKeys: React.Key[],
    actionValue?: string
}

interface OuterProps {}

type Props = OuterProps & AllApplicantsProps & RouteComponentProps & RequestResetProps<void, "requestReset"> & AnonymizeUserProps<void, "anonymizeUser"> & DeleteUserProps<void, "deleteUser"> & InviteUserProps<void, "inviteUser">;

class AllApplicantList extends React.Component<Props, State> {

    state: State = {
        selectedRowKeys: [],
        actionValue: undefined
    }

    private onActionSelect = (value: string) => {
        this.setState({
            actionValue: value
        });
    }

    private onActionClear = () => {
        this.setState({
            actionValue: undefined
        })
    }

    private inviteAction = async (selectedRowKey: React.Key) => {
        const { inviteUser } = this.props;
        const response = await inviteUser({
            variables: {
                email: selectedRowKey.toString()
            }
        }).catch(apolloResponseErrorHandler)

        if ( response.data?.inviteUser?.response ) {
            notification.success({
                key: "invite-user-success",
                message: "User has been invited successfully!"
            })
        }
    }

    private passwordResetAction = async (selectedRowKeys: React.Key) => {
        const { requestReset } = this.props;
        const response = await requestReset({
            variables: {
                email: selectedRowKeys.toString()
            }
        }).catch(apolloResponseErrorHandler);

        if ( response.data?.requestReset?.response ) {
            notification.success({
                key: "request-reset-success",
                message: "Password reset E-Mail has been send!"
            })
        }
    }

    private findUser = (email: string) => {
        const { data: { getAllApplicants }} = this.props;

        return getAllApplicants?.find(item => item?.email === email)
    }

    private deleteUserAction = async (selectedRowKeys: React.Key) => {
        const { deleteUser } = this.props;

        const user = this.findUser(selectedRowKeys as string);

        const response = await deleteUser({
            variables: {
                pk: user!.pk
            },
            refetchQueries: [
                {
                    query: AllApplicantsDocument
                }
            ]
        }).catch(apolloResponseErrorHandler);

        if ( response.data?.deleteUser?.found ) {
            notification.success({
                key: "user-deleted",
                message: "User deleted!"
            })
        }
    }

    private onActionButtonClick = () => {
        const { actionValue, selectedRowKeys } = this.state;

        switch (actionValue) {
            case "invite":
                this.inviteAction(selectedRowKeys[0])
                break;
            case "password-reset":
                this.passwordResetAction(selectedRowKeys[0])
                break;
            case "delete":
                this.deleteUserAction(selectedRowKeys[0])
        }

        this.setState({
            selectedRowKeys: [],
            actionValue: undefined
        })
    }

    private handleAnonymize = async (email: string) => {
        const { anonymizeUser } = this.props;

        const response = await anonymizeUser({
            variables: {
                email: email
            },
            refetchQueries: [
                {
                    query: AllApplicantsDocument
                }
            ]
        }).catch(apolloResponseErrorHandler);

        if ( response.data?.anonymizeUser?.response ) {
            notification.success({
                key: "anonymize-applicant",
                message: "User anonymized!"
            })
        }

    }

    private tableColumns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            defaultSortOrder: 'ascend' as SortOrder,
            sorter: (a: any, b: any) => a.name.length - b.name.length
        },
        {
            title: "Email address",
            dataIndex: "email",
            key: "email",
            render: (email: string) => <Typography.Paragraph copyable={{ tooltips: [ 'Click here to copy', 'Copied email']}}>{email}</Typography.Paragraph>
        },
        {
            title: "Active",
            dataIndex: "active",
            key: "active",
            render: (active: boolean) => <Typography.Text>{active ? "Yes" : "No"}</Typography.Text>
        },
        {
            title: "View",
            dataIndex: "view",
            key: "view",
            render: (pk: string) => <Link to={`/applicants/detail/${pk}`}><EyeOutlined/></Link>
        },
        {
            title: "Edit",
            dataIndex: "edit",
            key: "edit",
            render: (pk: string) => <Link to={`/applicants/detail/${pk}/edit`}><EditOutlined/></Link>
        },
        {
            title: "Anonymize",
            dataIndex: "anonymize",
            key: "anonymize",
            render: (email: string) => {
                return (
                    <Popconfirm
                    title="Are you sure to delete the user?"
                    onConfirm={() => this.handleAnonymize(email)}
                    okText="Anonymize"
                    cancelText="Cancel" >
                        <UserDeleteOutlined />
                    </Popconfirm>
                );
            }
        }
    ];

    private onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        this.setState({
            selectedRowKeys: newSelectedRowKeys
        });
    }

    render () {
        const { data: { loading, getAllApplicants }} = this.props;
        const { selectedRowKeys, actionValue } = this.state;

        if ( loading ) {
            return <>Loading</>
        } else if ( getAllApplicants ) {
            const dataSource = getAllApplicants.map(item => ({
                key: item?.email,
                name: item?.firstName + " " + item?.lastName,
                email: item?.email,
                active: item?.isActive,
                view: item?.pk,
                edit: item?.pk,
                anonymize: item?.email
            }))
            return (
                <ApCard title="All Applicants" link={{text: "Create Applicant", to: { to: "/applicants/create-new" }}}>
                    <div className="wr-action-select-wrapper">
                        <ApplicantAdminActionSelect onSelect={this.onActionSelect} onClear={this.onActionClear} />
                        <Button
                        type="default"
                        disabled={actionValue && selectedRowKeys.length > 0 ? false : true}
                        onClick={this.onActionButtonClick}>Go</Button>
                    </div>
                    <Table
                    rowSelection={{
                        selectedRowKeys: this.state.selectedRowKeys,
                        type: 'radio',
                        onChange: this.onSelectChange
                    }}
                    columns={this.tableColumns}
                    dataSource={dataSource}
                    pagination={false}
                    className="ap-table" />
                </ApCard>
                
            )
        } else { return <>Something went wrong in all applicants list component</>}
    }
}

export default compose<Props, OuterProps>(
    withAuthentication(),
    withRouter,
    withAllApplicants({
        options: (props: Props) => ({
            onError: (err) => {
                const [graphError] = err.graphQLErrors;
                if (graphError && graphError.name === "PermissionDenied") {
                    apolloResponseErrorHandler(err)
                    props.history.push("/home");
                } else {
                    apolloResponseErrorHandler(err);
                }
            }
        })
    }),
    withInviteUser({
        name: "inviteUser"
    }),
    withRequestReset({
        name: "requestReset"
    }),
    withDeleteUser({
        name: "deleteUser"
    }),
    withAnonymizeUser({
        name: "anonymizeUser"
    }),
    withInviteUser({
        name: "inviteUser"
    })
    )(AllApplicantList);