import { FormInstance, notification } from "antd";
import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { compose } from "recompose";
import ApCard from "../../components/base/ap-card";
import { ServiceForm, ServiceFormData } from "../../components/services/ServiceForm";
import { apolloResponseErrorHandler } from "../../helper/errors";
import { withAuthentication } from "../../hoc/isAuthendicated";
import BaseLayout from "../../layouts/BaseLayout";
import { ServiceDocument, UpdateSingleServiceProps, useServiceQuery, useUpdateSingleServiceMutation } from "../../models";

interface RouteParams {
    id: string
}

interface OuterProps {}

type Props = OuterProps & RouteComponentProps<RouteParams> & UpdateSingleServiceProps;

const EditServiceView: React.FC<Props> = ({ ...props }) => {
    const formRef = React.useRef<FormInstance<ServiceFormData>>();
    const { id } = props.match.params;

    const query = useServiceQuery({
        variables: {
            id: id
        },
        onCompleted: (data) => {
            if ( ! formRef.current || ! data.service ) return

            const { name, url } = data.service;
            formRef.current.setFieldsValue({
                name,
                url
            })
        }
    })

    const [ mutate ] = useUpdateSingleServiceMutation({
        refetchQueries: [{ query: ServiceDocument, variables: { id: id }}],
        onCompleted: () => {
            notification.success({
                key: "edit-service-success",
                message: "Service has been updated successfully!"
            })
            props.history.push(`/services/detail/${id}`)
        },
        onError: err => apolloResponseErrorHandler(err)
    })

    useEffect(() => {
        if ( query.data?.service && formRef.current ) formRef.current.setFieldsValue({
            name: query.data.service.name,
            url: query.data.service.url
        })
    }, [formRef.current])

    const onFormFinish = (formData: ServiceFormData) => {
        mutate({
            variables: {
                id: id,
                input: {
                    name: formData.name,
                    url: formData.url
                }
            }
        })
    }

    return (
        <BaseLayout>
            <ApCard title="Edit Service">
                <ServiceForm
                formtype="edit" 
                ref={formRef as React.RefObject<FormInstance>} 
                onFinish={onFormFinish} />
            </ApCard>
        </BaseLayout>
    )
}

export default compose<Props, OuterProps>(
    withAuthentication()
)(EditServiceView)