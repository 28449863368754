import React from "react";
import { Login } from "../../components/auth/Login";

import { AuthLayout } from "../../layouts/AuthLayout";

const LoginView: React.FC = () => {
    return (
        <AuthLayout title="Login Form">
            <Login />
        </AuthLayout>
    )
}

export default LoginView