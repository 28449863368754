import { Descriptions, Empty } from "antd";
import React from "react";
import { compose } from "recompose";
import { UserServicesProps, UserServiceType, withUserServices } from "../../models";

interface OuterProps {
    userid: string
}

type Props = OuterProps & UserServicesProps

class UserServicesList extends React.Component<Props> {


    render () {
        const { data: { loading, userServices }} = this.props

        console.log(userServices)

        if ( loading ) {
            return <>Loading</>
        } else if ( userServices ) {
            return (
                <Descriptions bordered>
                    {
                        userServices.map( service => (
                            <>
                            <Descriptions.Item label="Service Name">{service?.name}</Descriptions.Item>
                            <Descriptions.Item label="URL">{service?.url}</Descriptions.Item>
                            <Descriptions.Item label="Active">{service?.isActive ? "True" : "False"}</Descriptions.Item>
                            </>
                        ))
                    }
                </Descriptions>
            )
        } else {
            return <>Something went wrong in user services list component!</>
        }

    }
}

export default compose<Props, OuterProps>(
    withUserServices({
        options: (props: Props) => ({
            variables: {
                user_id: props.userid
            }
        })
    })
)(UserServicesList)