import { Col, FormInstance, Row } from "antd";
import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { compose } from "recompose";
import ApCard from "../../../components/base/ap-card";
import { ApplicantForm, ApplicantFormData } from "../../../components/user/recruiter/ApplicantForm";
import { apolloResponseErrorHandler } from "../../../helper/errors";
import { withAuthentication } from "../../../hoc/isAuthendicated";
import BaseLayout from "../../../layouts/BaseLayout";
import { UpdateUserProps, UserDocument, UserRole, useUpdateUserMutation, useUserQuery } from "../../../models";

// getUser query for applicant details

interface RouteParams {
    id: string
}

interface OuterProps {}

type Props = OuterProps & UpdateUserProps & RouteComponentProps<RouteParams>;

const EditApplicantView: React.FC<Props> = ({ ...props }) => {
    const formRef = React.useRef<FormInstance<ApplicantFormData>>();
    const { id } = props.match.params;

    const query = useUserQuery({
        variables: {
            id: id
        },
        onCompleted: (data) => {
            if ( !formRef.current || !data.user ) return
            
            const {firstName, lastName, email, isActive, applicantData, } = data.user
            
            formRef.current.setFieldsValue({
                firstName,
                lastName,
                email,
                active: isActive,
                comment: applicantData?.comment,
                heavenhrUrl: applicantData?.heavenhrUrl
            })
        }
    })

    const [ mutate ] = useUpdateUserMutation({
        refetchQueries: [{ query: UserDocument, variables: { id: id } }],
        onCompleted: () => {props.history.push(`/applicants/detail/${id}`)},
        onError: (err) => apolloResponseErrorHandler(err)
    })

    useEffect(() => {
        if ( query.data?.user && formRef.current ) formRef.current.setFieldsValue(query.data.user)
    }, [formRef.current])

    const onFormFinish = (formData: ApplicantFormData) => {
        const applicantData = {
            heavenhrUrl: formData.heavenhrUrl,
            comment: formData.comment,
            //todo: rendered options needs to be removed - breaking change
            commentRendered: "<p></p>"
        }
        mutate({
            variables: {
                pk: id,
                input: {
                    role: UserRole.Applicant,
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    email: formData.email,
                    isSuperuser: false,
                    isStaff: false,
                    isActive: formData.active,
                    applicantData: applicantData
                }
            }
        })
    }

    return (
        <BaseLayout>
            <Row>
                <Col lg={24} xs={24}>
                    <ApCard title="Edit Applicant">
                        <ApplicantForm
                        formType="edit"
                        isActive={query.data?.user?.isActive || true}
                        onFinish={onFormFinish}
                        ref={formRef as React.RefObject<FormInstance>} />
                    </ApCard>
                </Col>
            </Row>
        </BaseLayout>
    )
}

export default compose<Props, OuterProps>(
    withAuthentication()
)(EditApplicantView)
