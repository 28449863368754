import { LockFilled, MailFilled } from "@ant-design/icons";
import { Button, Form, Input } from "antd";
import Checkbox from "antd/lib/checkbox/Checkbox";
import React from "react";
import { Link } from "react-router-dom";

export interface LoginFormData {
    email: string
    password: string
}

interface OuterProps {
    loading?: boolean
    onFinish?: (data: LoginFormData) => void
}

type Props = OuterProps;

class LoginForm extends React.Component<Props> {

    private onFinish = (data: LoginFormData) => {
        if ( this.props.onFinish) {
            this.props.onFinish(data);
        }
    }

    render () {
        const { loading } = this.props;
        return (
            <Form
                name="login"
                onFinish={this.onFinish}>
                    <Form.Item
                    className="wr-styled-input"
                    name="email"
                    rules={[{required: true, message: "Email address is required!"}]} >
                        <Input autoFocus
                            type="email" 
                            disabled={loading}
                            placeholder="Email address"
                            prefix={<MailFilled />} />
                    </Form.Item>
                    <Form.Item
                    className="wr-styled-input"
                    name="password"
                    rules={[{required: true, message: "Password is required"}]} >
                        <Input.Password 
                            placeholder="Password"
                            disabled={loading}
                            prefix={<LockFilled />} />
                    </Form.Item>
                    <Form.Item 
                    className="form-check" 
                    name="privacy" 
                    valuePropName="checked"
                    rules={[{
                        required: true,
                        message: "Please confirm the AGB"
                    }]}>
                        <Checkbox>I read and agreed the <Link to="/terms-conditions">terms and conditions</Link></Checkbox>
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" htmlType="submit" disabled={loading}>
                            Login
                        </Button>
                    </Form.Item>
                </Form>
        )
    }
}

export default LoginForm;