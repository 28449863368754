import { Descriptions, Typography } from "antd";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import ApCard from "../../components/base/ap-card";
import { withAuthentication } from "../../hoc/isAuthendicated";
import BaseLayout from "../../layouts/BaseLayout";
import { JobPackageProps, withJobPackage } from "../../models";

interface RouteParams {
    id: string
}

interface OuterProps {}

type Props = OuterProps & RouteComponentProps<RouteParams> & JobPackageProps;

class JobPackageDetailView extends React.Component<Props> {
    
    private renderContent = () => {
        const { data: { getJobPackage, loading }} = this.props;

        if ( loading ) {
            return <>Loading</>
        } else if ( getJobPackage ) {
            return (
                <Descriptions title={getJobPackage.name} column={4} bordered>
                    <Descriptions.Item label="Task Lists" span={4}>{getJobPackage.taskList.map(item => ( <Typography.Text className="job-package-task-item">{item.name}</Typography.Text>))}</Descriptions.Item>
                    <Descriptions.Item label="Pretrialday Description" span={2}>{getJobPackage.preTrialDayDescription}</Descriptions.Item>
                    <Descriptions.Item label="Trialday Description" span={2}>{getJobPackage.trialDayDescription}</Descriptions.Item>
                    <Descriptions.Item label="Checklist" span={4}>{getJobPackage.checklistItems.map(item => (<Typography.Text key={item.pk}>{item.description}</Typography.Text>))}</Descriptions.Item>
                </Descriptions>
            )
        } else { return <>Something went wrong in Job Package detail view component!</>}
    }
    
    render () {
        const { getJobPackage } = this.props.data
        return (
            <BaseLayout>
                <ApCard title="Job Package Detail" link={{text: "Edit Job Package", to: { to: `/job-packages/detail/${getJobPackage?.pk}/edit` }}}>
                    {this.renderContent()}
                </ApCard>
            </BaseLayout>
        )
    }
}

export default compose<Props, OuterProps>(
    withAuthentication(),
    withJobPackage({
        options: (props: Props) => ({
            variables: {
                id: props.match.params.id
            }
        })
    }),
    withRouter
)(JobPackageDetailView);