import { Select, SelectProps } from "antd";
import React from "react";
import { useAllJobPackagesQuery } from "../../models";

type Props = SelectProps<"mode">

export const JobPackageSelect: React.FC<Props> = ({ ...props }) => {
    const jobPackages = useAllJobPackagesQuery()

    return (
        <Select
        {...props}
        loading={jobPackages.loading}
        options={(jobPackages.data?.getAllJobPackages || []).map((jobpackage) => ({
            label: jobpackage?.name as string,
            value: jobpackage?.pk as string,
            key: jobpackage?.pk as string
        }))} />
    )
}