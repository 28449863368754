import React from "react";
import { compose } from "recompose";
import AllTasksList from "../../components/tasks/AllTasksList";
import { withAuthentication } from "../../hoc/isAuthendicated";
import BaseLayout from "../../layouts/BaseLayout";

interface OuterProps {}

type Props = OuterProps;

class TaskListView extends React.Component {
    render () {
        return (
            <BaseLayout>
                <AllTasksList/>
            </BaseLayout>
        )
    }
}

export default compose<Props, OuterProps>(
    withAuthentication()
)(TaskListView);