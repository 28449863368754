import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { notification, Popconfirm, Table, Typography } from "antd";
import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { apolloResponseErrorHandler } from "../../helper/errors";
import { AllServicesProps, withAllServices, AllServicesDocument, DeleteSingleServiceProps, withDeleteSingleService } from "../../models"

interface OuterProps {}

type Props = OuterProps & AllServicesProps & RouteComponentProps & DeleteSingleServiceProps<void, "deleteService">;

class AllServicesList extends React.Component<Props> {

    private handleDelete = async (pk: string) => {
        const { deleteService } = this.props;

        const response = await deleteService({
            variables: {
                id: pk
            },
            refetchQueries: [
                {
                    query: AllServicesDocument
                }
            ]
        }).catch(apolloResponseErrorHandler);

        if ( response.data?.deleteService?.found ) {
            notification.success({
                key: "delete-service-success",
                message: "Service has been deleted successfully!"
            })
        }
    }

    private tableColumns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (name: string) => (<Typography.Text strong>{name}</Typography.Text>)
        },
        {
            title: "View",
            dataIndex: "view",
            key: "view",
            render: (pk: string) => (<Link to={`/services/detail/${pk}`}><EyeOutlined/></Link>)
        },
        {
            title: "Edit",
            dataIndex: "edit",
            key: "edit",
            render: (pk: string) => <Link to={`/services/detail/${pk}/edit`}><EditOutlined/></Link>
        },
        {
            title: "Delete",
            dataIndex: "delete",
            key: "delete",
            render: (pk: string) => (
                <Popconfirm
                title="Are you sure to delete the service?"
                onConfirm={() => this.handleDelete(pk)}
                okText="Delete"
                cancelText="Cancel" >
                    <DeleteOutlined/>
                </Popconfirm>
            )
        }
    ]

    render () {
        const { data: { allServices, loading }} = this.props;
        if ( loading ) {
            return <>Loading</>
        } else if ( allServices ) {
            const dataSource = allServices.map(item => ({
                key: item?.pk,
                name: item?.name,
                view: item?.pk,
                edit: item?.pk,
                delete: item?.pk
            }));

            return (
                <Table 
                columns={this.tableColumns} 
                dataSource={dataSource}
                className="ap-table"
                pagination={false}/>
            )
        } else {
            return <>Something went wrong in all services list component!</>
        }
    }
}

export default compose<Props, OuterProps>(
    withAllServices(),
    withDeleteSingleService({
        name: "deleteService"
    }),
    withRouter
)(AllServicesList);