import { ApolloError } from "@apollo/client";
import { notification } from "antd";
import { GraphQLError } from "graphql";

export const unexpectedErrorHandler = async (err: Error) => {
  notification.error({
    key: "unexpected-error",
    message: err.message,
  });
};

export const apolloResponseErrorHandler = async (err: ApolloError) => {
  if (err.networkError) {
    notification.error({
      key: "apollo-network-error",
      message: err.message,
    });
  } else {
    await unexpectedErrorHandler(err);
  }

  return {
    data: null,
    errors: err.graphQLErrors.length
      ? err.graphQLErrors
      : [new GraphQLError("The operation failed due to network problems")],
  };
};
