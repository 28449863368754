import React from "react";
import { compose } from "recompose";
import { withAuthentication } from "../../hoc/isAuthendicated";
import BaseLayout from "../../layouts/BaseLayout";
import { UserInfoProps, UserRole } from "../../models";
import HomeApplicant from "./HomeApplicant";
import HomeEmployee from "./HomeEmployee";
import HomeRecruiter from "./HomeRecruiter";

interface OuterProps {}

type Props = OuterProps & UserInfoProps;

class IndexView extends React.Component<Props> {

    private renderByUserRole = () => {
        const { whoami, loading } = this.props.data

        if ( loading ) {
            return <>Loading homeview</>
        } else if ( whoami ) {
            switch ( whoami.role ) {
                case UserRole.Recruiter:
                    return <HomeRecruiter recruiter={whoami}/>
                case UserRole.Employee:
                    return <HomeEmployee employee={whoami}/>
                default:
                    return <HomeApplicant applicant={whoami} />;
            }
        } else {
            return <>Something went wrong</>
        }
    }

    render () {
        return (
            <BaseLayout>
                {this.renderByUserRole()}
            </BaseLayout>
        )
    }
}

export default compose<Props, OuterProps>(
    withAuthentication(),
    
)(IndexView);