import { Col, Empty, Row } from "antd";
import React from "react";
import { compose } from "recompose";
import MarkdownSection from "../../components/base/MarkdownSection";
import ContactPersonDetails from "../../components/user/applicant/ContactPersonDetails";
import TrialdayInformation from "../../components/user/applicant/TrialdayInformation";
import { TrialDayProps, TrialDayType, UserType, withTrialDay } from "../../models";
import { IsDateAfterToday, IsDateEqualToday } from '../../helper/date';


interface OuterProps {
    applicant: UserType
}

type Props = OuterProps & TrialDayProps;

class HomeApplicant extends React.Component<Props> {
    
    private renderUpcommingTrialDay = (trialDay: TrialDayType) => {
        return (
            <Row>
                <Col lg={12} xs={24}>
                    <Row>
                        <TrialdayInformation trialDay={trialDay} />
                    </Row>
                    <Row>
                        <ContactPersonDetails 
                        align="vertical" 
                        showAvatar={true}
                        applicant={this.props.applicant} />
                    </Row>
                </Col>
                <Col lg={12} xs={24}>
                    <MarkdownSection 
                        sectionTitle="Preperation"
                        titleLink={{
                            text: "To your tasks",
                            to: {
                                to: "/tasks"
                            }
                        }}>
                            <div dangerouslySetInnerHTML={{ __html: trialDay.jobPackage.preTrialDayDescriptionRendered}} />
                    </MarkdownSection>
                </Col>
            </Row>
        )
    }

    private renderCurrentTrialDay = (trialDay: TrialDayType) => {
        return (
            <Row>
                <Col lg={12} xs={24}>
                    <Row>
                        <TrialdayInformation trialDay={trialDay} />
                    </Row>
                    <Row>
                        <ContactPersonDetails 
                        align="vertical" 
                        showAvatar={true}
                        applicant={this.props.applicant} />
                    </Row>
                </Col>
                <Col lg={12} xs={24}>
                    <MarkdownSection 
                        sectionTitle="Procedure"
                        titleLink={{
                            text: "To your tasks",
                            to: {
                                to: "/tasks"
                            }
                        }}>
                            <div dangerouslySetInnerHTML={{ __html: trialDay.jobPackage.trialDayDescriptionRendered }} />
                        </MarkdownSection>
                </Col>
            </Row>
        )
    }

    private renderOccuredTrialDay = () => {
        return (
            <Row>
                <Col lg={12} xs={24}>
                    <Row>
                        <MarkdownSection sectionTitle="Your Trialday took place">
                            <Empty description="You will receive further information via email!"/>
                        </MarkdownSection>
                    </Row>
                </Col>
            </Row>
        )
    }

    private renderDependingOnTrialdayDate = (trialday: TrialDayType) => {
        if ( IsDateEqualToday(trialday.date) ) {
            return this.renderCurrentTrialDay(trialday)
        } else if ( IsDateAfterToday(trialday.date) ) {
            return this.renderUpcommingTrialDay(trialday);
        } else {
            return this.renderOccuredTrialDay();
        }
    }

    render () {
        const { loading, getTrialDay} = this.props.data

        if ( loading ) {
            return <>Loading Applicant</>
        } else if (getTrialDay) {
            return this.renderDependingOnTrialdayDate(getTrialDay as TrialDayType);
        } else {
            return (
                <Row>
                <Col lg={12} xs={24}>
                    <Row>
                        <MarkdownSection sectionTitle="Your Trialday">
                            <Empty description="You don't have an appointment yet!"/>
                        </MarkdownSection>
                    </Row>
                </Col>
            </Row>
            )
        }
    }
}

export default compose<Props, OuterProps>(
    withTrialDay({
        options: (props: Props) => ({
            variables: {
                id: props.applicant.pk
            }
        })
    })
)(HomeApplicant);