import { FormInstance, notification } from "antd";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { compose } from "recompose";
import ApCard from "../../../components/base/ap-card";
import { ApplicantForm, ApplicantFormData } from "../../../components/user/recruiter/ApplicantForm";
import { apolloResponseErrorHandler } from "../../../helper/errors";
import { withAuthentication } from "../../../hoc/isAuthendicated";
import BaseLayout from "../../../layouts/BaseLayout";
import { AllApplicantsDocument, CreateUserProps, useCreateUserMutation, UserRole } from "../../../models";

interface OuterProps {}

type Props = OuterProps & CreateUserProps & RouteComponentProps;

const CreateApplicantView: React.FC<Props> = ({ ...props }) => {
    const formRef = React.useRef<FormInstance>();

    const [ mutate ] = useCreateUserMutation({
        refetchQueries: [{ query: AllApplicantsDocument }],
        onCompleted: () => {
            notification.success({
                key: "create-applicant-success",
                message: "Applicant has been created successfully!"
            })
            props.history.push("/applicants")
        },
        onError: (err) => apolloResponseErrorHandler(err)
    })

    const onFormFinish = (formData: ApplicantFormData) => {
        const applicantData = {
            heavenhrUrl: formData.heavenhrUrl,
            comment: formData.comment,
            //todo: rendered options needs to be removed - breaking change
            commentRendered: "<p></p>"
        }

        mutate({
            variables: {
                input: {
                    role: UserRole.Applicant,
                    firstName: formData.firstName,
                    lastName: formData.lastName,
                    email: formData.email,
                    applicantData: applicantData,
                    isSuperuser: false,
                    isStaff: false,
                    isActive: false
                }
            }
        })
    }

    return (
        <BaseLayout>
            <ApCard title="Create Service">
                <ApplicantForm 
                formType="create"
                isActive={false} 
                ref={formRef as React.RefObject<FormInstance>} 
                onFinish={onFormFinish} />
            </ApCard>
        </BaseLayout>
    )
}

export default compose<Props, OuterProps>(
    withAuthentication()
)(CreateApplicantView)