import { QuestionCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";
import React from "react";

interface State {
    visible: boolean;
}

interface OuterProps {
    help: string;
}

type Props = OuterProps;

class TaskHelpPopover extends React.Component<Props, State> {

    state: State = {
        visible: false
    }

    private toggleVisibility = () => {
        this.setState({
            visible: !this.state.visible
        })
    }

    render () {
        const { help } = this.props;
        return (
            <Popover
            content={help}
            trigger="click"
            visible={this.state.visible}
            onVisibleChange={this.toggleVisibility}>
                <QuestionCircleOutlined/>
            </Popover>
        )
    }
}

export default TaskHelpPopover;