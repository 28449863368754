import { Descriptions, Typography } from "antd";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import ApCard from "../../components/base/ap-card";
import BaseLayout from "../../layouts/BaseLayout";
import { TrialDayProps, withTrialDay } from "../../models";
import moment from "moment";
import { withAuthentication } from "../../hoc/isAuthendicated";

interface RouteParams {
    id: string
}

interface OuterProps {}

type Props = OuterProps & TrialDayProps & RouteComponentProps<RouteParams>;

class TrialdayDetailView extends React.Component<Props> {

    private renderContent = () => {
        const { data: { getTrialDay, loading }} = this.props;

        if ( loading ) {
            return <>Loading</>
        } else if ( getTrialDay ) {
            return (
                <Descriptions title={`Trialday with ${getTrialDay.applicant.firstName} ${getTrialDay.applicant.lastName}`} column={4} bordered>
                    <Descriptions.Item label="Date" span={2}>{moment(getTrialDay.date).format("DD.MM.YYYY")}</Descriptions.Item>
                    <Descriptions.Item label="Job Package" span={2}>{getTrialDay.jobPackage.name}</Descriptions.Item>
                    <Descriptions.Item label="Applicant" span={2}>{getTrialDay.applicant.email}</Descriptions.Item>
                    <Descriptions.Item label="Supervisors" span={2}>{getTrialDay.supervisors.map(item => <Typography.Text style={{display: 'block'}}>{item.email}</Typography.Text>)}</Descriptions.Item>
                    <Descriptions.Item label="Additional Information" span={4}>{getTrialDay.additionalInformation}</Descriptions.Item>
                    <Descriptions.Item label="Tasks" span={4}>{getTrialDay.tasks?.map(item => (<Typography.Text style={{display: 'block'}}>{item?.description}</Typography.Text>))}</Descriptions.Item>
                </Descriptions>
            )
        } else {
            return <>Something went wrong in trialday detail view component!</>
        }
    }

    render () {
        const { id } = this.props.match.params
        return (
            <BaseLayout>
                <ApCard title="Trialday Detail" link={{
                    text: "Edit Trial Day",
                    to: {
                        to: `/trialdays/detail/${id}/edit` }}} >
                    {this.renderContent()}
                </ApCard>
            </BaseLayout>
        )
    }
}

export default compose<Props, OuterProps>(
    withAuthentication(),
    withRouter,
    withTrialDay({
        options: (props: Props) => ({
            variables: {
                id: props.match.params.id
            }
        })
    })
)(TrialdayDetailView);