import { useHistory } from "react-router-dom";
import { compose } from "recompose";
import { apolloResponseErrorHandler } from "../helper/errors";
import { UserInfoQuery, useUserInfoQuery, withUserInfo } from "../models"

export const withAuthentication = () => {
    return compose(
        withUserInfo({
            options: (props: any) => {
                return {
                    onError: (err) => {
                        const [graphError] = err.graphQLErrors;
                        if (graphError && graphError.name === "PermissionDenied") {
                            props.history.push("/auth/login");
                        } else {
                            apolloResponseErrorHandler(err);
                            props.history.push("/auth/login");
                        }
                    },
                    onCompleted: (data: UserInfoQuery) => {
                        if (!data || !data.whoami) {
                            props.history.push("/auth/login");
                        }
                    }
                }
            }
        }
        )
    )
}

/**
 * Manages and ensures that the user is authenticated otherwise, redirect to the given redirectRoute.
 */
export const useAuthentication = (options: { redirectRoute: string }) => {

    const history = useHistory()

    useUserInfoQuery({
        onError: (err) => {
            const [graphError] = err.graphQLErrors;
            if (graphError && graphError.name === "PermissionDenied") {
                history.push(options.redirectRoute);
            } else {
                apolloResponseErrorHandler(err);
                history.push(options.redirectRoute);
            }
        },
        onCompleted: (data) => {
            if (!data.whoami) {
                history.push(options.redirectRoute)
            }
        }
    })

}