import React from "react"
import { compose } from "recompose";
import { withAuthentication } from "../hoc/isAuthendicated";
import BaseLayout from "../layouts/BaseLayout";

interface OuterProps {}

type Props = OuterProps;

class GuidelineView extends React.Component<Props> {
    render () {
        return (
            <BaseLayout>
                <iframe style={{width: '100%', height: '100%', border: 'none'}} src="https://static.prod.webrunners.cloud/manifesto/"/>
            </BaseLayout>
        )
    }
}

export default compose<Props, OuterProps>(
    withAuthentication()
)(GuidelineView);