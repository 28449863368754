import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, FormInstance, FormProps, Input, Row, Select } from "antd";
import React from "react";
import { TaskSelect } from "../form/TaskSelect";

const { Option } = Select;

export interface JobPackageChecklistItem {
    description: string,
    position: number
}

export type JobPackageFormData = {
    name: string
    tasklists?: string[]
    pretrialdaydescription: string
    trialdaydescription: string
    jobpackagechecklist?: JobPackageChecklistItem[]
}

export interface JobPackageFormTaskOption {
    key: string
    name: string
}

interface OuterProps {
    formType: "edit" | "create"
}

type Props = OuterProps & FormProps<JobPackageFormData>;

export const JobPackageForm = React.forwardRef<FormInstance, Props>((props, ref) => {

    return (
        <Form ref={ref} {...props}>
            <Row>
                <Col lg={12} xs={24}>
                    <Form.Item
                    className="wr-styled-input"
                    name="name"
                    label="Name"
                    rules={[{
                        required: true,
                        message: "Name is required!"
                    }]}>
                        <Input autoFocus type="text" placeholder="Name" />
                    </Form.Item>
                </Col>
                <Col lg={12} xs={24}>
                    <Form.Item
                    className="wr-styled-input"
                    name="tasklists"
                    label="Tasks">
                        <TaskSelect mode="multiple" />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col lg={12} xs={24}>
                    <Form.Item
                    className="wr-styled-input"
                    name="pretrialdaydescription"
                    label="Pre Trialday Description">
                        <Input.TextArea placeholder="Pre Trialday Description" />
                    </Form.Item>
                </Col>
                <Col lg={12} xs={24}>
                    <Form.Item
                    className="wr-styled-input"
                    name="trialdaydescription"
                    label="Trialday Description">
                        <Input.TextArea placeholder="Trialday Description" />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col lg={24} xs={24}>
                    <Row>
                        <h3>Recruiter Checklist</h3>
                    </Row>
                    <Form.List name="jobpackagechecklist">
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, ...restField}) => (
                                    <Row>
                                        <Col lg={8} xs={24}>
                                            <Form.Item
                                                {...restField}
                                                className="wr-styled-input"
                                                name={[name, "description"]}
                                                label="Item">
                                                <Input.TextArea />
                                            </Form.Item>
                                        </Col>
                                        <Col lg={8} xs={24}>
                                            <Form.Item
                                                {...restField}
                                                className="wr-styled-input"
                                                name={[name, "position"]}
                                                label="Position">
                                                <Input type="number" step="1" />
                                            </Form.Item>
                                        </Col>
                                        <Col lg={3} xs={24}>
                                            <MinusCircleOutlined onClick={() => remove(name)} />
                                        </Col>
                                    </Row>
                                ))}
                                <Row>
                                    <Col lg={8} xs={24}>
                                        <Form.Item>
                                            <Button onClick={() => add()} block icon={<PlusOutlined/>}>
                                                Add Field
                                            </Button>
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </>
                        )}
                    </Form.List>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Form.Item>
                        <Button type="primary" htmlType="submit">
                            { props.formType === "edit" ? "Edit Job Package" : "Create Job Package" }
                        </Button>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
})