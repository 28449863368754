import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

const { REACT_APP_BACKEND_URI = "http://localhost:8000" } = process.env;
const BACKEND_URI = `${REACT_APP_BACKEND_URI}/api/graphql`;

const link = createHttpLink({
  uri: `${BACKEND_URI}`,
});

const authLink = setContext((_, { headers }) => {
  const jwtToken = localStorage.getItem("token");
  if (jwtToken) {
    return {
      headers: {
        ...headers,
        Authorization: `JWT ${jwtToken}`,
      },
    };
  }
  return;
});

const client = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache(),
});

export default client;
