import { Descriptions, Typography } from "antd";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import ApCard from "../../components/base/ap-card";
import { withAuthentication } from "../../hoc/isAuthendicated";
import BaseLayout from "../../layouts/BaseLayout";
import { ServiceProps, withService } from "../../models";

interface RouteParams {
    id: string
}

interface OuterProps {}

type Props = OuterProps & ServiceProps & RouteComponentProps<RouteParams>;

class ServiceDetailView extends React.Component<Props> {

    private renderContent = () => {
        const {data: { service, loading }} = this.props;

        if ( loading ) {
            return <>Loading</>
        } else if ( service ) {
            return (
                <Descriptions title={service.name} column={4} bordered>
                    <Descriptions.Item label="URL" span={2}>{service.url}</Descriptions.Item>
                    {/* <Descriptions.Item label="Icon" span={2}>{service.iconPath}</Descriptions.Item> */}
                    <Descriptions.Item label="Active for" span={4}>
                        {service.activeForUsers ? service.activeForUsers.map(item => 
                            (
                            <Typography.Text style={{display: "block"}}>
                                {item?.firstName + " " + item?.lastName}
                            </Typography.Text>
                            )
                        ) : "None"}
                    </Descriptions.Item>
                </Descriptions>
            )
        } else {
            return <>Something went wrong in service detail view component!</>
        }
    }

    render () {
        const { id } = this.props.match.params
        return (
            <BaseLayout>
                <ApCard title="Serice Detail" link={{text: "Edit Service", to: { to: `/services/detail/${id}/edit` }}}>
                    {this.renderContent()}
                </ApCard>
            </BaseLayout>
        )
    }
}

export default compose<Props, OuterProps>(
    withAuthentication(),
    withService({
        options: (props: Props) => ({
            variables: {
                id: props.match.params.id
            }
        })
    }),
    withRouter
)(ServiceDetailView);