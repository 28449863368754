import { Col, Row } from "antd";
import React from "react"
import { RouteComponentProps } from "react-router-dom";
import { compose } from "recompose";
import ApplicantList from "../../../components/user/employee/ApplicantList";
import AllApplicantList from "../../../components/user/recruiter/AllApplicantList";
import { withAuthentication } from "../../../hoc/isAuthendicated";
import BaseLayout from "../../../layouts/BaseLayout";
import { UserInfoProps, UserRole, UserType } from "../../../models";

interface OuterProps {}

type Props = OuterProps & UserInfoProps & RouteComponentProps;

class ApplicantListView extends React.Component<Props> {

    private renderContent = (whoami: UserType) => {
        switch (whoami.role) {
            case UserRole.Employee:
                return (
                    <ApplicantList supervisor={whoami}/>
                )
            case UserRole.Recruiter:
                return <AllApplicantList/>
            default:
                this.props.history.push("/");
        }
    }

    render () {
        const { whoami, loading } = this.props.data;

        if ( loading ) {
            return <>Loading</>
        } else if ( whoami ) {
            return (
                <BaseLayout>
                    <Row>
                        <Col lg={24} xs={24}>
                            {this.renderContent(whoami)}
                        </Col>
                    </Row>
                </BaseLayout>
            )
        } else {
            return <>something went wrong in applicant view!</>
        }
        
    }
}

export default compose<Props, OuterProps>(
    withAuthentication()
)(ApplicantListView);