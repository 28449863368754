import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Col, FormInstance, FormProps, Form, Input, Row, Button } from 'antd'
import React from 'react'

export interface TaskItem {
    description: string
    acceptanceCriteria: string
    help: string
}

export type TasklistFormData = {
    name: string
    tasks: TaskItem[]
}

interface OuterProps {
    formType: "edit" | "create"
}

type Props = OuterProps & FormProps<TasklistFormData>;

export const TasklistForm = React.forwardRef<FormInstance, Props>((props, ref) => {
    const { formType } = props;

    return (
        <Form ref={ref} {...props}>
                <Row>
                    <Col lg={8} xs={24}>
                        <Form.Item
                        className="wr-styled-input"
                        name="name"
                        label="Name"
                        rules={[{
                            required: true,
                            message: "Name is required!"
                        }]}>
                            <Input autoFocus type="text" placeholder="" />
                        </Form.Item>
                    </Col>
                </Row>
                <Form.List name="tasks">
                    {(fields, {add, remove }) => (
                        <>
                            {fields.map(({key, name, ...restField}) => (
                                <Row>
                                    <Col lg={7} xs={24}>
                                        <Form.Item
                                        {...restField}
                                        className="wr-styled-input"
                                        name={[name, "description"]}
                                        label="Description">
                                            <Input.TextArea />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={7} xs={24}>
                                        <Form.Item
                                        {...restField}
                                        className="wr-styled-input"
                                        name={[name, "acceptanceCriteria"]}
                                        label="Acceptance Criteria">
                                            <Input.TextArea />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={7} xs={24}>
                                        <Form.Item
                                        {...restField}
                                        className="wr-styled-input"
                                        name={[name, "help"]}
                                        label="Help">
                                            <Input type="text" placeholder="Help section for user" />
                                        </Form.Item>
                                    </Col>
                                    <Col lg={3} xs={24}>
                                    <MinusCircleOutlined onClick={() => remove(name)} />
                                    </Col>
                                </Row>
                            ))}
                            <Form.Item>
                                <Button onClick={() => add()} block icon={<PlusOutlined/>}>
                                    Add Field
                                </Button>
                            </Form.Item>
                        </>
                    )}
                </Form.List>
                <Row>
                    <Col>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                { formType === "edit" ? "Edit Task" : "Create Task"}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
    )
})