import { Select, SelectProps } from "antd";
import React from "react";
import { useAllUsersQuery, UserRole } from "../../models";

type Props = SelectProps<"mode">

export const SupervisorSelect: React.FC<Props> = ({ ...props }) => {
    const allUsers = useAllUsersQuery()

    return (
        <Select
        {...props}
        loading={allUsers.loading}
        options={(allUsers.data?.users || []).filter(user => user?.role !== UserRole.Applicant).map(supervisor => ({
            label: supervisor?.firstName + " " + supervisor?.lastName as string,
            value: supervisor?.pk as string,
            key: supervisor?.pk as string
        }))} />
    )
}