import { LockFilled } from "@ant-design/icons";
import {Button, Form, Input} from "antd";
import React from "react";

interface FormValues {
    password: string
    passwordConfirm: string
}

interface OuterProps {
    onFinish: (values: FormValues ) => void
}

type Props = OuterProps;

class SetPasswordForm extends React.Component<Props> {

    private checkPasswordLength = async (rules: any, value: string) => {
        if (value.length < 8 ) {
            return Promise.reject("password too short");
        }
        return Promise.resolve();
    }

    private checkPasswordNumeric = async (rules: any, value: string) => {
        if (isNaN(Number(value))) {
            return Promise.resolve();
        }
        return Promise.reject("password needs non numeric characters");
    }

    render () {
        const { onFinish } = this.props;
        return (
            <Form 
            onFinish={onFinish}
            name="set-password">
                <Form.Item
                className="wr-styled-input"
                name="password"
                rules={[
                    {
                        required: true,
                        message: "Password is required!"
                    },
                    {
                        validateTrigger: "submit",
                        validator: this.checkPasswordLength,
                        message: "Password needs at least 8 characters"
                    },
                    {
                        validateTrigger: "submit",
                        validator: this.checkPasswordNumeric,
                        message: "Password needs non numeric characters"
                    }
                ]} >
                    <Input.Password autoFocus placeholder="Password" prefix={<LockFilled/>} />
                </Form.Item>
                <Form.Item
                className="wr-styled-input"
                name="passwordConfirm"
                rules={[{
                    required: true,
                    message: "You have to confirm your password!"
                }]}>
                    <Input.Password placeholder="Confirm Password" prefix={<LockFilled/>}/>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">Save password</Button>
                </Form.Item>
            </Form>
        )
    }
}

export default SetPasswordForm;