import { Col, Row, Typography } from "antd"
import React from "react"
import MarkdownSection from "../../base/MarkdownSection"

const ApOnboardingContent = () => {
    return (
        <article className="onboarding-text">
            <Row>
                <Col lg={12} xs={24} style={{margin: "0 auto"}}>
                    <MarkdownSection sectionTitle="Applicant Portal">
                        <p>
                            The applicant portal is a tool to guide all participants throug the trial day and to simplify it as much as possible.
                            You will receive your tasks and all neccessary information about the trial day and the company.<br/>
                            If you still have any questions, please feel free to contact one of the contact persons mentioned in your profile.
                        </p>
                        <p>
                            Before the trial day, you will find suggesstions on the home page that will help you to prepare for the day.
                            On the trialday itself, you will find the schedule of the trial day instead of the suggesstions.
                        </p>
                        <p>
                            Your tasks will be displayed on the trial day itself.
                        </p>
                        <p>
                            Within the guidelines section you will find a documentary of decisions regarding the cooperation in the company.
                            Through the documentation you can get an understanding of the company, development style and how we work together.<br/><br/>
                            These should make it easier for you to get started, but also help you to find out whether we fit your expectations or not.
                        </p>
                    </MarkdownSection>
                </Col>
            </Row>
        </article>
    )
}

export default ApOnboardingContent;