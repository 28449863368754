import { Col, Row, Table, Typography } from "antd";
import React from "react";
import { compose } from "recompose";
import moment from "moment";
import ApCard from "../../components/base/ap-card";
import { SupervisorDatesProps, UserType, withSupervisorDates } from "../../models";
import { apolloResponseErrorHandler } from "../../helper/errors";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { withAuthentication } from "../../hoc/isAuthendicated";
import { SortOrder } from "antd/lib/table/interface";
import { Today, ToEuropeanDateFormat } from "../../helper/date";

interface OuterProps {
    employee: UserType
}

type Props = OuterProps & SupervisorDatesProps & RouteComponentProps;

class HomeEmployee extends React.Component<Props> {

    private tableColumns = [
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
            render: (date: Date) => moment(date).format('D.M.YYYY'),
            defaultSortOrder: 'ascend' as SortOrder,
            sorter: ( a: any, b: any ) => new Date(a.date).valueOf() - new Date(b.date).valueOf(),                    
        },
        {
            title: "Applicant",
            dataIndex: "applicant",
            key: "applicant"
        },
        {
            title: "Supervisors",
            dataIndex: "supervisors",
            key: "supervisors",
            render: (supvervisors: UserType[]) => supvervisors.map(item => <Typography.Text key={item.pk} style={{display: "block"}}>{item.firstName + " " + item.lastName}</Typography.Text>)
        }
    ];

    render () {
        const { loading, getSupervisorDates } = this.props.data;

        if ( loading ) {
            return <>Loading</>
        } else if ( getSupervisorDates ) {

            const dataSource = getSupervisorDates.filter(item => {
                const eventDate = ToEuropeanDateFormat(item?.date);
                if (eventDate >= Today ) return item;

            }).map( item => {
                return {
                    key: item?.pk,
                    date: new Date(item?.date),
                    applicant: item?.applicant.firstName + " " + item?.applicant.lastName,
                    supervisors: item?.supervisors
                }
            })

            return (
                <Row>
                    <Col lg={24} xs={24}>
                        <Row>
                            <ApCard title="Your Schedule">
                                <Table
                                columns={this.tableColumns}
                                dataSource={dataSource}
                                pagination={false}
                                className="ap-table" />
                            </ApCard>

                            
                        </Row>
                    </Col>
                </Row>
            )
        } else {
            return <>Something went wrong in home employee component!</>
        }
    }
}

export default compose<Props, OuterProps>(
    withAuthentication(),
    withRouter,
    withSupervisorDates({
        options: (props: Props) => ({
            variables: {
                supervisor_id: props.employee.pk
            },
            onError: (err) => {
                const [graphError] = err.graphQLErrors;
                if (graphError && graphError.name === "PermissionDenied") {
                    apolloResponseErrorHandler(err)
                    props.history.push("/home");
                } else {
                    apolloResponseErrorHandler(err);
                }
            }
        })
    })
)(HomeEmployee);