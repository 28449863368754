import { EyeOutlined } from "@ant-design/icons";
import { Table, Typography } from "antd";
import { SortOrder } from "antd/lib/table/interface";
import moment from "moment";
import React from "react";
import { Link } from "react-router-dom";
import { UserType } from "../../../models";


export interface DataSource {
    key: string | undefined
    date: Date
    applicant: string
    supervisors: UserType[] | undefined
}

interface OuterProps {
    dataSource: DataSource[] | undefined
}

type Props = OuterProps;

class TrialdayList extends React.Component<Props> {

    private tableColumns = [
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
            render: (date: Date) => moment(date).format('D.M.YYYY'),
            defaultSortOrder: 'ascend' as SortOrder,
            sorter: ( a: any, b: any ) => new Date(a.date).valueOf() - new Date(b.date).valueOf(),                    
        },
        {
            title: "Applicant",
            dataIndex: "applicant",
            key: "applicant"
        },
        {
            title: "Supervisors",
            dataIndex: "supervisors",
            key: "supervisors",
            render: (supvervisors: UserType[]) => supvervisors.map(item => <Typography.Text key={item.pk} style={{display: "block"}}>{item.firstName + " " + item.lastName}</Typography.Text>)
        },
        {
            title: "Checklist",
            dataIndex: "checklist",
            key: "checklist",
            render: (pk: string) => <Link to={`/job-packages/detail/${pk}/checklist`}><EyeOutlined/></Link>
        }
    ]

    render () {
        const { dataSource } = this.props;

        return <Table
        columns={this.tableColumns}
        dataSource={dataSource}
        pagination={false}
        className="ap-table" />
    }
}

export default TrialdayList;