import { Button, message, Spin } from "antd";
import Title from "antd/lib/typography/Title";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import SetPasswordForm from "../../components/auth/SetPasswordForm";
import ResultComponent from "../../components/base/ResultComponent";
import { apolloResponseErrorHandler } from "../../helper/errors";
import {AuthLayout} from "../../layouts/AuthLayout";
import {
    InvitationProps,
    UseInvitationProps,
    withInvitation,
    withUseInvitation
} from "../../models";


interface OuterProps {}

interface RouteParams {
    slug: string
}

interface State {
    confirmed: boolean
}

type Props = OuterProps 
    & RouteComponentProps<RouteParams> 
    & InvitationProps 
    & UseInvitationProps;

class ConfirmAccount extends React.Component<Props, State> {

    state: State = {
        confirmed: false
    }

    private onSetPassword = async (values: { 
        password: string, 
        passwordConfirm: string
    }) => {
        const { password, passwordConfirm } = values;
        const { slug } = this.props.match.params

        if ( password === passwordConfirm ) {
            const { mutate } = this.props;

            const response = await mutate({
                variables: {
                    password: password,
                    slug: slug
                }
            }).catch(apolloResponseErrorHandler);

            if ( response.data?.useInvitation?.user ) {
                this.setState({
                    confirmed: true
                })
            }

        } else {
            message.error("Passwords must match!")
        }
    }

    private setPasswordForm = () => {
        return (
            <div className="confirm-account-inner">
                <div className="set-password">
                    <Title level={3}>Set your Password</Title>
                    <SetPasswordForm onFinish={this.onSetPassword}/>
                </div>
            </div>
        )
    }

    private renderContent = () => {
        const { invitation, loading } = this.props.data;
        const { confirmed } = this.state;

        if ( loading ) {
            return (
                <div className="confirm-account">
                    <Spin className="confirm-account-loader" size="large"/>
                </div>)
        } else {
            if ( confirmed ) {
                return (
                    <div className="confirm-account">
                        <ResultComponent 
                        title="Your account has been confirmed successfully!" 
                        type="success"
                        extras={[
                            <Button type="primary" onClick={() => this.props.history.push("/auth/login")}>You can login now!</Button>
                        ]} />
                    </div>
                )
            } else {
                return (
                     <div className="confirm-account">
                         {invitation?.user ? 
                         this.setPasswordForm() : 
                         <ResultComponent title="Something went wrong! Either your account already is verified or try with a new link again!" type="warning" />
                         }
                     </div>
                )
            }
        }
    }

    render () {
        return (
            <AuthLayout title="Confirm Account">
                {this.renderContent()}
            </AuthLayout>
        )
    }
}

export default compose<Props, OuterProps>(
    withInvitation({
        options: (props: Props) => ({
            variables: {
                slug: props.match.params.slug
            }
        })
    }),
    withUseInvitation(),
    withRouter
)(ConfirmAccount);