import './Auth.less';

import { Col, Layout, Row } from "antd";
import Title from "antd/lib/typography/Title";
import React from "react";


export const AuthLayout: React.FC<{ title: string }> = ({ children, title }) => {

    return (
        <Layout>
            <main className="auth-layout">
                <Row>
                    <Col className="cover-image" lg={12} xs={0}>
                    </Col>
                    <Col className="auth-form" lg={12} xs={24}>
                        <div className="auth-form-wrapper">
                            <span className="auth-form-title" >Webrunners Career</span>
                            <Title level={1}>{title}</Title>
                            {children}
                        </div>
                    </Col>
                </Row>
            </main>
        </Layout>
    )
}
