import { Select } from "antd";
import React from "react";

const { Option } = Select;

interface OuterProps {
    onSelect: (value: string) => void,
    onClear: () => void
}

type Props = OuterProps;

class ApplicantAdminActionSelect extends React.Component<Props> {

    render () {
        const { onSelect, onClear } = this.props;
        return (
            <>
            <Select
            placeholder="Select Action"
            style={{minWidth: 200}}
            onSelect={onSelect}
            allowClear
            onClear={onClear}
            >
                <Option value="invite">Send invitation email</Option>
                <Option value="password-reset">Send password-reset email</Option>
                <Option value="delete">Delete User</Option>
            </Select>
            </>
        )
    }
}

export default ApplicantAdminActionSelect;