import { Descriptions } from "antd";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import ApCard from "../../../components/base/ap-card";
import UserServicesList from "../../../components/services/UserServicesList";
import { withAuthentication } from "../../../hoc/isAuthendicated";
import BaseLayout from "../../../layouts/BaseLayout";
import { UserProps, withUser, ApplicantTrialDaysProps, withApplicantTrialDays } from "../../../models";

interface RouteParams {
    id: string
}

interface OuterProps {}

type Props = OuterProps & RouteComponentProps<RouteParams> & UserProps<void, "user"> & ApplicantTrialDaysProps<void, "trialdays">;

class ApplicantDetailView extends React.Component<Props> {
    render () {
        const { user: { user, loading }, trialdays: { getApplicantTrialDays }} = this.props;

        if ( loading ) {
            return <>Loading</>
        } else if ( user ) {
            return (
                <BaseLayout>
                    <ApCard title="Applicant Detail" link={{text: "Edit Applicant", to: { to: `/applicants/detail/${user.pk}/edit` }}}>
                        <Descriptions title={`${user.firstName} ${user.lastName}`} column={4} bordered>
                            <Descriptions.Item label="Email" span={2}>{user.email}</Descriptions.Item>
                            <Descriptions.Item label="Role" span={2}>{user.role}</Descriptions.Item>
                            <Descriptions.Item label="Is Active" span={2}>{user.isActive ? "True" : "False"}</Descriptions.Item>
                            <Descriptions.Item label="HeavenHR URL" span={2}>{user.applicantData?.heavenhrUrl}</Descriptions.Item>
                            <Descriptions.Item label="Comment" span={2}>{user.applicantData?.comment}</Descriptions.Item>
                            <Descriptions.Item label="Job Packages" span={2}>{getApplicantTrialDays ? getApplicantTrialDays.map(item => (
                                item?.jobPackage.name
                            )) : ""}</Descriptions.Item>
                        </Descriptions>
                    </ApCard>
                    <ApCard title="Services" titleLevel={2}>
                        <UserServicesList userid={this.props.match.params.id}/>
                    </ApCard>
                </BaseLayout>
            )
        } else { <>Something went wrong in applicant detail view!</>}
    }
}

export default compose<Props, OuterProps>(
    withAuthentication(),
    withRouter,
    withApplicantTrialDays({
        name: "trialdays",
        options: (props: Props) => ({
            variables: {
                id: props.match.params.id
            }
        })
    }),
    withUser({
        name: "user",
        options: (props: Props) => ({
            variables: {
                id: props.match.params.id
            }
        })
    })
)(ApplicantDetailView);