import { Col, FormInstance, FormProps, Row, Form, Input, Button, Switch } from "antd";
import React from "react";

export type ApplicantFormData = {
    firstName: string
    lastName: string
    email: string
    password?: string
    heavenhrUrl?: string
    comment?: string
    active: boolean
}

interface OuterProps {
    isActive: boolean,
    formType: "edit" | "create"
} 

type Props = OuterProps & FormProps<ApplicantFormData>;

export const ApplicantForm = React.forwardRef<FormInstance, Props>((props, ref) => {
    const { isActive, formType } = props;
    return (
        <Form<ApplicantFormData> ref={ref} {...props} >
                <Row>
                    <Col lg={8} xs={24}>
                        <Form.Item
                            className="wr-styled-input"
                            name="firstName"
                            label="First Name"
                            rules={[{
                                required: true
                        }]} >
                            <Input autoFocus type="text" placeholder="First Name" />
                        </Form.Item>
                    </Col>
                    <Col lg={8} xs={24}>
                        <Form.Item
                            className="wr-styled-input"
                            name="lastName"
                            label="Last Name"
                            rules={[{
                                required: true
                        }]} >
                            <Input type="text" placeholder="Last Name" />
                        </Form.Item>
                    </Col>
                    <Col lg={8} xs={24}>
                        <Form.Item
                            className="wr-styled-input"
                            name="email"
                            label="Email address"
                            rules={[{
                                required: true
                            }]}>
                                <Input type="email" placeholder="Email address"></Input>
                        </Form.Item>
                    </Col>
                    <Col lg={8} xs={24}>
                        <Form.Item
                        className="wr-styled-input"
                        name="password"
                        label="Password">
                            <Input.Password 
                            disabled={ formType === "edit" ? false : true } 
                            autoFocus 
                            placeholder="Password" />
                        </Form.Item>
                    </Col>
                    <Col lg={8} xs={24}>
                        <Form.Item
                        className="wr-styled-input"
                        name="active"
                        label="Active">
                            <Switch defaultChecked={isActive}/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col lg={24} xs={24}>
                        <Row><h2>Applicant Data</h2></Row>
                        <Row>
                            <Col lg={8} xs={24}>
                                <Form.Item
                                    className="wr-styled-input"
                                    name="heavenhrUrl"
                                    label="HeavenHR URL">
                                    <Input type="url" placeholder="HeavenHR URL" />
                                </Form.Item>
                            </Col>
                            <Col lg={16} xs={24}>
                                <Form.Item
                                    className="wr-styled-input"
                                    name="comment"
                                    label="Comment">
                                    <Input.TextArea placeholder="Comment" rows={5} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                { formType === "create" ? "Create Applicant" : "Edit Applicant"}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
    )
})