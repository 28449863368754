import { Col, FormInstance, notification, Row } from "antd";
import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { compose } from "recompose";
import ApCard from "../../components/base/ap-card";
import { JobPackageChecklistItem, JobPackageForm, JobPackageFormData } from "../../components/jobs/JobPackageForm";
import { apolloResponseErrorHandler } from "../../helper/errors";
import { withAuthentication } from "../../hoc/isAuthendicated";
import BaseLayout from "../../layouts/BaseLayout";
import { JobPackageDocument, UpdateJobPackageProps, useJobPackageQuery, useUpdateJobPackageMutation } from "../../models";

interface RouteParams {
    id: string
}

interface OuterProps {}

type Props = OuterProps & UpdateJobPackageProps & RouteComponentProps<RouteParams>

const EditJobPackageView: React.FC<Props> = ({ ...props }) => {
    const formRef = React.useRef<FormInstance<JobPackageFormData>>();
    const { id } = props.match.params;

    const query = useJobPackageQuery({
        variables: {
            id: id
        },
        onCompleted: (data) => {
            if (!formRef.current || !data.getJobPackage) return

            const { name, preTrialDayDescription, trialDayDescription, checklistItems, taskList } = data.getJobPackage

            formRef.current.setFieldsValue({
                name,
                pretrialdaydescription: preTrialDayDescription,
                trialdaydescription: trialDayDescription,
                jobpackagechecklist: checklistItems.map(item => ({
                    position: item.position,
                    description: item.description,
                }) as JobPackageChecklistItem),
                tasklists: taskList.map(task => task.pk)
            })
        }
    })

    const [mutate] = useUpdateJobPackageMutation({
        refetchQueries: [{ query: JobPackageDocument, variables: { id: id } }],
        onCompleted: () => {
            notification.success({
                key: "edit-jobpackage-success",
                message: "Job Pacakge has been updated successfully!"
            })
            props.history.push(`/job-packages/detail/${id}`)
        },
        onError: (err) => apolloResponseErrorHandler(err)
    })

    useEffect(() => {
        if (query.data?.getJobPackage && formRef.current) formRef.current.setFieldsValue(query.data.getJobPackage)
    }, [formRef.current])

    const mapJobPackageChecklist = (list: JobPackageChecklistItem[]) => {
        return list.map(item => ({
            description: item.description,
            position: item.position,
            descriptionRendered: "<p></p>"
        }))
    }

    const onFormFinish = (formData: JobPackageFormData) => {
        const tasklist = formData.tasklists ? formData.tasklists : []
        const checklist = formData.jobpackagechecklist ? mapJobPackageChecklist(formData.jobpackagechecklist) : []

        mutate({
            variables: {
                id: id,
                input: {
                    name: formData.name,
                    preTrialDayDescription: formData.pretrialdaydescription,
                    preTrialDayDescriptionRendered: "<p></p>",
                    trialDayDescription: formData.trialdaydescription,
                    trialDayDescriptionRendered: "<p></p>",
                    taskList: tasklist,
                    checklistItems: checklist
                }
            }
        })
    }

    return (
        <BaseLayout>
            <Row>
                <Col lg={24} xs={24}>
                    <ApCard title="Edit Job Package">
                        <JobPackageForm
                            formType="edit"
                            ref={formRef as React.RefObject<FormInstance>}
                            onFinish={onFormFinish} />
                    </ApCard>
                </Col>
            </Row>
        </BaseLayout>
    )
}

export default compose<Props, OuterProps>(
    withAuthentication()
)(EditJobPackageView)