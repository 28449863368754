import { notification, Popconfirm, Table, Typography } from "antd";
import React from "react";
import ApCard from "../base/ap-card";
import { AllJobPackagesProps, AllJobPackagesDocument, TaskListType, withAllJobPackages, DeleteJobPackageProps, withDeleteJobPackage } from "../../models"
import { compose } from "recompose";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { apolloResponseErrorHandler } from "../../helper/errors";

interface OuterProps {}

type Props = OuterProps & AllJobPackagesProps & RouteComponentProps & DeleteJobPackageProps;

class AllJobPackages extends React.Component<Props> {

    private handleDelete = async (pk: string) => {
        const { mutate } = this.props;

        const response = await mutate({
            variables: {
                id: pk
            },
            refetchQueries: [
                {
                    query: AllJobPackagesDocument
                }
            ]
        }).catch(apolloResponseErrorHandler);

        if ( response.data?.deleteJobPackage?.found ) {
            notification.success({
                key: "delete-job-package",
                message: "Job Package has been deleted successfully!"
            })
        }
    }

    private tableColumns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            render: (name: string) => <Typography.Text>{name}</Typography.Text>
        },
        {
            title: "Tasks",
            dataIndex: "tasklist",
            key: "task-list",
            render: (tasklist: TaskListType[]) => {
                const list = tasklist.map((item: TaskListType) => <Typography.Text style={{display: "block"}}>{item.name}</Typography.Text>)
                return <>{list}</>
            }
        },
        {
            title: "View",
            dataIndex: "view",
            key: "view",
            render: (pk: string) => (<Link to={`/job-packages/detail/${pk}`}><EyeOutlined/></Link>)
        },
        {
            title: "Edit",
            dataIndex: "edit",
            key: "edit",
            render: (pk: string) => <Link to={`/job-packages/detail/${pk}/edit`}><EditOutlined/></Link>
        },
        {
            title: "Delete",
            dataIndex: "delete",
            key: "delete",
            render: (pk: string) => {
                return (
                    <Popconfirm
                    title="Are you sure to delete the user?"
                    onConfirm={() => this.handleDelete(pk)}
                    okText="Delete"
                    cancelText="Cancel" >
                        <DeleteOutlined />
                    </Popconfirm>
                );
            }
        }
    ]

    render () {
        const { data: { getAllJobPackages, loading }} = this.props;

        if ( loading ) {
            return <>Loading</>
        } else if ( getAllJobPackages ) {
            const dataSource = getAllJobPackages.map(item => ({
                key: item?.pk,
                name: item?.name,
                tasklist: item?.taskList,
                view: item?.pk,
                edit: item?.pk,
                delete: item?.pk
            }))
            
            return (
                <ApCard 
                title="All Job Packages"
                link={{
                    text: "Create Job Package",
                    to: {
                        to: "/job-packages/create-new"
                    }
                }} >
                    <Table
                    columns={this.tableColumns}
                    dataSource={dataSource}
                    pagination={false}
                    className="ap-table" />
                </ApCard>
            )
        } else { return <>Something went wrong in all jobpackages component</>}
    }
}

export default compose<Props, OuterProps>(
    withAllJobPackages(),
    withRouter,
    withDeleteJobPackage()
)(AllJobPackages);