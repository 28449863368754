import { Col, FormInstance, notification, Row } from "antd";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { compose } from "recompose";
import ApCard from "../../components/base/ap-card";
import { JobPackageChecklistItem, JobPackageForm, JobPackageFormData } from "../../components/jobs/JobPackageForm";
import { apolloResponseErrorHandler } from "../../helper/errors";
import { withAuthentication } from "../../hoc/isAuthendicated";
import BaseLayout from "../../layouts/BaseLayout";
import { AllJobPackagesDocument, CreateJobPackageProps, useCreateJobPackageMutation } from "../../models";

interface OuterProps {}

type Props = OuterProps & CreateJobPackageProps & RouteComponentProps;

const CreateJobPackageView: React.FC<Props> = ({ ...props }) => {
    const formRef = React.useRef<FormInstance>();

    const [ mutate ] = useCreateJobPackageMutation({
        refetchQueries: [{ query: AllJobPackagesDocument }],
        onCompleted: () => {
            notification.success({
                key: "create-jobpackage-success",
                message: "Job Package has been created successfully!"
            })
            props.history.push("/job-packages")
        },
        onError: (err) => apolloResponseErrorHandler(err)
    })

    const mapJobPackageChecklist = (list: JobPackageChecklistItem[]) => {
        return list.map(item => ({
            description: item.description,
            position: item.position,
            descriptionRendered: "<p></p>" 
        }))
    }


    const onFormFinish = (formData: JobPackageFormData) => {
        const tasklist = formData.tasklists ? formData.tasklists : []
        const checklist = formData.jobpackagechecklist ? mapJobPackageChecklist(formData.jobpackagechecklist) : []

        mutate({
            variables: {
                input: {
                    name: formData.name,
                    preTrialDayDescription: formData.pretrialdaydescription,
                    preTrialDayDescriptionRendered: "<p></p>",
                    trialDayDescription: formData.trialdaydescription,
                    trialDayDescriptionRendered: "<p></p>",
                    taskList: tasklist,
                    checklistItems: checklist

                }
            }
        })
    }

    return (
        <BaseLayout>
            <Row>
                <Col lg={24} xs={24}>
                    <ApCard title="Create Job Packages">
                        <JobPackageForm 
                        formType="create"
                        ref={formRef as React.RefObject<FormInstance>}
                        onFinish={onFormFinish} />
                    </ApCard>
                </Col>
            </Row>
        </BaseLayout>
    )
}

export default compose<Props, OuterProps>(
    withAuthentication()
)(CreateJobPackageView)