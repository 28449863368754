import React from "react";
import { compose } from "recompose";
import AllTrialdaysList from "../../components/trialdays/AllTrialdaysList";
import { withAuthentication } from "../../hoc/isAuthendicated";
import BaseLayout from "../../layouts/BaseLayout";

interface OuterProps {}

type Props = OuterProps

class TrialdayView extends React.Component<Props> {
    render () {
        return (
            <BaseLayout>
                <AllTrialdaysList/>
            </BaseLayout>
        )
    }
}

export default compose<Props, OuterProps>(
    withAuthentication()
)(TrialdayView);