import './Base.less';

import { UserOutlined } from "@ant-design/icons";
import { Divider, Dropdown, Layout, Menu } from "antd";
import Avatar from "antd/lib/avatar/avatar";
import Text from "antd/lib/typography/Text";
import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import BaseMenu from "../components/base/BaseMenu";
import { UserInfoProps, withUserInfo } from "../models";
import OnboardingModal from '../components/onboarding/OnboardingModal';
import { withAuthentication } from '../hoc/isAuthendicated';


const { Sider, Content, Header } = Layout;

interface OuterProps {}

interface State {
    dropdownVisible: boolean
    onboardingModalVisible: boolean
    firstUserLogin: boolean
}

type Props = OuterProps & UserInfoProps & RouteComponentProps;

class BaseLayout extends React.Component<Props, State> {

    state: State = {
        dropdownVisible: false,
        onboardingModalVisible: false,
        firstUserLogin: false
    }

    toggleOnboardingModalToVisible = () => {
        this.toggleDropdownVisibility()
        this.setState({
            onboardingModalVisible: !this.state.onboardingModalVisible
        })
    }

    componentDidMount () {
        const lsItem = localStorage.getItem("userLoggedInBefore");
        if ( !lsItem ) {
            this.setState({
                firstUserLogin: true,
                onboardingModalVisible: true
            });
            localStorage.setItem("userLoggedInBefore", "true");
        }
    }

    private renderContent = () => {
        const { children } = this.props;
        if (children) {
          return children;
        }
        return;
      };

    private onLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("tokenExpiresIn");
        window.location.reload();
    }

    private getMainMenuSelectedKeys = () => {
        const { location: { pathname } } = this.props;
        const path = pathname === "/" ? "home" : pathname;
        const parts = path.split("/");

        return parts.reduce((keys, part) => {
            if (part === "") return keys;

            if (keys.length) {
                keys.push([...keys, part].join("/"));
            } else {
                keys.push("/" + [...keys, part].join("/"));
            }

            return keys;
        }, [] as string[]);
    }

    private toggleDropdownVisibility = () => {
        this.setState({
            dropdownVisible: !this.state.dropdownVisible
        })
    };

    private userDropdown = () => {
        return (
            <>
            <Menu 
            key="header-dropdown" >
                <Menu.Item key="profile" onClick={() => this.props.history.push("/profile")} >
                    Profile
                </Menu.Item>
                <Menu.Item key="onboarding" onClick={this.toggleOnboardingModalToVisible} >
                    Onboarding
                </Menu.Item>
                <Divider key="divider-1" style={{margin: "10px 0"}}/>
                <Menu.Item key="logout" onClick={this.onLogout}>
                    Logout
                </Menu.Item>
            </Menu>
            <OnboardingModal
                modalVisible={this.state.onboardingModalVisible}
                toggleVisibility={this.toggleOnboardingModalToVisible}
                keyboardSupport={true}
            />
        </>
        )
    }

    render () {
        const { loading, whoami } = this.props.data;

        if ( loading ) {
            return <>Loading</>
        } else {
            return (
                <Layout>
                    <Sider className="base-sider">
                        <Header className="logo-wrapper">
                            <Link to="/home">
                                <img 
                                    className="logo" 
                                    src="/webrunners_logo.svg" 
                                    alt="Webrunners GmbH Logo" 
                                />
                            </Link>
                        </Header>
                        <BaseMenu userRole={whoami?.role} defaultSelectedKeys={this.getMainMenuSelectedKeys()}/>
                    </Sider>
                    <Layout className="base-layout">
                        <Header className="base-header">
                            <div>
                            <Dropdown
                                className="base-header-profile-link"
                                overlay={this.userDropdown()}
                                onVisibleChange={this.toggleDropdownVisibility}
                                visible={this.state.dropdownVisible}
                                >
                                <div>
                                    <Avatar size="small" icon={<UserOutlined/>} />
                                    <Text strong>{(whoami?.firstName || whoami?.lastName) ? whoami.firstName + " " + whoami.lastName : whoami?.email}</Text>
                                </div>
                            </Dropdown>
                            </div>
                        </Header>
                        <Content>{this.renderContent()}</Content>
                    </Layout>
                </Layout>
            )
        }
    }
}

export default compose<Props, OuterProps>(
    withAuthentication(),
    withUserInfo(),
    withRouter
)(BaseLayout);