import './styles/antd.less';
import './App.less';
import './index.less';

import React from 'react';

import { ApolloProvider } from "@apollo/client";
import { HashRouter as Router, Route } from 'react-router-dom';

import client from './helper/apollo';
import IndexView from './views/home/IndexView';
import LoginView from './views/auth/LoginView';
import PasswordSetView from './views/auth/PasswordSet';
import ConfirmAccount from './views/auth/ConfirmAccount';
import ForgotPasswordView from './views/auth/ForgotPassword';
import TaskView from './views/tasks/TaskView';
import GuidelineView from './views/Guidelines';
import ApplicantListView from './views/recruiter/applicants/ApplicantListView';
import ProfileView from './views/profile/ProfileView';
import CreateApplicantView from './views/recruiter/applicants/CreateApplicantView';
import TermsAndConditionsView from './views/TermsAndConditionsView';
import EditApplicantView from './views/recruiter/applicants/EditApplicantView';
import TaskListView from './views/tasks/TaskListView';
import EditTaskListView from './views/tasks/EditTaskListView';
import CreateTaskListView from './views/tasks/CreateTaskListView';
import TaskListDetailView from './views/tasks/TaskListDetailView';
import JobPackageView from './views/jobs/JobPackageView';
import EditJobPackageView from './views/jobs/EditJobPackageView';
import CreateJobPackageView from './views/jobs/CreateJobPackageView';
import JobPackageDetailView from './views/jobs/JobPackageDetailView';
import ServiceView from './views/services/ServiceView';
import ServiceDetailView from './views/services/ServiceDetailView';
import EditServiceView from './views/services/EditServiceView';
import TrialdayView from './views/trialdays/TrialdaysView';
import TrialdayDetailView from './views/trialdays/TrialdayDetailView';
import CreateTrialdayView from './views/trialdays/CreateTrialdayView';
import CreateServiceView from './views/services/CreateServiceView';
import ApplicantDetailView from './views/recruiter/applicants/ApplicantDetailView';
import EditTrialdayView from './views/trialdays/EditTrialdayView';
import JobPackageChecklistView from './views/jobs/JobPackageChecklistView';


function App() {
  return (
    <div className="app">
      <ApolloProvider client={client}>
        <Router>
          <Route exact path="/auth/login" component={LoginView} />
          <Route exact path="/auth/password-set/:slug" component={PasswordSetView} />
          <Route exact path="/auth/forgot-password" component={ForgotPasswordView} />
          <Route exact path="/invitation/:slug" component={ConfirmAccount} />
          
          <Route exact path={["/home", "/"]} component={IndexView}/>
          <Route exact path="/guidelines" component={GuidelineView} />
          <Route exact path="/profile" component={ProfileView} />

          <Route exact path="/job-packages" component={JobPackageView} />
          <Route exact path="/job-packages/create-new" component={CreateJobPackageView} />
          <Route exact path="/job-packages/detail/:id" component={JobPackageDetailView} />
          <Route exact path="/job-packages/detail/:id/checklist" component={JobPackageChecklistView} />
          <Route exact path="/job-packages/detail/:id/edit" component={EditJobPackageView} />

          <Route exact path="/tasks" component={TaskView} />

          <Route exact path="/applicants" component={ApplicantListView} />
          <Route exact path="/applicants/create-new" component={CreateApplicantView} />
          <Route exact path="/applicants/detail/:id" component={ApplicantDetailView} />
          <Route exact path="/applicants/detail/:id/edit" component={EditApplicantView} />

          <Route exact path="/task-lists" component={TaskListView} />
          <Route exact path="/task-lists/create-new" component={CreateTaskListView} />
          <Route exact path="/task-lists/detail/:id" component={TaskListDetailView} />
          <Route exact path="/task-lists/detail/:id/edit" component={EditTaskListView} />

          <Route exact path="/services" component={ServiceView} />
          <Route exact path="/services/create-new" component={CreateServiceView} />
          <Route exact path="/services/detail/:id" component={ServiceDetailView} />
          <Route exact path="/services/detail/:id/edit" component={EditServiceView} />

          <Route exact path="/trialdays" component={TrialdayView} />
          <Route exact path="/trialdays/detail/:id" component={TrialdayDetailView} />
          <Route exact path="/trialdays/detail/:id/edit" component={EditTrialdayView} />
          <Route exact path="/trialdays/create-new" component={CreateTrialdayView} />

          <Route exact path="/terms-conditions" component={TermsAndConditionsView} />
        </Router>
      </ApolloProvider>
    </div>
  );
}

export default App;
