import React from "react";
import Markdown from "markdown-to-jsx";

interface OuterProps {
    markdownFilename: string
}

interface State {
    markdown: string
}

type Props = OuterProps;

/**
 * This class can read markdown files that are located at 'frontend/src/content/markdown/'
 */
class MarkdownDocument extends React.Component<Props, State> {

    state: State = {
        markdown: ''
    }

    componentDidMount () {
        const { markdownFilename } = this.props;

        import(`../../content/markdown/${markdownFilename}`)
            .then(res => {
                fetch(res.default)
                    .then(res => res.text())
                    .then(res => this.setState({markdown: res}));
            })
            .catch(err => console.log(err))
    }


    render () {
        const {markdown} = this.state;
        return (
            <Markdown>
                {markdown}
            </Markdown>
        )
    }
}

export default MarkdownDocument;