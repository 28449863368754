import { FormInstance, notification } from 'antd';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { compose } from 'recompose';
import ApCard from '../../components/base/ap-card';
import { ServiceForm, ServiceFormData } from '../../components/services/ServiceForm';
import { apolloResponseErrorHandler } from '../../helper/errors';
import { withAuthentication } from '../../hoc/isAuthendicated';
import BaseLayout from '../../layouts/BaseLayout';
import { AllServicesDocument, CreateSingleServiceProps, useCreateSingleServiceMutation } from "../../models";

interface OuterProps {}

type Props = OuterProps & CreateSingleServiceProps & RouteComponentProps

const CreateServiceView: React.FC<Props> = ({ ...props }) => {
    const formRef = React.useRef<FormInstance>();

    const [ mutate ] = useCreateSingleServiceMutation({
        refetchQueries: [{ query: AllServicesDocument }],
        onCompleted: () => {
            notification.success({
                key: "create-service-success",
                message: "Service has been created successfully!"
            })
            props.history.push(`/services`)
        },
        onError: (err) => apolloResponseErrorHandler(err)
    })

    const onFormFinish = (formData: ServiceFormData) => {
        mutate({
            variables: {
                input: {
                    name: formData.name,
                    url: formData.url
                }
            }
        })
    }

    return (
        <BaseLayout>
            <ApCard title="Create Service">
                <ServiceForm 
                formtype="create"
                ref={formRef as React.RefObject<FormInstance>} 
                onFinish={onFormFinish} />
            </ApCard>
        </BaseLayout>
    )
}

export default compose<Props, OuterProps>(
    withAuthentication()
)(CreateServiceView)