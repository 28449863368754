import { notification } from "antd";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useTokenQuery } from "../../models";
import LoginForm from "./LoginForm";

export const Login: React.FC = () => {

    const [formData, setFormData] = useState(null);
    const [loading, setLoading] = useState(false);
    const history = useHistory();

    const token = useTokenQuery({
        skip: formData === null ? true : false,
        variables: (formData || {}) as any,
        onCompleted: (data) => {
            if (data?.tokenAuth) {
                localStorage.setItem("token", data.tokenAuth.token);
                setLoading(true)
                setTimeout(() => {
                    history.push("/home")
                }, 2000)
            }
        },
        onError: () => {
            notification.error({
                key: "authentication-error",
                "message": "authentication failed"
            })
        }
    })

    return (
        <LoginForm loading={token.loading || loading} onFinish={(data) => setFormData(data as any)} />
    )
}