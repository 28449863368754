import { Col, Row } from "antd";
import React from "react";
import { compose } from "recompose";
import ApCard from "../../components/base/ap-card";
import MarkdownDocument from "../../components/base/MarkdownDocument";
import TrialdayList, { DataSource } from "../../components/user/employee/TrialdayList";
import { withAuthentication } from "../../hoc/isAuthendicated";
import { AllTrialDaysProps, UserType, withAllTrialDays } from "../../models";

interface OuterProps {
    recruiter: UserType
}

type Props = OuterProps & AllTrialDaysProps;

class HomeRecruiter extends React.Component<Props> {

    render () {

        const { data: { getAllTrialDays, loading }, recruiter} = this.props;

        if ( loading ) {
            return <>Loading</>
        } else if ( getAllTrialDays ) {

            const dataSourceAll: DataSource[] = getAllTrialDays.map(item =>
                ({ 
                    key: item?.pk,
                    date: new Date(item?.date),
                    applicant: item?.applicant.firstName + " " + item?.applicant.lastName,
                    supervisors: item?.supervisors,
                    checklist: item?.jobPackage.pk
                })
            );

            const dataSourceRecruiter = dataSourceAll.filter(item => {
                return item.supervisors?.some( item => item.pk === recruiter.pk)
            }
            )
    
            return (
                <>
                <Row>
                    <Col lg={12} xs={24}>
                        <Row>
                            <ApCard title="Your Schedule">
                                <TrialdayList dataSource={dataSourceRecruiter}/>
                            </ApCard>
                        </Row>
                    </Col>
                    <Col lg={12} xs={24}>
                        <Row>
                            <ApCard title="All Trialdays">
                                <TrialdayList dataSource={dataSourceAll}/>
                            </ApCard>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col lg={12} xs={24}>
                        <Row>
                            <ApCard title="Workflow">
                                <MarkdownDocument markdownFilename="recruiter-checklist.md"/>
                            </ApCard>
                        </Row>
                    </Col>
                </Row>
                </>
            )
        } else {
            return <></>
        }

        
    }
}

export default compose<Props, OuterProps>(
    withAuthentication(),
    withAllTrialDays()
)(HomeRecruiter);