import { Select, SelectProps } from "antd";
import React from "react";
import { useAllTaskListsQuery } from "../../models";

type Props = SelectProps<"mode">

export const TaskSelect: React.FC<Props> = ({ ...props }) => {
    const tasklist = useAllTaskListsQuery()

    return (
        <Select
            {...props}
            loading={tasklist.loading}
            options={(tasklist.data?.getAllTaskLists || []).map((tasklist) => ({
                label: tasklist?.name as string,
                value: tasklist?.pk as string,
                key: tasklist?.pk as string
            }))} />
    )
}