import './ApCard.less';

import { PlusOutlined } from "@ant-design/icons";
import { Card } from "antd";
import Title from "antd/lib/typography/Title";
import React from "react"
import { Link, LinkProps } from "react-router-dom";

export interface TitleLink {
    text: string
    to: LinkProps
}

interface OuterProps {
    title: string
    titleLevel?: 1 | 2 | 3 | 4 | 5
    link?: TitleLink
}

type Props = OuterProps;

class ApCard extends React.Component<Props> {

    private renderChildren = () => {
        const { children } = this.props;

        if (children) {
            return children
        } else {
            return;
        }
    }

    private renderTitleLink = (link: TitleLink) => {
        return <span><Link to={link.to.to}>{link.text}<PlusOutlined /></Link></span>
    }

    private renderTitle = () => {
        const { title, link, titleLevel } = this.props;

        if ( link ) {
            return (
                <div className="title-with-link" >
                    <Title level={titleLevel ? titleLevel : 2}>
                        {title}
                    </Title>
                    {this.renderTitleLink(link)}
                </div>
            )
        } else {
            return (
                <Title level={titleLevel ? titleLevel : 2}>
                    {title}
                </Title>
            )
        }

        
    }

    render() {
        return (
            <Card
                className="ap-card"
                title={this.renderTitle()}
                bordered={false}
            >
                <div className="bg-color-normal">
                    {this.renderChildren()}
                </div>
            </Card>
        )
    }
}
export default ApCard;