import './ApplicantTask.less';

import { Empty } from "antd";
import Text from "antd/lib/typography/Text";
import React from "react";
import { compose } from "recompose";
import { TrialDayProps, TaskType, UserType, withTrialDay } from "../../../models";
import MarkdownSection from "../../base/MarkdownSection";
import TaskHelpPopover from '../../base/TaskHelpPopover';

interface OuterProps {
    applicant: UserType
}

type Props = OuterProps & TrialDayProps;

class ApplicantTask extends React.Component<Props> {

    private renderNoTasks = (message: string) => {
        return (
            <MarkdownSection sectionTitle="Your Tasks">
                <Empty description={message} />
            </MarkdownSection>
        )
    }

    private renderHelpModal = (task: TaskType | undefined | null) => {
        if (task?.help) {
            return <TaskHelpPopover help={task.help} />
        } else {
            return <></>
        }
    }

    renderTaskDescription = (task: TaskType) => {
        if ( task.descriptionRendered ) {
            return <span dangerouslySetInnerHTML={{ __html: task.descriptionRendered}}></span>
        }
        return <></>
    }

    renderAcceptanceCriteria = (task: TaskType) => {
        if(task.acceptanceCriteria) {
            return <span dangerouslySetInnerHTML={{__html: task.acceptanceCriteriaRendered}}></span>
        }
    }

    private trialdayTasksList = () => {
        const { getTrialDay } = this.props.data;
        if ( getTrialDay?.tasks ) {
            return getTrialDay.tasks.map( task => {
                return (
                <li key={task?.pk} className="task-item">
                    <div className="task-item-inner">
                        <div className="task-item-inner-description">
                            <h3>Description</h3>

                            {task ? this.renderTaskDescription(task) : null }
                        </div>
                        <div className="task-item-inner-ac">
                            <h4>Acceptance Criteria</h4>
                            {task ? this.renderAcceptanceCriteria(task) : null}
                        </div>
                    </div>
                    {this.renderHelpModal(task)}
                </li>
                )
            });
        } else {
            return <></>;
        }
    }

    get isCurrentTrialDay () {
        const { getTrialDay } = this.props.data;
        const today = new Date().setHours(0,0,0,0);
        return getTrialDay ? new Date(getTrialDay?.date).setHours(0,0,0,0) === today : false;
    }

    render () {
        const { getTrialDay, loading } = this.props.data;
        
        if ( loading ) {
            return <>Tasks loading</>
        } else if ( getTrialDay ) {

            const isCurrentTrialDay = this.isCurrentTrialDay;

            if ( isCurrentTrialDay ) {
                return (
                    <MarkdownSection sectionTitle="Your Tasks">
                        <ol className="applicant-tasklist">
                            {this.trialdayTasksList()}
                        </ol>
                    </MarkdownSection>
                )
            }  else {
                return this.renderNoTasks("Your tasks are only available on trialday")
            }

            
        } else {
            return this.renderNoTasks("You do not have a trial day yet")
        }
    }
}

export default compose<Props, OuterProps>(
    withTrialDay({
        options: (props: Props) => ({
            variables: {
                id: props.applicant.pk
            }
        })
    })
)(ApplicantTask);