import Text from "antd/lib/typography/Text";
import React from "react"
import { UserType } from "../../../models";
import ApCard from "../../base/ap-card";
import { TrialDayProps, withTrialDay } from "../../../models";
import { compose } from "recompose";
import { Empty } from "antd";

interface OuterProps {
    applicant: UserType
}

type Props = OuterProps & TrialDayProps;

class GeneralInformation extends React.Component<Props> {

    private renderContent = () => {
        const { getTrialDay, loading } = this.props.data;
        
        if ( loading ) {
            return <>Loading</>
        } else if (getTrialDay?.additionalInformationRendered) {
            return <div dangerouslySetInnerHTML={{ __html: getTrialDay.additionalInformationRendered}}></div>
        } else {
            return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No additional data to show" />
        }
    }

    render () {
        return (
            <ApCard title="General Information">
                {this.renderContent()}
            </ApCard>
        )
    }
}

export default compose<Props, OuterProps>(
    withTrialDay({
        options: (props: Props) => ({
            variables: {
                id: props.applicant.pk
            }
        })
    })
)(GeneralInformation);