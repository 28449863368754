import WebrunnersOnboardingContent from "./Webrunners";
import React from "react";
import ApOnboardingContent from "./Onboarding";

export const StepList = [
    {
        title: "Webrunners GmbH",
        content: <WebrunnersOnboardingContent />
    },
    {
        title: "Applicant Portal",
        content: <ApOnboardingContent />
    }
];