import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { notification, Popconfirm, Table, Typography } from "antd";
import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { apolloResponseErrorHandler } from "../../helper/errors";
import { AllTaskListsProps, withAllTaskLists, AllTaskListsDocument, DeleteTaskListProps, withDeleteTaskList } from "../../models";
import ApCard from "../base/ap-card";

interface OuterProps {}

type Props = OuterProps & AllTaskListsProps & RouteComponentProps & DeleteTaskListProps;

class AllTasksList extends React.Component<Props> {

    private handleDelete = async (pk: string) => {
        const { mutate } = this.props;

        const response = await mutate({
            variables: {
                id: pk
            },
            refetchQueries: [
                {
                    query: AllTaskListsDocument
                }
            ]
        }).catch(apolloResponseErrorHandler)

        if ( response.data?.deleteTasklist?.found ) {
            notification.success({
                key: "task-list-delete",
                message: "Task has been deleted successfully!"
            })
        }
    }

    private tableColumns = [
        {
            title: "Title",
            dataIndex: "title",
            key: "title",
            render: (title: string) => <Typography.Text>{title}</Typography.Text>
        },
        {
            title: "View",
            dataIndex: "view",
            key: "view",
            render: (pk: string) => (
                <Link to={`/task-lists/detail/${pk}`}><EyeOutlined/></Link>
            )
        },
        {
            title: "Edit",
            dataIndex: "edit",
            key: "edit",
            render: (pk: string) => <Link to={`/task-lists/detail/${pk}/edit`}><EditOutlined/></Link>
        },
        {
            title: "Delete",
            dataIndex: "delete",
            key: "delete",
            render: (pk: string) => {
                return (
                    <Popconfirm
                    title="Are you sure to delete the user?"
                    onConfirm={() => this.handleDelete(pk)}
                    okText="Delete"
                    cancelText="Cancel" >
                        <DeleteOutlined />
                    </Popconfirm>
                );
            }
        }
    ]

    render () {
        const { data: { getAllTaskLists, loading } } = this.props;
        
        if ( loading ) {
            return <>Loading</>
        } else if ( getAllTaskLists ) {
            const dataSource = getAllTaskLists.map( ( item ) => ({
                key: item?.pk,
                title: item?.name,
                view: item?.pk,
                edit: item?.pk,
                delete: item?.pk
            }));

            return <ApCard title="All Tasks" link={{text: "Create Task", to: { to: "/task-lists/create-new" }}} >
                <Table
                columns={this.tableColumns}
                dataSource={dataSource}
                pagination={false}
                className="ap-table" />
            </ApCard>
        } else { return <>Something went wrong in all task lists component</> }
    }
}

export default compose<Props, OuterProps>(
    withAllTaskLists(),
    withDeleteTaskList(),
    withRouter
)(AllTasksList);