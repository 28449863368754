import { FormInstance, notification } from "antd";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { compose } from "recompose";
import ApCard from "../../components/base/ap-card";
import { TaskItem, TasklistForm, TasklistFormData } from "../../components/tasks/TaskListForm";
import { apolloResponseErrorHandler } from "../../helper/errors";
import { withAuthentication } from "../../hoc/isAuthendicated";
import BaseLayout from "../../layouts/BaseLayout";
import { AllTaskListsDocument, CreateTaskListProps, useCreateTaskListMutation } from "../../models";

interface OuterProps {}

type Props = OuterProps & CreateTaskListProps & RouteComponentProps;

const CreateTaskListView: React.FC<Props> = ({ ...props }) => {
    const formRef = React.useRef<FormInstance>();

    const [ mutate ] = useCreateTaskListMutation({
        refetchQueries: [{ query: AllTaskListsDocument }],
        onCompleted: () => {
            notification.success({
                key: "create-tasklist-success",
                message: "Task List has been created successfully!"
            })
            props.history.push("/task-lists")
        },
        onError: (err) => apolloResponseErrorHandler(err)
    });

    const mappedTasklist = (tasks: TaskItem[]) => (
        tasks.map(item => ({
            description: item.description,
            descriptionRendered: "<p></p>",
            acceptanceCriteria: item.acceptanceCriteria,
            acceptanceCriteriaRendered: "<p></p>",
            help: item.help,
            helpRendered: "<p></p>"
        }))
    )

    const onFormFinish = (formData: TasklistFormData) => {
        const tasklist = formData.tasks ? mappedTasklist(formData.tasks) : [];

        mutate({
            variables: {
                input: {
                    name: formData.name,
                    tasks: tasklist
                }
            }
        })
    }
    
    return (
        <BaseLayout>
            <ApCard title="Create new Task List">
                <TasklistForm 
                formType="create"
                ref={formRef as React.RefObject<FormInstance>}
                onFinish={onFormFinish} />
            </ApCard>
        </BaseLayout>
    )
}

export default compose<Props, OuterProps>(
    withAuthentication()
)(CreateTaskListView)