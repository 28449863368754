import { FormInstance, notification } from "antd";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { compose } from "recompose";
import ApCard from "../../components/base/ap-card";
import { TrialdayForm, TrialdayFormData } from "../../components/trialdays/TrialdayForm";
import { apolloResponseErrorHandler } from "../../helper/errors";
import { withAuthentication } from "../../hoc/isAuthendicated";
import BaseLayout from "../../layouts/BaseLayout";
import { AllTrialDaysDocument, CreateTrialdayProps, useCreateTrialdayMutation } from "../../models";

interface OuterProps {}

type Props = OuterProps & CreateTrialdayProps & RouteComponentProps;

const CreateTrialdayView: React.FC<Props> = ({ ...props }) => {
    const formRef = React.useRef<FormInstance>();

    const [ mutate ] = useCreateTrialdayMutation({
        refetchQueries: [{ query: AllTrialDaysDocument }],
        onCompleted: () => {
            notification.success({
                key: "create-trialday-success",
                message: "Trial Day has been created successfully!"
            })
            props.history.push("/trialdays")
        },
        onError: err => apolloResponseErrorHandler(err)
    })

    const onFormFinish = (formData: TrialdayFormData) => {
        mutate({
            variables: {
                input: {
                    date: formData.date,
                    jobPackage: formData.jobpackage,
                    additionalInformation: formData.additionalinformation,
                    additionalInformationRendered: "<p></p>",
                    applicant: formData.applicant,
                    supervisors: formData.supervisors,
                    taskList: formData.tasklist,
                    applicantNotified: false
                }
            }
        })
    }

    return (
        <BaseLayout>
            <ApCard title="Create Trialday">
                <TrialdayForm
                formtype="create"
                ref={formRef as React.RefObject<FormInstance>}
                onFinish={onFormFinish}/>
            </ApCard>
        </BaseLayout>
    )
}

export default compose<Props, OuterProps>(
    withAuthentication()
)(CreateTrialdayView)