import moment from "moment";

/**
 * Returns timestamp with hours set to zero
 */
export const Today = moment().format("D.M.YYYY");

/**
 * Returns Boolean - compares if given date is equal to today
 * @param date
 */
export const IsDateEqualToday = (date: any) => {
  return moment(date).format("D.M.YYYY") === Today;
};

/**
 * Returns Boolean - compares if given date is before today
 * @param date
 */
export const IsDateAfterToday = (date: any) => {
  return moment(date).format("D.M.YYYY") > Today;
};

export const ToEuropeanDateFormat = (date: any) => {
  return moment(date).format("D.M.YYYY");
};
