import { List } from "antd";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import { compose } from "recompose";
import ApCard from "../../components/base/ap-card";
import { withAuthentication } from "../../hoc/isAuthendicated";
import BaseLayout from "../../layouts/BaseLayout";
import { JobPackageProps, JobPackageType, withJobPackage } from "../../models";

interface RouteParams {
    id: string
}

interface OuterProps {}

type Props = RouteComponentProps<RouteParams> & JobPackageProps;

class JobPackageChecklistView extends React.Component<Props> {

    private mapChecklist = (jobPackage?: JobPackageType | null) => {
        if ( !jobPackage ) return []
        return jobPackage.checklistItems.map( item => (
            {
                key: item.pk,
                description: item.description
            }
        ))
    }

    render () {
        const { data: { getJobPackage }} = this.props;
        const { id } = this.props.match.params;
        return (
            <BaseLayout>
                <ApCard title="Recruiter Checklist" link={{text: "Go to Job Package", to: { to: `/job-packages/detail/${id}` }}}>
                    <List
                    itemLayout="horizontal"
                    dataSource={this.mapChecklist(getJobPackage)}
                    renderItem={ item => (
                        <List.Item key={item.key}>{item.description}</List.Item>
                    )} />
                </ApCard>
            </BaseLayout>
        )
    }
}

export default compose<Props, OuterProps>(
    withAuthentication(),
    withJobPackage({
        options: (props: Props) => (
            {
                variables: {
                    id: props.match.params.id
                }
            }
        )
    })
)(JobPackageChecklistView)