import { Select, SelectProps } from "antd"
import React from "react"
import { useJobPackageQuery } from "../../models"

interface OuterProps {
    jobpackage: string
}

type Props = OuterProps & SelectProps<"mode">

export const JobPackageTaskSelect: React.FC<Props> = ({ ...props }) => {
    const jobpackage = useJobPackageQuery({
        variables: {
            id: props.jobpackage
        }
    });

    return (
        <Select
        {...props}
        loading={jobpackage.loading}
        options={(jobpackage.data?.getJobPackage?.taskList || []).map((tasklist) => ({
            label: tasklist.name,
            value: tasklist.pk,
            key: tasklist.pk
        }))} />
    )
}