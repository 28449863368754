import React from "react";
import { compose } from "recompose";
import ApCard from "../../components/base/ap-card";
import AllServicesList from "../../components/services/AllServicesList";
import { withAuthentication } from "../../hoc/isAuthendicated";
import BaseLayout from "../../layouts/BaseLayout";

interface OuterProps {}

type Props = OuterProps

class ServiceView extends React.Component<Props> {
    render () {
        return (
            <BaseLayout>
                <ApCard title="Services" link={{text: "Create Service", to: { to: "/services/create-new" }}}>
                    <AllServicesList />
                </ApCard>
            </BaseLayout>
        )
    }
}

export default compose<Props, OuterProps>(
    withAuthentication()
)(ServiceView);