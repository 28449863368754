import { Select } from "antd";
import React from "react";

const { Option } = Select;

interface OuterProps {
    onSelect: (value: string) => void,
    onClear: () => void
}

type Props = OuterProps;

class TrialdayAdminActionSelect extends React.Component<Props> {
    render () {
        const { onSelect, onClear } = this.props;
        return (
            <Select
            placeholder="Select Action"
            style={{minWidth: 200}}
            onSelect={onSelect}
            allowClear
            onClear={onClear}>
                <Option value="send-notification">Send notification to Applicant</Option>
            </Select>
        )
    }
}

export default TrialdayAdminActionSelect;