import { Row, Col } from "antd";
import React from "react";
import MarkdownDocument from "../components/base/MarkdownDocument";
import UnauthorizedLayout from "../layouts/UnauthorizedLayout";



class TermsAndConditionsView extends React.Component {

    render () {
        return (
            <>
                <UnauthorizedLayout title="Datenschutzerklärung">
                    <MarkdownDocument markdownFilename="terms-and-conditions.md"/>
                </UnauthorizedLayout>
            </>
        )
    }
}

export default TermsAndConditionsView;