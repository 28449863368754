import React from "react";
import { compose } from "recompose";
import AllJobPackages from "../../components/jobs/AllJobPackages";
import { withAuthentication } from "../../hoc/isAuthendicated";
import BaseLayout from "../../layouts/BaseLayout";

interface OuterProps {}

type Props = OuterProps

class JobPackageView extends React.Component<Props> {

    render (){
        return (
            <BaseLayout>
                <AllJobPackages />
            </BaseLayout>
        )
    }
}

export default compose<Props, OuterProps>(
    withAuthentication()
)(JobPackageView);