import { Descriptions, Table } from "antd";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import ApCard from "../../components/base/ap-card";
import { withAuthentication } from "../../hoc/isAuthendicated";
import BaseLayout from "../../layouts/BaseLayout";
import { TaskListProps, TaskType, withTaskList } from "../../models";

interface RouteParams {
    id: string
}

interface OuterProps {}

type Props = OuterProps & RouteComponentProps<RouteParams> & TaskListProps;

class TaskListDetailView extends React.Component<Props> {

    private tableColumns = [
        {
            title: "Description",
            dataIndex: "description",
            key: "description"
        },
        {
            title: "Acceptance Criteria",
            dataIndex: "acceptanceCriteria",
            key: "acceptanceCriteria"
        },
        {
            title: "Help",
            dataIndex: "help",
            key: "help"
        }
    ]

    private mapTasks = (tasks: TaskType[]) => {
        return tasks.map(item => (
            <>
                <Descriptions.Item label="Description" span={1}>{item.description}</Descriptions.Item>
                <Descriptions.Item label="Acceptance Criteria" span={1}>{item.acceptanceCriteria}</Descriptions.Item>
                <Descriptions.Item label="Help" span={1}>{item.help}</Descriptions.Item>
            </>
        ))
    }

    private renderContent = () => {
        const {data: { getTaskList, loading }} = this.props;

        if ( loading ) {
            return <>Loading</>
        } else if ( getTaskList ) {

            const data = getTaskList.tasks.map(item => {
                return {
                    key: item.pk,
                    description: item.description,
                    acceptanceCriteria: item.acceptanceCriteria,
                    help: item.help
                }
            })

            return (
                <>
                    <h2>{getTaskList.name}</h2>
                    <Table columns={this.tableColumns} dataSource={data} pagination={false} />
                </>
            )
        } else {
            return <>Something went wrong in Task List detail view component!</>
        }

    }


    render () {
        const { getTaskList } = this.props.data
        return (
            <BaseLayout>
                <ApCard title="Task List Detail" link={{text: "Edit Task List", to: { to: `/task-lists/detail/${getTaskList?.pk}/edit` }}}>
                    {this.renderContent()}
                </ApCard>
            </BaseLayout>
        )
    }
}

export default compose<Props, OuterProps>(
    withAuthentication(),
    withTaskList({
        options: (props: Props) => ({
            variables: {
                id: props.match.params.id
            }
        })
    }),
    withRouter
)(TaskListDetailView);