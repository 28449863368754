import { Col, Row } from "antd";
import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import React from "react";
import { compose } from "recompose";
import ApCard from "../../components/base/ap-card";
import UserServicesList from "../../components/user/UserServicesList";
import { withAuthentication } from "../../hoc/isAuthendicated";
import BaseLayout from "../../layouts/BaseLayout";
import { UserInfoProps } from "../../models";

interface OuterProps {}

type Props = OuterProps & UserInfoProps;

class ProfileView extends React.Component<Props> {

    private renderContent = () => {
        const { whoami, loading } = this.props.data

        if ( loading ) {
            return <>Loading</>
        } else if ( whoami ) {
            return (
                <Row>
                    <Col lg={12} xs={24}>
                        <ApCard title="Profile" titleLevel={1}>
                            <Title level={2}>{whoami.firstName + " " + whoami.lastName}</Title>
                            <Text>Email: {whoami.email}<br/>Role: {whoami.role}</Text>
                        </ApCard>
                    </Col>
                    <Col lg={12} xs={24}>
                        <ApCard title="Services">
                            <UserServicesList userPk={whoami.pk}/>
                        </ApCard>
                    </Col>
                </Row>
            )
        } else {
            return <>Something went wrong!</>
        }
    }

    render () {

        return (
            <BaseLayout>
                {this.renderContent()}
            </BaseLayout>
        )
    }
}

export default compose<Props, OuterProps>(
    withAuthentication()
)(ProfileView);