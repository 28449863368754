import { Table, Typography } from "antd";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import { apolloResponseErrorHandler } from "../../../helper/errors";
import { withAuthentication } from "../../../hoc/isAuthendicated";
import { SupervisorDatesProps, UserType, withSupervisorDates } from "../../../models";
import ApCard from "../../base/ap-card";
import { SortOrder } from "antd/lib/table/interface";


interface OuterProps {
    supervisor: UserType 
}

type Props = OuterProps & SupervisorDatesProps & RouteComponentProps;

class ApplicantList extends React.Component<Props> {

    private tableColumns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name",
            defaultSortOrder: 'ascend' as SortOrder,
            sorter: (a: any, b: any) => a.name.length - b.name.length
        },
        {
            title: "Email address",
            dataIndex: "email",
            key: "email",
            render: (email: string) => <Typography.Paragraph copyable={{ tooltips: [ 'Click here to copy', 'Copied email']}}>{email}</Typography.Paragraph>
        },
        {
            title: "Job",
            dataIndex: "job",
            key: "job"
        }
    ];

    render () {
        const { loading, getSupervisorDates } = this.props.data;

        if ( loading ) {
            return <>Loading</>
        } else if ( getSupervisorDates ) {

            /** remove duplicates and map accordingly to table columns */
            const dataSource = getSupervisorDates?.filter((item, index, self) => 
                index === self.findIndex((s) => (
                    s?.applicant.pk === item?.applicant.pk
                ))
            ).map(item => {
                return {
                    key: item?.applicant.pk,
                    name: item?.applicant.firstName + " " + item?.applicant.lastName,
                    email: item?.applicant.email,
                    job: item?.jobPackage.name
                }
            })

            return (
                <ApCard title="Applicant Overview">
                    <Table
                    columns={this.tableColumns}
                    dataSource={dataSource}
                    pagination={false}
                    className="ap-table" />
                </ApCard>
            )
        } else {
            return <></>
        }
    }
}

export default compose<Props, OuterProps>(
    withAuthentication(),
    withRouter,
    withSupervisorDates({
        options: (props: Props) => ({
            variables: {
                supervisor_id: props.supervisor.pk
            },
            onError: (err) => {
                const [graphError] = err.graphQLErrors;
                if (graphError && graphError.name === "PermissionDenied") {
                    apolloResponseErrorHandler(err)
                    props.history.push("/home");
                } else {
                    apolloResponseErrorHandler(err);
                }
            }
        }),
    })
)(ApplicantList);