import React from "react";
import {Steps} from "antd";
import { StepList } from "./content/StepList";

import "./OnboardingSteps.less";

const {Step} = Steps;

interface OuterProps {
    step: number
}

type Props = OuterProps;

class OnboardingSteps extends React.Component<Props> {

    private renderSteps = () => {
        const { step } = this.props;
        return (
            <>
                <Steps className="onboarding-steps" current={step}>
                    {StepList.map(item => (
                        <Step key={item.title} title={item.title}/>
                    ))}
                </Steps>
                <div key={StepList[step].title} className="steps-content">
                    {StepList[step].content}
                </div>
            </>
        )
    }

    render () {
        return (
            this.renderSteps()
        )
    }
}

export default OnboardingSteps;