import './ContactPersonDetails.less';

import Text from "antd/lib/typography/Text";
import Title from "antd/lib/typography/Title";
import React from "react";
import { UserType, TrialDayProps, withTrialDay } from "../../../models";
import ApCard from "../../base/ap-card";
import { compose } from 'recompose';

interface OuterProps {
    align: "vertical" | "horizontal"
    showAvatar: boolean
    applicant: UserType
}

type Props = OuterProps & TrialDayProps;

class ContactPersonDetails extends React.Component<Props> {

    private renderSupervisors = (supervisors: UserType[] | undefined) => {
        const { align } = this.props;
        
        if (!supervisors) { return <></> }

        return supervisors.map( supervisor => {
            return (<div key={supervisor.pk} className={`contact-details contact-details-${align}`}>
                    <Text className="contact-person">
                        <Title level={4} className="contact-person-title">
                            {supervisor.firstName + " " + supervisor.lastName}
                        </Title>
                        <ul>
                            <li>{supervisor.email}</li>
                            <li>10:00 - 18:00 Uhr</li>
                        </ul>
                    </Text>
                </div>)
        });
    }

    render () {
        const {data: {loading, getTrialDay}} = this.props;
        
        if (loading) {
            return <>Loading supervisors …</>
        } else {
            return (
                <ApCard title="Your Supervisor(s)" titleLevel={3}>
                    {this.renderSupervisors(getTrialDay?.supervisors)}
                </ApCard>
            )
        }
    }
}

export default compose<Props, OuterProps>(
    withTrialDay({
        options: (props: Props) => ({
            variables: {
                id: props.applicant.pk
            }
        })
    })
)(ContactPersonDetails);