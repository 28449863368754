import "./UserServiceList.less";

import React from "react";
import { List, Typography } from "antd";
import { compose } from "recompose";
import { Scalars, MyServicesProps, withMyServices, UserServiceType } from "../../models";

const { REACT_APP_BACKEND_URI = "http://localhost:8000" } = process.env;

interface OuterProps {
    userPk: Scalars["ID"]
}

type Props = OuterProps & MyServicesProps;

class UserServicesList extends React.Component<Props> {

    private renderIcon = ( iconPath: string | null | undefined, name: string | null | undefined ) => {
        if ( !iconPath ) {
            return <span className="service-item-inner-noimg"><Typography.Text>{name}</Typography.Text></span>
        } else if ( iconPath ) {
            return (
                <span className="service-item-inner-img">
                    <img src={REACT_APP_BACKEND_URI + iconPath} alt={name ? name : "Service Icon"}/>
                    <Typography.Text>{name}</Typography.Text>
                </span>
            )
        }
    }

    private renderServiceItem = ( service: UserServiceType | undefined | null ) => {
        if ( !service ) {
            return
        } else if (service) {
            return (
                <List.Item
                className={service.isActive ? "service-item" : "service-item service-item-inactive"}
                key={"item-" + service.servicePk}>
                    <a
                     href={service.url ? service.url : "#"}
                     target="_blank"
                     key={service.userPk}>
                         {this.renderIcon(service.iconPath, service.name)}
                     </a>
                </List.Item>
            )
        }
    }

    render () {
        const { data: { myServices, loading }} = this.props;

        if ( loading ) {
            return <>Loading …</>
        } else if (myServices) {
            return (
                <List
                 className="service-item-wrapper"
                 grid={{
                     gutter: 16,
                     xs: 2,
                     sm: 3,
                     md: 4,
                     lg: 3,
                     xl: 4,
                     xxl: 6
                 }}
                 dataSource={myServices}
                 renderItem={service => (
                     this.renderServiceItem(service)
                 )}
                />
            )
        }
    }
}

export default compose<Props, OuterProps>(
    withMyServices()
)(UserServicesList);