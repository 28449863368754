import { Menu } from "antd";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router"
import { compose } from "recompose";
import { UserRole } from "../../models"
import OnboardingModal from "../onboarding/OnboardingModal";

interface State {}

interface OuterProps {
    defaultSelectedKeys: string[]
    userRole: UserRole | undefined
}

type Props = OuterProps & RouteComponentProps;

class BaseMenu extends React.Component<Props, State> {

    private visibleToRecruiter = this.props.userRole !== UserRole.Recruiter;
    private visibleToApplicant = this.props.userRole !== UserRole.Applicant;
    private notVisibleToApplicant = this.props.userRole === UserRole.Applicant;

    private renderUserSubMenu = () => {
        return (
            <Menu.SubMenu title="Users" key="users-sub">
                <Menu.Item
                key="/applicants"
                onClick={() => this.props.history.push("/applicants")}
                hidden={this.notVisibleToApplicant}>
                    Applicants
                </Menu.Item>
                <Menu.Item
                key="/services"
                onClick={() => this.props.history.push("/services")}
                hidden={this.visibleToRecruiter}>
                    Services
                </Menu.Item>
            </Menu.SubMenu>
        )
    }

    private renderTrialdaySubMenu = () => {
        return (
            <Menu.SubMenu title="Trialdays" key="trialdays-sub">
                <Menu.Item
                key="/trialdays"
                onClick={() => this.props.history.push("/trialdays")}
                hidden={this.visibleToRecruiter}>
                    Trialdays
                </Menu.Item>
                <Menu.Item
                key="/task-lists"
                onClick={() => this.props.history.push("/task-lists")}
                hidden={this.notVisibleToApplicant}>
                    Tasks
                </Menu.Item>
                <Menu.Item
                key="/job-packages"
                onClick={() => this.props.history.push("/job-packages")}
                hidden={this.visibleToRecruiter}>
                    Job Packages
                </Menu.Item>
            </Menu.SubMenu>
        )
    }

    render () {
        const { defaultSelectedKeys } = this.props

        return (
            <>
                <Menu 
                className="base-menu"
                key="base-menu" 
                selectedKeys={defaultSelectedKeys}
                defaultOpenKeys={["users-sub", "trialdays-sub"]} 
                mode="inline"
                theme="dark"
                >
                    <Menu.Item 
                    key="/home"
                    onClick={() => this.props.history.push("/home")}
                    >
                        Home
                    </Menu.Item>
                    <Menu.Item 
                    key="/tasks"
                    onClick={() => this.props.history.push("/tasks")}
                    hidden={this.visibleToApplicant}
                    >
                        Your Tasks
                    </Menu.Item>
                    { this.props.userRole === UserRole.Recruiter ? this.renderUserSubMenu() : <></> }
                    { this.props.userRole === UserRole.Recruiter ? this.renderTrialdaySubMenu() : <></> }
                    <Menu.Item 
                    key="/guidelines" 
                    onClick={() => this.props.history.push("/guidelines")}
                    >
                        Our Guidelines
                    </Menu.Item>
                </Menu>
            </>
        )
    }
}

export default compose<Props, OuterProps>(
    withRouter
)(BaseMenu);