import React from "react";
import {Col, Collapse, Row, Typography} from "antd";
import MarkdownSection from "../../base/MarkdownSection";
import { CaretRightOutlined } from "@ant-design/icons";

const { Panel } = Collapse;

const WebrunnersOnboardingContent = () => {
    return (
        <div className="onboarding-wrapper">
            <article className="onboarding-text">
                <Row>
                    <Col lg={12} xs={24}>
                        <MarkdownSection sectionTitle="About Webrunners" titleLevel={2}>
                            <Typography.Text>
                                We are an agency for software development and IT-consulting based in Cologne, 
                                Germany and our focus is on agile software development, mainly web applications.<br/>
                                We implement web applications, individual software solutions, services and consult our customers.<br/>
                                We are working with latest standards, both online and offline.<br/><br/>
                                Our team currently consist of eight employees and freelancers.<br/>
                                Due to the current situation, our team is working remotly from homeoffice. 
                                But normally we meet regularly in our office in Cologne at the Mediapark 
                                (Von-Werth-Straße 37, 50670 Cologne).<br/>
                                In case we want to get creative, host hackathons 
                                or just spend time together as a team, we meet in our Creative Space in 
                                Cologne Niehl (Merkenicher Straße 79, 50735 Cologne).
                            </Typography.Text>

                            <Typography.Text>
                                
                            </Typography.Text>
                        </MarkdownSection>
                    </Col>
                    <Col lg={12} xs={24}>
                        <MarkdownSection sectionTitle="Technologies used">
                        <Collapse
                        bordered={false}
                        expandIcon={({ isActive }) => <CaretRightOutlined rotate={isActive ? 90 : 0} />}
                        className="technologie-collapsable">
                            <Panel header="Organisational" key="1">
                                <ul>
                                    <li key="og-1">Timetracking: <strong>Clockodo</strong></li>
                                    <li key="og-2">File sharing: <strong>Nextcloud</strong></li>
                                    <li key="og-3">Meetings: <strong>Zoom</strong></li>
                                    <li key="og-4">Project planning: <strong>Jira / GitLab</strong></li>
                                </ul>
                            </Panel>
                            <Panel header="Frontend Development" key="2">
                                <ul>
                                    <li key="fd-1">React / Typescript</li>
                                    <li key="fd-2">Python Django</li>
                                    <li key="fd-3">mostly Ant Design for design</li>
                                </ul>
                            </Panel>
                            <Panel header="Backend Development" key="3">
                                <ul>
                                    <li key="bd-1">Python Django</li>
                                </ul>
                            </Panel>
                            <Panel header="Content Management Systems" key="4">
                                <ul>
                                    <li key="cms-1">TYPO3</li>
                                    <li key="cms-2">WordPress</li>
                                </ul>
                            </Panel>
                            <Panel header="Version Control" key="5">
                                <ul>
                                    <li key="vc-1">GitLab</li>
                                </ul>
                            </Panel>
                            <Panel header="Virtualization / Containerization" key="6">
                                <ul>
                                    <li key="vm-1">Docker / Docker Compose</li>
                                    <li key="vm-2">DDEV</li>
                                </ul>
                            </Panel>
                            <Panel header="API" key="7">
                                <ul>
                                    <li key="api-1">GraphQL</li>
                                </ul>
                            </Panel>
                        </Collapse>
                        </MarkdownSection>
                    </Col>
                </Row>
                
            </article>
        </div>
    )
}

export default WebrunnersOnboardingContent;