import { MailFilled } from "@ant-design/icons";
import { Button, Divider, Form, Input } from "antd";
import React from "react";
import { Link, RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import ResultComponent from "../../components/base/ResultComponent";
import {AuthLayout} from "../../layouts/AuthLayout";
import { RequestResetProps, withRequestReset } from "../../models";

interface OuterProps {}

interface State {
    submittedEmail: boolean
}

type Props = OuterProps & RequestResetProps & RouteComponentProps;

class ForgotPasswordView extends React.Component<Props, State> {

    state: State = {
        submittedEmail: false
    }

    private onFormFinish = async (values: {email: string}) => {
        const { mutate } = this.props;

        const response = await mutate({
            variables: {
                email: values.email
            }
        });

        if ( response.data?.requestReset?.response ) {
            this.setState({
                submittedEmail: true
            })
        }
    }

    private renderContent = () => {
        const { submittedEmail } = this.state;

        if ( submittedEmail ) {
            return (
                <ResultComponent
                title="You received an email"
                type="success"
                extras={[<Button type="primary" onClick={() => this.props.history.push("/auth/login")}>Go to Login</Button>]} />
            )
        } else {
            return (
            <Form 
            name="request-reset"
            onFinish={this.onFormFinish} >
                <Form.Item
                className="wr-styled-input"
                name="email"
                rules={[{
                    required: true,
                    message: "Email is required!"
                }]} >
                    <Input type="email" placeholder="Email address" prefix={<MailFilled/>} />
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit">Submit</Button>
                </Form.Item>
            </Form>
            )
        }
    }

    render () {
        return (
            <AuthLayout title="Forgot Password">
                {this.renderContent()}
                <Divider />
                <Link to="/auth/login">Zum Login</Link>
            </AuthLayout>
        )
    }
}

export default compose<Props, OuterProps>(
    withRequestReset(),
    withRouter
)(ForgotPasswordView);