import { Select, SelectProps } from "antd";
import React from "react";
import { useAllApplicantsQuery } from "../../models";

type Props = SelectProps<"mode">

export const ApplicantSelect: React.FC<Props> = ({ ...props }) => {
    const applicants = useAllApplicantsQuery()

    return (
        <Select
        {...props}
        loading={applicants.loading}
        options={(applicants.data?.getAllApplicants || []).map((applicant) => ({
            label: applicant?.firstName + " " + applicant?.lastName as string,
            value: applicant?.pk as string,
            key: applicant?.pk as string
        }))} />
    )
}