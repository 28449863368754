import { FormInstance, notification } from "antd";
import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { compose } from "recompose";
import ApCard from "../../components/base/ap-card";
import { TrialdayForm, TrialdayFormData } from "../../components/trialdays/TrialdayForm";
import { apolloResponseErrorHandler } from "../../helper/errors";
import { withAuthentication } from "../../hoc/isAuthendicated";
import BaseLayout from "../../layouts/BaseLayout";
import { TrialDayDocument, UpdateTrialDayProps, useTrialDayQuery, useUpdateTrialDayMutation } from "../../models"

interface RouteParams {
    id: string
}

interface OuterProps {}

type Props = OuterProps & UpdateTrialDayProps & RouteComponentProps<RouteParams>

const EditTrialdayView: React.FC<Props> = ({ ...props }) => {
    const formRef = React.useRef<FormInstance<TrialdayFormData>>();
    const { id } = props.match.params;

    const query = useTrialDayQuery({
        variables: {
            id: id
        },
        onCompleted: (data) => {
            if ( ! formRef.current || !data.getTrialDay ) return

            const { date, jobPackage, additionalInformation, applicant, supervisors, taskList } = data.getTrialDay

            formRef.current.setFieldsValue({
                date,
                jobpackage: jobPackage.pk,
                additionalinformation: additionalInformation,
                applicant: applicant.pk,
                supervisors: supervisors.map( supervisor => supervisor.pk),
                tasklist: taskList.pk
            })
        }
    })

    const [ mutate ] = useUpdateTrialDayMutation({
        refetchQueries: [{ query: TrialDayDocument, variables: { id: id} }],
        onCompleted: () => {
            notification.success({
                key: "edit-trialday-success",
                message: "Trial Day has been updated successfully!"
            })
            props.history.push(`/trialdays/detail/${id}`)
        },
        onError: (err) => apolloResponseErrorHandler(err)
    })

    useEffect(() => {
        if ( query.data?.getTrialDay && formRef.current ) formRef.current.setFieldsValue({
            date: query.data.getTrialDay.date,
            jobpackage: query.data.getTrialDay.pk,
            additionalinformation: query.data.getTrialDay.additionalInformation,
            applicant: query.data.getTrialDay.applicant.pk,
            supervisors: query.data.getTrialDay.supervisors.map( supervisor => supervisor.pk)
        })
    }, [formRef.current])

    const onFormFinish = (formData: TrialdayFormData) => {
        mutate({
            variables: {
                id: id,
                input: {
                    date: formData.date,
                    jobPackage: formData.jobpackage,
                    additionalInformation: formData.additionalinformation,
                    additionalInformationRendered: "<p></p>",
                    applicant: formData.applicant,
                    supervisors: formData.supervisors,
                    taskList: formData.tasklist,
                }
            }
        })
    }


    return (
        <BaseLayout>
            <ApCard title="Create Trialday">
                <TrialdayForm
                formtype="edit"
                ref={formRef as React.RefObject<FormInstance>}
                onFinish={onFormFinish}/>
            </ApCard>
        </BaseLayout>
    )
}

export default compose<Props, OuterProps>(
    withAuthentication()
)(EditTrialdayView)