import { CheckCircleOutlined, CloseCircleOutlined, InfoCircleOutlined, WarningOutlined } from "@ant-design/icons";
import { Divider } from "antd";
import Text from "antd/lib/typography/Text";
import React from "react";

interface OuterProps {
    title: string
    type: "success" | "warning" | "info" | "error"
    extras?: [React.ReactNode]
}

type Props = OuterProps;

class ResultComponent extends React.Component<Props> {

    private renderResult = () => {
        const {type, title } = this.props;

        switch (type) {
            case 'success':
                return (
                    <div className="wr-result">
                        <CheckCircleOutlined className="wr-result-icon wr-result-icon-success" />
                        <Text type="success">{title}</Text>
                    </div>
                )
            case 'info':
                return (
                    <div className="wr-result">
                        <InfoCircleOutlined className="wr-result-icon wr-result-icon-info" />
                        <Text className="ant-typography-info">{title}</Text>
                    </div>
                ) 
            case 'warning':
                return (
                    <div className="wr-result">
                        <WarningOutlined className="wr-result-icon wr-result-icon-warning" />
                        <Text type="warning">{title}</Text>
                    </div>
                ) 
            case 'error':
                return (
                    <div className="wr-result">
                        <CloseCircleOutlined className="wr-result-icon wr-result-icon-danger" />
                        <Text type="danger">{title}</Text>
                    </div>
                ) 
            default:
                console.log("Something went wrong with the ResultComponent");
        }
    }

    private renderExtras = ( extras : React.ReactNode[]) => {
        return (
            <div className="wr-result-extras">
                <Divider />
                <div className="wr-result-extras-inner">
                    {extras}
                </div>
            </div>
        )
    }

    render () {
        const { extras } = this.props;
        return (
            <div className="wr-result-wrapper">
                {this.renderResult()}
                { extras ? this.renderExtras(extras) : null}
            </div>
        )
    }
}

export default ResultComponent;