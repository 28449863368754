import React from "react"
import ApCard, { TitleLink } from "./ap-card";

interface OuterProps {
    sectionTitle: string
    titleLink?: TitleLink
    titleLevel?: 1 | 2 | 3 | 4 | 5
}

type Props = OuterProps;

class MarkdownSection extends React.Component<Props> {

    private renderChildren = () => {
        const { children } = this.props;

        if ( children ) {
            return children
        }
        return;
    }

    private renderContent = () => {
        const { sectionTitle, titleLink, titleLevel } = this.props;
        
        return (
            <ApCard 
                title={sectionTitle} 
                link={titleLink}
                titleLevel={titleLevel}>
                {this.renderChildren()}
            </ApCard>
        )
    }

    render () {
        return (this.renderContent())
    }
}

export default MarkdownSection;