import { Col, Row } from "antd";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { compose } from "recompose";
import ApplicantTask from "../../components/user/applicant/ApplicantTask";
import GeneralInformation from "../../components/user/applicant/GeneralInformation";
import { withAuthentication } from "../../hoc/isAuthendicated";
import BaseLayout from "../../layouts/BaseLayout";
import { UserInfoProps } from "../../models";


interface OuterProps {}

type Props = OuterProps & UserInfoProps & RouteComponentProps;

class TaskView extends React.Component<Props> {

    private renderContent = () => {
        const { whoami, loading } = this.props.data;

        if ( loading ) {
            return <>Loading Task View</>
        } else if ( whoami ) {
            return (
                <Row>
                    <Col lg={16} xs={24}>
                        <ApplicantTask applicant={whoami}/>
                    </Col>
                    <Col lg={8} xs={24}>
                        <Row>
                            <GeneralInformation applicant={whoami} />
                        </Row>
                    </Col>
                </Row>
            )
        } else {
            return <>Something went wrong</>
        }
    }

    render () {
        return (
            <BaseLayout>
                {this.renderContent()}
            </BaseLayout>
        )
    }
}

export default compose<Props, OuterProps>(
    withRouter,
    withAuthentication()
)(TaskView);