import { Button, notification, Popconfirm, Table, Typography } from "antd";
import React from "react";
import { compose } from "recompose";
import { 
    AllTrialDaysProps, 
    Scalars, 
    withAllTrialDays, 
    AllTrialdaysDocument, 
    DeleteTrialDayProps, 
    withDeleteTrialDay,
    SendTrialdayNotificationProps,
    withSendTrialdayNotification
} from "../../models";
import moment from "moment";
import { Link } from "react-router-dom";
import { DeleteOutlined, EditOutlined, EyeOutlined } from "@ant-design/icons";
import { apolloResponseErrorHandler } from "../../helper/errors";
import ApCard from "../base/ap-card";
import TrialdayAdminActionSelect from "./TrialdayAdminActionSelect";

interface State {
    selectedRowKeys: React.Key[],
    actionValue?: string
}

interface OuterProps {}

type Props = OuterProps & AllTrialDaysProps & DeleteTrialDayProps<void, "deleteTrialday"> & SendTrialdayNotificationProps<void, "sendTrialdayNotification">;

class AllTrialdaysList extends React.Component<Props, State> {

    state: State = {
        selectedRowKeys: [],
        actionValue: undefined
    }

    private handleDelete = async (pk: string) => {
        console.log(pk)
        const { deleteTrialday } = this.props;

        const response = await deleteTrialday({
            variables: {
                id: pk
            },
            refetchQueries: [
                {
                    query: AllTrialdaysDocument
                }
            ]
        }).catch(apolloResponseErrorHandler);

        if ( response.data?.deleteTrialDay?.found ) {
            notification.success({
                key: "delete-trialday-success",
                message: "Trialday has been deleted successfully!"
            })
        }
    }

    private tableColumns = [
        {
            title: "Date",
            dataIndex: "date",
            key: "date",
            render: ((date: Scalars["Date"]) => {
                const renderDate = moment(date).format("DD.MM.YYYY");
                return <Typography.Text>{renderDate}</Typography.Text>
            })
        },
        {
            title: "Applicant",
            dataIndex: "applicant",
            key: "applicant"
        },
        {
            title: "Job Package",
            dataIndex: "jobpackage",
            key: "jobpackage"
        },
        {
            title: "View",
            dataIndex: "view",
            key: "view",
            render: (pk: string) => (<Link to={`/trialdays/detail/${pk}`}><EyeOutlined/></Link>)
        },
        {
            title: "Edit",
            dataIndex: "edit",
            key: "edit",
            render: (pk: string) => (<Link to={`/trialdays/detail/${pk}/edit`}><EditOutlined/></Link>)
        },
        {
            title: "Delete",
            dataIndex: "delete",
            key: "delete",
            render: (pk: string) => (
                <Popconfirm
                title="Are you sure to delete the service?"
                onConfirm={() => this.handleDelete(pk)}
                okText="Delete"
                cancelText="Cancel" >
                    <DeleteOutlined/>
                </Popconfirm>
            )

        }
    ]

    private sendNotificationEmail = async (selectedRowKey: React.Key) => {
        // send notification
        const { sendTrialdayNotification } = this.props;

        const response = await sendTrialdayNotification({
            variables: {
                id: selectedRowKey as string
            }
        }).catch(apolloResponseErrorHandler);

        if ( response.data?.sendTrialDayNotification?.response ) {
            notification.success({
                key: "trailday-notification-success",
                message: "Applicant has been notified!"
            })
        }
    }

    private onActionSelect = (value: string) => {
        this.setState({
            actionValue: value
        })
    }

    private onActionClear = () => {
        this.setState({
            actionValue: undefined
        })
    }

    private onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        this.setState({
            selectedRowKeys: newSelectedRowKeys
        })
    }

    private onActionButtonClick = () => {
        const { selectedRowKeys } = this.state;

        // Here a switch case can be implemented
        this.sendNotificationEmail(selectedRowKeys[0])

        this.setState({
            selectedRowKeys: [],
            actionValue: undefined
        })
    }

    render () {
        const {data: { getAllTrialDays, loading }} = this.props;
        const { actionValue, selectedRowKeys } = this.state;
        
        if ( loading ) {
            return <>Loading</>
        } else if ( getAllTrialDays ) {
            const dataSource = getAllTrialDays.map( item => ({
                key: item?.pk,
                date: item?.date,
                applicant: item?.applicant.firstName + " " + item?.applicant.lastName,
                jobpackage: item?.jobPackage.name,
                view: item?.pk,
                edit: item?.pk,
                delete: item?.pk
            }));

            return (
                <ApCard title="All Trial Days" link={{
                    text: "Create Trial Day",
                    to: {
                        to: "/trialdays/create-new"
                    }
                }}>
                    <div className="wr-action-select-wrapper">
                        <TrialdayAdminActionSelect 
                        onSelect={this.onActionSelect} 
                        onClear={this.onActionClear} />
                        <Button
                        type="default"
                        disabled={actionValue && selectedRowKeys.length > 0 ? false : true}
                        onClick={this.onActionButtonClick}>Go</Button>
                    </div>
                    <Table
                    rowSelection={{
                        selectedRowKeys: this.state.selectedRowKeys,
                        type: "radio",
                        onChange: this.onSelectChange
                    }}
                    columns={this.tableColumns}
                    dataSource={dataSource}
                    pagination={false}
                    className="ap-table"/>
                </ApCard>
            )
        } else {<>Something went wrong in all trialdays list component!</>}
    }
}

export default compose<Props, OuterProps>(
    withAllTrialDays(),
    withDeleteTrialDay({
        name: "deleteTrialday"
    }),
    withSendTrialdayNotification({
        name: "sendTrialdayNotification"
    })
)(AllTrialdaysList);