import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
import * as ApolloReactHoc from '@apollo/client/react/hoc';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `Date` scalar type represents a Date
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  Date: any;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: any;
  /**
   * The `GenericScalar` scalar type represents a generic
   * GraphQL scalar value that could be:
   * String, Boolean, Int, Float, List or Object.
   */
  GenericScalar: any;
  /**
   * Create scalar that ignores normal serialization/deserialization, since
   * that will be handled by the multipart request spec
   */
  Upload: any;
};

export type ApplicantDataType = {
  __typename?: 'ApplicantDataType';
  user: UserType;
  heavenhrUrl: Scalars['String'];
  comment: Scalars['String'];
  commentRendered: Scalars['String'];
};

export type ChecklistItemType = {
  __typename?: 'ChecklistItemType';
  position: Scalars['Int'];
  description: Scalars['String'];
  descriptionRendered: Scalars['String'];
  pk: Scalars['ID'];
};

export type CreateJobPackageInput = {
  name: Scalars['String'];
  preTrialDayDescription?: Maybe<Scalars['String']>;
  preTrialDayDescriptionRendered: Scalars['String'];
  trialDayDescription?: Maybe<Scalars['String']>;
  trialDayDescriptionRendered: Scalars['String'];
  taskList?: Maybe<Array<Maybe<Scalars['ID']>>>;
  trialDays?: Maybe<Array<Maybe<Scalars['ID']>>>;
  checklistItems?: Maybe<Array<Maybe<CreateJobPackageInputExactJobPackageChecklistitems>>>;
  checklistItemsById?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type CreateJobPackageInputExactJobPackageChecklistitems = {
  position?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  descriptionRendered: Scalars['String'];
};

export type CreateServiceInput = {
  name: Scalars['String'];
  url: Scalars['String'];
  icon?: Maybe<Scalars['Upload']>;
  users?: Maybe<Array<Maybe<Scalars['ID']>>>;
  serviceUsers?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type CreateServiceMutation = {
  __typename?: 'CreateServiceMutation';
  service?: Maybe<ServiceType>;
};

export type CreateTaskListInput = {
  name: Scalars['String'];
  jobPackage?: Maybe<Array<Maybe<Scalars['ID']>>>;
  trialdaySet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  tasks?: Maybe<Array<Maybe<CreateTaskListInputExactTaskListTasks>>>;
  tasksById?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type CreateTaskListInputExactTaskListTasks = {
  position?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  descriptionRendered: Scalars['String'];
  acceptanceCriteria?: Maybe<Scalars['String']>;
  acceptanceCriteriaRendered?: Maybe<Scalars['String']>;
  help?: Maybe<Scalars['String']>;
  helpRendered?: Maybe<Scalars['String']>;
};

export type CreateTrialDayInput = {
  date?: Maybe<Scalars['Date']>;
  jobPackage: Scalars['ID'];
  applicant: Scalars['ID'];
  supervisors: Array<Maybe<Scalars['ID']>>;
  additionalInformation?: Maybe<Scalars['String']>;
  additionalInformationRendered: Scalars['String'];
  taskList: Scalars['ID'];
  applicantNotified?: Maybe<Scalars['Boolean']>;
};

export type CreateUserInput = {
  role: UserRole;
  lastLogin?: Maybe<Scalars['DateTime']>;
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser?: Maybe<Scalars['Boolean']>;
  /** The groups this user belongs to. A user will get all permissions granted to each of their groups. */
  groups?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specific permissions for this user. */
  userPermissions?: Maybe<Array<Maybe<Scalars['ID']>>>;
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  /** Designates whether the user can log into this admin site. */
  isStaff?: Maybe<Scalars['Boolean']>;
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive?: Maybe<Scalars['Boolean']>;
  dateJoined?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
  logentrySet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  applicantData?: Maybe<CreateUserInputCreateApplicantdata>;
  employeeData?: Maybe<CreateUserInputCreateEmployeedata>;
  passwordResets?: Maybe<Array<Maybe<Scalars['ID']>>>;
  invitations?: Maybe<Array<Maybe<Scalars['ID']>>>;
  trialDays?: Maybe<Array<Maybe<Scalars['ID']>>>;
  trialDaysSupervisored?: Maybe<Array<Maybe<Scalars['ID']>>>;
  serviceSet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  serviceUsers?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type CreateUserInputCreateApplicantdata = {
  heavenhrUrl?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentRendered: Scalars['String'];
};

export type CreateUserInputCreateEmployeedata = {
  title: Scalars['String'];
  contactInfo?: Maybe<Scalars['String']>;
  contactInfoRendered: Scalars['String'];
};

export type CreateUserInvitationMutation = {
  __typename?: 'CreateUserInvitationMutation';
  response?: Maybe<Scalars['Boolean']>;
};



export type DeleteServiceMutation = {
  __typename?: 'DeleteServiceMutation';
  found?: Maybe<Scalars['Boolean']>;
  deletedInputId?: Maybe<Scalars['ID']>;
  deletedId?: Maybe<Scalars['ID']>;
  deletedRawId?: Maybe<Scalars['ID']>;
};

export type DeleteUserServiceMutation = {
  __typename?: 'DeleteUserServiceMutation';
  service?: Maybe<UserServiceType>;
};

export type EmbeddedUserInvitationType = {
  __typename?: 'EmbeddedUserInvitationType';
  user: UserType;
  slug: Scalars['String'];
  hasBeenSent: Scalars['Boolean'];
  validUntil: Scalars['DateTime'];
  pk: Scalars['ID'];
};

export type EmployeeDataType = {
  __typename?: 'EmployeeDataType';
  user: UserType;
  title: Scalars['String'];
  contactInfo: Scalars['String'];
  contactInfoRendered: Scalars['String'];
};


export type JobPackageCreateMutation = {
  __typename?: 'JobPackageCreateMutation';
  jobPackage?: Maybe<JobPackageType>;
};

export type JobPackageDeleteMutation = {
  __typename?: 'JobPackageDeleteMutation';
  found?: Maybe<Scalars['Boolean']>;
  deletedInputId?: Maybe<Scalars['ID']>;
  deletedId?: Maybe<Scalars['ID']>;
  deletedRawId?: Maybe<Scalars['ID']>;
};

export type JobPackageType = {
  __typename?: 'JobPackageType';
  name: Scalars['String'];
  preTrialDayDescription: Scalars['String'];
  preTrialDayDescriptionRendered: Scalars['String'];
  trialDayDescription: Scalars['String'];
  trialDayDescriptionRendered: Scalars['String'];
  taskList: Array<TaskListType>;
  checklistItems: Array<ChecklistItemType>;
  pk: Scalars['ID'];
};

export type JobPackageUpdateMutation = {
  __typename?: 'JobPackageUpdateMutation';
  jobPackage?: Maybe<JobPackageType>;
};

/** Obtain JSON Web Token mutation */
export type ObtainJsonWebToken = {
  __typename?: 'ObtainJSONWebToken';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  token: Scalars['String'];
};

export type Refresh = {
  __typename?: 'Refresh';
  payload: Scalars['GenericScalar'];
  refreshExpiresIn: Scalars['Int'];
  token: Scalars['String'];
};

export type RootMutation = {
  __typename?: 'RootMutation';
  createTasklist?: Maybe<TaskListCreateMutation>;
  updateTasklist?: Maybe<TaskListUpdateMutation>;
  deleteTasklist?: Maybe<TaskListDeleteMutation>;
  createJobPackage?: Maybe<JobPackageCreateMutation>;
  updateJobPackage?: Maybe<JobPackageUpdateMutation>;
  deleteJobPackage?: Maybe<JobPackageDeleteMutation>;
  createTrialDay?: Maybe<TrialDayCreateMutation>;
  updateTrialDay?: Maybe<TrialDayUpdateMutation>;
  deleteTrialDay?: Maybe<TrialDayDeleteMutation>;
  sendTrialDayNotification?: Maybe<TrialDaySendNotificationMutation>;
  setUserService?: Maybe<SetUserServiceActivityMutation>;
  deleteUserService?: Maybe<DeleteUserServiceMutation>;
  createService?: Maybe<CreateServiceMutation>;
  updateService?: Maybe<UpdateServiceMutation>;
  deleteService?: Maybe<DeleteServiceMutation>;
  createUser?: Maybe<UserCreateMutation>;
  updateUser?: Maybe<UserUpdateMutation>;
  deleteUser?: Maybe<UserDeleteMutation>;
  inviteUser?: Maybe<CreateUserInvitationMutation>;
  useReset?: Maybe<UserPasswordResetMutation>;
  requestReset?: Maybe<UserPasswordResetRequestMutation>;
  useInvitation?: Maybe<UseUserInvitationMutation>;
  anonymizeUser?: Maybe<UserAnonymizeAndDeactivateMutation>;
};


export type RootMutationCreateTasklistArgs = {
  input: CreateTaskListInput;
};


export type RootMutationUpdateTasklistArgs = {
  id: Scalars['ID'];
  input: UpdateTaskListInput;
};


export type RootMutationDeleteTasklistArgs = {
  id: Scalars['ID'];
};


export type RootMutationCreateJobPackageArgs = {
  input: CreateJobPackageInput;
};


export type RootMutationUpdateJobPackageArgs = {
  id: Scalars['ID'];
  input: UpdateJobPackageInput;
};


export type RootMutationDeleteJobPackageArgs = {
  id: Scalars['ID'];
};


export type RootMutationCreateTrialDayArgs = {
  input: CreateTrialDayInput;
};


export type RootMutationUpdateTrialDayArgs = {
  id: Scalars['ID'];
  input: UpdateTrialDayInput;
};


export type RootMutationDeleteTrialDayArgs = {
  id: Scalars['ID'];
};


export type RootMutationSendTrialDayNotificationArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type RootMutationSetUserServiceArgs = {
  isActive: Scalars['Boolean'];
  serviceId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type RootMutationDeleteUserServiceArgs = {
  serviceId: Scalars['ID'];
  userId: Scalars['ID'];
};


export type RootMutationCreateServiceArgs = {
  input: CreateServiceInput;
};


export type RootMutationUpdateServiceArgs = {
  id: Scalars['ID'];
  input: UpdateServiceInput;
};


export type RootMutationDeleteServiceArgs = {
  id: Scalars['ID'];
};


export type RootMutationCreateUserArgs = {
  input: CreateUserInput;
};


export type RootMutationUpdateUserArgs = {
  id: Scalars['ID'];
  input: UpdateUserInput;
};


export type RootMutationDeleteUserArgs = {
  id: Scalars['ID'];
};


export type RootMutationInviteUserArgs = {
  email: Scalars['String'];
};


export type RootMutationUseResetArgs = {
  password: Scalars['String'];
  slug: Scalars['String'];
};


export type RootMutationRequestResetArgs = {
  email: Scalars['String'];
};


export type RootMutationUseInvitationArgs = {
  password: Scalars['String'];
  slug: Scalars['String'];
};


export type RootMutationAnonymizeUserArgs = {
  email: Scalars['String'];
};

export type RootQuery = {
  __typename?: 'RootQuery';
  service?: Maybe<ServiceType>;
  allServices?: Maybe<Array<Maybe<ServiceType>>>;
  userServices?: Maybe<Array<Maybe<UserServiceType>>>;
  myServices?: Maybe<Array<Maybe<UserServiceType>>>;
  userService?: Maybe<UserServiceType>;
  getSupervisor?: Maybe<UserType>;
  getSupervisorDates?: Maybe<Array<Maybe<TrialDayType>>>;
  getApplicantTrialDays?: Maybe<Array<Maybe<TrialDayType>>>;
  getAllTaskLists?: Maybe<Array<Maybe<TaskListType>>>;
  getTaskList?: Maybe<TaskListType>;
  getAllTrialDays?: Maybe<Array<Maybe<TrialDayType>>>;
  getTrialDay?: Maybe<TrialDayType>;
  getAllJobPackages?: Maybe<Array<Maybe<JobPackageType>>>;
  getJobPackage?: Maybe<JobPackageType>;
  getAllApplicants?: Maybe<Array<Maybe<UserType>>>;
  users?: Maybe<Array<Maybe<UserType>>>;
  user?: Maybe<UserType>;
  whoami?: Maybe<UserType>;
  reset?: Maybe<UserPasswordResetType>;
  invitation?: Maybe<UserInvitationType>;
  loginTest?: Maybe<Scalars['String']>;
  /** Obtain JSON Web Token mutation */
  tokenAuth?: Maybe<ObtainJsonWebToken>;
  verifyToken?: Maybe<Verify>;
  refreshToken?: Maybe<Refresh>;
};


export type RootQueryServiceArgs = {
  id: Scalars['ID'];
};


export type RootQueryUserServicesArgs = {
  user_id: Scalars['ID'];
};


export type RootQueryUserServiceArgs = {
  id: Scalars['ID'];
};


export type RootQueryGetSupervisorArgs = {
  id: Scalars['ID'];
};


export type RootQueryGetSupervisorDatesArgs = {
  supervisor_id: Scalars['ID'];
};


export type RootQueryGetApplicantTrialDaysArgs = {
  applicant_id: Scalars['ID'];
};


export type RootQueryGetTaskListArgs = {
  id: Scalars['ID'];
};


export type RootQueryGetTrialDayArgs = {
  id: Scalars['ID'];
};


export type RootQueryGetJobPackageArgs = {
  id: Scalars['ID'];
};


export type RootQueryUserArgs = {
  id: Scalars['ID'];
};


export type RootQueryResetArgs = {
  slug: Scalars['String'];
};


export type RootQueryInvitationArgs = {
  slug: Scalars['String'];
};


export type RootQueryTokenAuthArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
};


export type RootQueryVerifyTokenArgs = {
  token?: Maybe<Scalars['String']>;
};


export type RootQueryRefreshTokenArgs = {
  token?: Maybe<Scalars['String']>;
};

export type ServiceType = {
  __typename?: 'ServiceType';
  name: Scalars['String'];
  url: Scalars['String'];
  pk: Scalars['ID'];
  activeForUsers?: Maybe<Array<Maybe<UserType>>>;
  inactiveForUsers?: Maybe<Array<Maybe<UserType>>>;
  iconPath?: Maybe<Scalars['String']>;
};

export type SetUserServiceActivityMutation = {
  __typename?: 'SetUserServiceActivityMutation';
  service?: Maybe<UserServiceType>;
};

export type TaskListCreateMutation = {
  __typename?: 'TaskListCreateMutation';
  taskList?: Maybe<TaskListType>;
};

export type TaskListDeleteMutation = {
  __typename?: 'TaskListDeleteMutation';
  found?: Maybe<Scalars['Boolean']>;
  deletedInputId?: Maybe<Scalars['ID']>;
  deletedId?: Maybe<Scalars['ID']>;
  deletedRawId?: Maybe<Scalars['ID']>;
};

export type TaskListType = {
  __typename?: 'TaskListType';
  name: Scalars['String'];
  tasks: Array<TaskType>;
  pk: Scalars['ID'];
};

export type TaskListUpdateMutation = {
  __typename?: 'TaskListUpdateMutation';
  taskList?: Maybe<TaskListType>;
};

export type TaskType = {
  __typename?: 'TaskType';
  description: Scalars['String'];
  descriptionRendered: Scalars['String'];
  acceptanceCriteria: Scalars['String'];
  acceptanceCriteriaRendered: Scalars['String'];
  help: Scalars['String'];
  helpRendered: Scalars['String'];
  pk: Scalars['ID'];
};

export type TrialDayCreateMutation = {
  __typename?: 'TrialDayCreateMutation';
  trialDay?: Maybe<TrialDayType>;
};

export type TrialDayDeleteMutation = {
  __typename?: 'TrialDayDeleteMutation';
  found?: Maybe<Scalars['Boolean']>;
  deletedInputId?: Maybe<Scalars['ID']>;
  deletedId?: Maybe<Scalars['ID']>;
  deletedRawId?: Maybe<Scalars['ID']>;
};

export type TrialDaySendNotificationMutation = {
  __typename?: 'TrialDaySendNotificationMutation';
  response?: Maybe<Scalars['Boolean']>;
};

export type TrialDayType = {
  __typename?: 'TrialDayType';
  date: Scalars['Date'];
  jobPackage: JobPackageType;
  applicant: UserType;
  supervisors: Array<UserType>;
  additionalInformation: Scalars['String'];
  additionalInformationRendered: Scalars['String'];
  taskList: TaskListType;
  pk: Scalars['ID'];
  tasks?: Maybe<Array<Maybe<TaskType>>>;
};

export type TrialDayUpdateMutation = {
  __typename?: 'TrialDayUpdateMutation';
  trialDay?: Maybe<TrialDayType>;
};

export type UpdateJobPackageInput = {
  name?: Maybe<Scalars['String']>;
  preTrialDayDescription?: Maybe<Scalars['String']>;
  preTrialDayDescriptionRendered: Scalars['String'];
  trialDayDescription?: Maybe<Scalars['String']>;
  trialDayDescriptionRendered: Scalars['String'];
  taskList?: Maybe<Array<Maybe<Scalars['ID']>>>;
  trialDays?: Maybe<Array<Maybe<Scalars['ID']>>>;
  checklistItems?: Maybe<Array<Maybe<UpdateJobPackageInputExactJobPackageChecklistitems>>>;
  checklistItemsById?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type UpdateJobPackageInputExactJobPackageChecklistitems = {
  position?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  descriptionRendered: Scalars['String'];
};

export type UpdateServiceInput = {
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  icon?: Maybe<Scalars['Upload']>;
  users?: Maybe<Array<Maybe<Scalars['ID']>>>;
  serviceUsers?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type UpdateServiceMutation = {
  __typename?: 'UpdateServiceMutation';
  service?: Maybe<ServiceType>;
};

export type UpdateTaskListInput = {
  name?: Maybe<Scalars['String']>;
  jobPackage?: Maybe<Array<Maybe<Scalars['ID']>>>;
  trialdaySet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  tasks?: Maybe<Array<Maybe<UpdateTaskListInputExactTaskListTasks>>>;
  tasksById?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type UpdateTaskListInputExactTaskListTasks = {
  position?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  descriptionRendered: Scalars['String'];
  acceptanceCriteria?: Maybe<Scalars['String']>;
  acceptanceCriteriaRendered?: Maybe<Scalars['String']>;
  help?: Maybe<Scalars['String']>;
  helpRendered?: Maybe<Scalars['String']>;
};

export type UpdateTrialDayInput = {
  date?: Maybe<Scalars['Date']>;
  jobPackage?: Maybe<Scalars['ID']>;
  applicant?: Maybe<Scalars['ID']>;
  supervisors?: Maybe<Array<Maybe<Scalars['ID']>>>;
  additionalInformation?: Maybe<Scalars['String']>;
  additionalInformationRendered: Scalars['String'];
  taskList?: Maybe<Scalars['ID']>;
  applicantNotified?: Maybe<Scalars['Boolean']>;
};

export type UpdateUserInput = {
  role?: Maybe<UserRole>;
  password?: Maybe<Scalars['String']>;
  lastLogin?: Maybe<Scalars['DateTime']>;
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser?: Maybe<Scalars['Boolean']>;
  /** The groups this user belongs to. A user will get all permissions granted to each of their groups. */
  groups?: Maybe<Array<Maybe<Scalars['ID']>>>;
  /** Specific permissions for this user. */
  userPermissions?: Maybe<Array<Maybe<Scalars['ID']>>>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  /** Designates whether the user can log into this admin site. */
  isStaff?: Maybe<Scalars['Boolean']>;
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive?: Maybe<Scalars['Boolean']>;
  dateJoined?: Maybe<Scalars['DateTime']>;
  email?: Maybe<Scalars['String']>;
  logentrySet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  applicantData?: Maybe<UpdateUserInputCreateApplicantdata>;
  employeeData?: Maybe<UpdateUserInputCreateEmployeedata>;
  passwordResets?: Maybe<Array<Maybe<Scalars['ID']>>>;
  invitations?: Maybe<Array<Maybe<Scalars['ID']>>>;
  trialDays?: Maybe<Array<Maybe<Scalars['ID']>>>;
  trialDaysSupervisored?: Maybe<Array<Maybe<Scalars['ID']>>>;
  serviceSet?: Maybe<Array<Maybe<Scalars['ID']>>>;
  serviceUsers?: Maybe<Array<Maybe<Scalars['ID']>>>;
};

export type UpdateUserInputCreateApplicantdata = {
  heavenhrUrl?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  commentRendered: Scalars['String'];
};

export type UpdateUserInputCreateEmployeedata = {
  title: Scalars['String'];
  contactInfo?: Maybe<Scalars['String']>;
  contactInfoRendered: Scalars['String'];
};


export type UseUserInvitationMutation = {
  __typename?: 'UseUserInvitationMutation';
  user?: Maybe<UserType>;
};

export type UserAnonymizeAndDeactivateMutation = {
  __typename?: 'UserAnonymizeAndDeactivateMutation';
  response?: Maybe<Scalars['Boolean']>;
};

export type UserCreateMutation = {
  __typename?: 'UserCreateMutation';
  user?: Maybe<UserType>;
};

export type UserDeleteMutation = {
  __typename?: 'UserDeleteMutation';
  found?: Maybe<Scalars['Boolean']>;
  deletedInputId?: Maybe<Scalars['ID']>;
  deletedId?: Maybe<Scalars['ID']>;
  deletedRawId?: Maybe<Scalars['ID']>;
};

export type UserInvitationType = {
  __typename?: 'UserInvitationType';
  user: UserType;
};

export type UserPasswordResetMutation = {
  __typename?: 'UserPasswordResetMutation';
  user?: Maybe<UserType>;
};

export type UserPasswordResetRequestMutation = {
  __typename?: 'UserPasswordResetRequestMutation';
  response?: Maybe<Scalars['Boolean']>;
};

export type UserPasswordResetType = {
  __typename?: 'UserPasswordResetType';
  user: UserType;
};

/** An enumeration. */
export enum UserRole {
  /** Employee */
  Employee = 'EMPLOYEE',
  /** Recruiter */
  Recruiter = 'RECRUITER',
  /** Applicant */
  Applicant = 'APPLICANT'
}

export type UserServiceType = {
  __typename?: 'UserServiceType';
  isActive: Scalars['Boolean'];
  pk: Scalars['ID'];
  servicePk: Scalars['ID'];
  userPk: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  iconPath?: Maybe<Scalars['String']>;
};

export type UserType = {
  __typename?: 'UserType';
  /** Designates that this user has all permissions without explicitly assigning them. */
  isSuperuser: Scalars['Boolean'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  /** Designates whether the user can log into this admin site. */
  isStaff: Scalars['Boolean'];
  /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
  isActive: Scalars['Boolean'];
  email: Scalars['String'];
  role: UserRole;
  applicantData?: Maybe<ApplicantDataType>;
  employeeData?: Maybe<EmployeeDataType>;
  pk: Scalars['ID'];
  invitations?: Maybe<Array<Maybe<EmbeddedUserInvitationType>>>;
};

export type UserUpdateMutation = {
  __typename?: 'UserUpdateMutation';
  user?: Maybe<UserType>;
};

export type Verify = {
  __typename?: 'Verify';
  payload: Scalars['GenericScalar'];
};

export type ApplicantTrialDaysQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ApplicantTrialDaysQuery = (
  { __typename?: 'RootQuery' }
  & { getApplicantTrialDays?: Maybe<Array<Maybe<(
    { __typename?: 'TrialDayType' }
    & Pick<TrialDayType, 'pk' | 'date' | 'additionalInformation' | 'additionalInformationRendered'>
    & { jobPackage: (
      { __typename?: 'JobPackageType' }
      & Pick<JobPackageType, 'name' | 'preTrialDayDescription' | 'preTrialDayDescriptionRendered' | 'trialDayDescription' | 'trialDayDescriptionRendered' | 'pk'>
      & { taskList: Array<(
        { __typename?: 'TaskListType' }
        & Pick<TaskListType, 'pk' | 'name'>
        & { tasks: Array<(
          { __typename?: 'TaskType' }
          & Pick<TaskType, 'description' | 'acceptanceCriteria' | 'help' | 'pk'>
        )> }
      )>, checklistItems: Array<(
        { __typename?: 'ChecklistItemType' }
        & Pick<ChecklistItemType, 'description' | 'descriptionRendered' | 'pk'>
      )> }
    ), applicant: (
      { __typename?: 'UserType' }
      & Pick<UserType, 'isSuperuser' | 'firstName' | 'lastName' | 'isStaff' | 'isActive' | 'email' | 'role' | 'pk'>
    ), supervisors: Array<(
      { __typename?: 'UserType' }
      & Pick<UserType, 'isSuperuser' | 'firstName' | 'lastName' | 'isStaff' | 'isActive' | 'email' | 'role' | 'pk'>
    )>, tasks?: Maybe<Array<Maybe<(
      { __typename?: 'TaskType' }
      & Pick<TaskType, 'pk' | 'description' | 'descriptionRendered' | 'acceptanceCriteria' | 'acceptanceCriteriaRendered' | 'help' | 'helpRendered'>
    )>>> }
  )>>> }
);

export type UserInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type UserInfoQuery = (
  { __typename?: 'RootQuery' }
  & { whoami?: Maybe<(
    { __typename?: 'UserType' }
    & Pick<UserType, 'isSuperuser' | 'firstName' | 'lastName' | 'isStaff' | 'isActive' | 'email' | 'role' | 'pk'>
  )> }
);

export type TokenQueryVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type TokenQuery = (
  { __typename?: 'RootQuery' }
  & { tokenAuth?: Maybe<(
    { __typename?: 'ObtainJSONWebToken' }
    & Pick<ObtainJsonWebToken, 'payload' | 'refreshExpiresIn' | 'token'>
  )> }
);

export type InvitationQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type InvitationQuery = (
  { __typename?: 'RootQuery' }
  & { invitation?: Maybe<(
    { __typename?: 'UserInvitationType' }
    & { user: (
      { __typename?: 'UserType' }
      & Pick<UserType, 'isSuperuser' | 'firstName' | 'lastName' | 'isStaff' | 'isActive' | 'email' | 'role' | 'pk'>
    ) }
  )> }
);

export type UseInvitationMutationVariables = Exact<{
  password: Scalars['String'];
  slug: Scalars['String'];
}>;


export type UseInvitationMutation = (
  { __typename?: 'RootMutation' }
  & { useInvitation?: Maybe<(
    { __typename?: 'UseUserInvitationMutation' }
    & { user?: Maybe<(
      { __typename?: 'UserType' }
      & Pick<UserType, 'isSuperuser' | 'firstName' | 'lastName' | 'isStaff' | 'isActive' | 'email' | 'role' | 'pk'>
    )> }
  )> }
);

export type RequestResetMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type RequestResetMutation = (
  { __typename?: 'RootMutation' }
  & { requestReset?: Maybe<(
    { __typename?: 'UserPasswordResetRequestMutation' }
    & Pick<UserPasswordResetRequestMutation, 'response'>
  )> }
);

export type ResetQueryVariables = Exact<{
  slug: Scalars['String'];
}>;


export type ResetQuery = (
  { __typename?: 'RootQuery' }
  & { reset?: Maybe<(
    { __typename?: 'UserPasswordResetType' }
    & { user: (
      { __typename?: 'UserType' }
      & Pick<UserType, 'isSuperuser' | 'firstName' | 'lastName' | 'isStaff' | 'isActive' | 'email' | 'role' | 'pk'>
    ) }
  )> }
);

export type UseResetMutationVariables = Exact<{
  password: Scalars['String'];
  slug: Scalars['String'];
}>;


export type UseResetMutation = (
  { __typename?: 'RootMutation' }
  & { useReset?: Maybe<(
    { __typename?: 'UserPasswordResetMutation' }
    & { user?: Maybe<(
      { __typename?: 'UserType' }
      & Pick<UserType, 'isSuperuser' | 'firstName' | 'lastName' | 'isStaff' | 'isActive' | 'email' | 'role' | 'pk'>
    )> }
  )> }
);

export type SupervisorDatesQueryVariables = Exact<{
  supervisor_id: Scalars['ID'];
}>;


export type SupervisorDatesQuery = (
  { __typename?: 'RootQuery' }
  & { getSupervisorDates?: Maybe<Array<Maybe<(
    { __typename?: 'TrialDayType' }
    & Pick<TrialDayType, 'date' | 'additionalInformation' | 'additionalInformationRendered' | 'pk'>
    & { jobPackage: (
      { __typename?: 'JobPackageType' }
      & Pick<JobPackageType, 'name' | 'preTrialDayDescription' | 'preTrialDayDescriptionRendered' | 'trialDayDescription' | 'trialDayDescriptionRendered' | 'pk'>
      & { taskList: Array<(
        { __typename?: 'TaskListType' }
        & Pick<TaskListType, 'pk' | 'name'>
        & { tasks: Array<(
          { __typename?: 'TaskType' }
          & Pick<TaskType, 'description' | 'acceptanceCriteria' | 'help' | 'pk'>
        )> }
      )>, checklistItems: Array<(
        { __typename?: 'ChecklistItemType' }
        & Pick<ChecklistItemType, 'description' | 'descriptionRendered' | 'pk'>
      )> }
    ), applicant: (
      { __typename?: 'UserType' }
      & Pick<UserType, 'isSuperuser' | 'firstName' | 'lastName' | 'isStaff' | 'isActive' | 'email' | 'role' | 'pk'>
    ), supervisors: Array<(
      { __typename?: 'UserType' }
      & Pick<UserType, 'isSuperuser' | 'firstName' | 'lastName' | 'isStaff' | 'isActive' | 'email' | 'role' | 'pk'>
    )> }
  )>>> }
);

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutation = (
  { __typename?: 'RootMutation' }
  & { createUser?: Maybe<(
    { __typename?: 'UserCreateMutation' }
    & { user?: Maybe<(
      { __typename?: 'UserType' }
      & Pick<UserType, 'pk' | 'isSuperuser' | 'firstName' | 'lastName' | 'isStaff' | 'isActive' | 'email' | 'role'>
      & { applicantData?: Maybe<(
        { __typename?: 'ApplicantDataType' }
        & Pick<ApplicantDataType, 'heavenhrUrl' | 'comment'>
      )>, employeeData?: Maybe<(
        { __typename?: 'EmployeeDataType' }
        & Pick<EmployeeDataType, 'title' | 'contactInfo'>
      )> }
    )> }
  )> }
);

export type UpdateUserMutationVariables = Exact<{
  pk: Scalars['ID'];
  input: UpdateUserInput;
}>;


export type UpdateUserMutation = (
  { __typename?: 'RootMutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'UserUpdateMutation' }
    & { user?: Maybe<(
      { __typename?: 'UserType' }
      & Pick<UserType, 'pk' | 'isSuperuser' | 'firstName' | 'lastName' | 'isStaff' | 'isActive' | 'email' | 'role'>
      & { applicantData?: Maybe<(
        { __typename?: 'ApplicantDataType' }
        & Pick<ApplicantDataType, 'heavenhrUrl' | 'comment'>
      )>, employeeData?: Maybe<(
        { __typename?: 'EmployeeDataType' }
        & Pick<EmployeeDataType, 'title' | 'contactInfo'>
      )> }
    )> }
  )> }
);

export type InviteUserMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type InviteUserMutation = (
  { __typename?: 'RootMutation' }
  & { inviteUser?: Maybe<(
    { __typename?: 'CreateUserInvitationMutation' }
    & Pick<CreateUserInvitationMutation, 'response'>
  )> }
);

export type DeleteUserMutationVariables = Exact<{
  pk: Scalars['ID'];
}>;


export type DeleteUserMutation = (
  { __typename?: 'RootMutation' }
  & { deleteUser?: Maybe<(
    { __typename?: 'UserDeleteMutation' }
    & Pick<UserDeleteMutation, 'found' | 'deletedInputId' | 'deletedId' | 'deletedRawId'>
  )> }
);

export type AnonymizeUserMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type AnonymizeUserMutation = (
  { __typename?: 'RootMutation' }
  & { anonymizeUser?: Maybe<(
    { __typename?: 'UserAnonymizeAndDeactivateMutation' }
    & Pick<UserAnonymizeAndDeactivateMutation, 'response'>
  )> }
);

export type DeleteTaskListMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteTaskListMutation = (
  { __typename?: 'RootMutation' }
  & { deleteTasklist?: Maybe<(
    { __typename?: 'TaskListDeleteMutation' }
    & Pick<TaskListDeleteMutation, 'found' | 'deletedInputId' | 'deletedId' | 'deletedRawId'>
  )> }
);

export type UpdateTaskListMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateTaskListInput;
}>;


export type UpdateTaskListMutation = (
  { __typename?: 'RootMutation' }
  & { updateTasklist?: Maybe<(
    { __typename?: 'TaskListUpdateMutation' }
    & { taskList?: Maybe<(
      { __typename?: 'TaskListType' }
      & Pick<TaskListType, 'pk' | 'name'>
      & { tasks: Array<(
        { __typename?: 'TaskType' }
        & Pick<TaskType, 'pk' | 'description' | 'acceptanceCriteria' | 'help'>
      )> }
    )> }
  )> }
);

export type CreateJobPackageMutationVariables = Exact<{
  input: CreateJobPackageInput;
}>;


export type CreateJobPackageMutation = (
  { __typename?: 'RootMutation' }
  & { createJobPackage?: Maybe<(
    { __typename?: 'JobPackageCreateMutation' }
    & { jobPackage?: Maybe<(
      { __typename?: 'JobPackageType' }
      & Pick<JobPackageType, 'pk' | 'name' | 'preTrialDayDescription' | 'preTrialDayDescriptionRendered' | 'trialDayDescription' | 'trialDayDescriptionRendered'>
      & { taskList: Array<(
        { __typename?: 'TaskListType' }
        & Pick<TaskListType, 'name' | 'pk'>
        & { tasks: Array<(
          { __typename?: 'TaskType' }
          & Pick<TaskType, 'description' | 'descriptionRendered' | 'acceptanceCriteria' | 'acceptanceCriteriaRendered' | 'help' | 'helpRendered' | 'pk'>
        )> }
      )>, checklistItems: Array<(
        { __typename?: 'ChecklistItemType' }
        & Pick<ChecklistItemType, 'description' | 'descriptionRendered' | 'pk'>
      )> }
    )> }
  )> }
);

export type UpdateJobPackageMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateJobPackageInput;
}>;


export type UpdateJobPackageMutation = (
  { __typename?: 'RootMutation' }
  & { updateJobPackage?: Maybe<(
    { __typename?: 'JobPackageUpdateMutation' }
    & { jobPackage?: Maybe<(
      { __typename?: 'JobPackageType' }
      & Pick<JobPackageType, 'pk' | 'name' | 'preTrialDayDescription' | 'preTrialDayDescriptionRendered' | 'trialDayDescription' | 'trialDayDescriptionRendered'>
      & { taskList: Array<(
        { __typename?: 'TaskListType' }
        & Pick<TaskListType, 'name' | 'pk'>
        & { tasks: Array<(
          { __typename?: 'TaskType' }
          & Pick<TaskType, 'description' | 'descriptionRendered' | 'acceptanceCriteria' | 'acceptanceCriteriaRendered' | 'help' | 'helpRendered' | 'pk'>
        )> }
      )>, checklistItems: Array<(
        { __typename?: 'ChecklistItemType' }
        & Pick<ChecklistItemType, 'description' | 'descriptionRendered' | 'pk'>
      )> }
    )> }
  )> }
);

export type CreateTaskListMutationVariables = Exact<{
  input: CreateTaskListInput;
}>;


export type CreateTaskListMutation = (
  { __typename?: 'RootMutation' }
  & { createTasklist?: Maybe<(
    { __typename?: 'TaskListCreateMutation' }
    & { taskList?: Maybe<(
      { __typename?: 'TaskListType' }
      & Pick<TaskListType, 'pk' | 'name'>
      & { tasks: Array<(
        { __typename?: 'TaskType' }
        & Pick<TaskType, 'pk' | 'description' | 'acceptanceCriteria' | 'help'>
      )> }
    )> }
  )> }
);

export type DeleteJobPackageMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteJobPackageMutation = (
  { __typename?: 'RootMutation' }
  & { deleteJobPackage?: Maybe<(
    { __typename?: 'JobPackageDeleteMutation' }
    & Pick<JobPackageDeleteMutation, 'found' | 'deletedInputId' | 'deletedId' | 'deletedRawId'>
  )> }
);

export type AllTrialDaysQueryVariables = Exact<{ [key: string]: never; }>;


export type AllTrialDaysQuery = (
  { __typename?: 'RootQuery' }
  & { getAllTrialDays?: Maybe<Array<Maybe<(
    { __typename?: 'TrialDayType' }
    & Pick<TrialDayType, 'date' | 'additionalInformation' | 'additionalInformationRendered' | 'pk'>
    & { jobPackage: (
      { __typename?: 'JobPackageType' }
      & Pick<JobPackageType, 'name' | 'preTrialDayDescription' | 'preTrialDayDescriptionRendered' | 'trialDayDescription' | 'trialDayDescriptionRendered' | 'pk'>
      & { taskList: Array<(
        { __typename?: 'TaskListType' }
        & Pick<TaskListType, 'pk' | 'name'>
        & { tasks: Array<(
          { __typename?: 'TaskType' }
          & Pick<TaskType, 'description' | 'acceptanceCriteria' | 'help' | 'pk'>
        )> }
      )>, checklistItems: Array<(
        { __typename?: 'ChecklistItemType' }
        & Pick<ChecklistItemType, 'description' | 'descriptionRendered' | 'position' | 'pk'>
      )> }
    ), applicant: (
      { __typename?: 'UserType' }
      & Pick<UserType, 'isSuperuser' | 'firstName' | 'lastName' | 'isStaff' | 'isActive' | 'email' | 'role' | 'pk'>
    ), supervisors: Array<(
      { __typename?: 'UserType' }
      & Pick<UserType, 'isSuperuser' | 'firstName' | 'lastName' | 'isStaff' | 'isActive' | 'email' | 'role' | 'pk'>
    )> }
  )>>> }
);

export type AllApplicantsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllApplicantsQuery = (
  { __typename?: 'RootQuery' }
  & { getAllApplicants?: Maybe<Array<Maybe<(
    { __typename?: 'UserType' }
    & Pick<UserType, 'isSuperuser' | 'firstName' | 'lastName' | 'isStaff' | 'isActive' | 'email' | 'role' | 'pk'>
  )>>> }
);

export type UserQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type UserQuery = (
  { __typename?: 'RootQuery' }
  & { user?: Maybe<(
    { __typename?: 'UserType' }
    & Pick<UserType, 'pk' | 'isSuperuser' | 'firstName' | 'lastName' | 'isStaff' | 'isActive' | 'email' | 'role'>
    & { applicantData?: Maybe<(
      { __typename?: 'ApplicantDataType' }
      & Pick<ApplicantDataType, 'heavenhrUrl' | 'comment'>
    )>, employeeData?: Maybe<(
      { __typename?: 'EmployeeDataType' }
      & Pick<EmployeeDataType, 'title' | 'contactInfo'>
    )> }
  )> }
);

export type AllUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type AllUsersQuery = (
  { __typename?: 'RootQuery' }
  & { users?: Maybe<Array<Maybe<(
    { __typename?: 'UserType' }
    & Pick<UserType, 'pk' | 'isSuperuser' | 'firstName' | 'lastName' | 'isStaff' | 'isActive' | 'email' | 'role'>
    & { applicantData?: Maybe<(
      { __typename?: 'ApplicantDataType' }
      & Pick<ApplicantDataType, 'heavenhrUrl' | 'comment'>
    )>, employeeData?: Maybe<(
      { __typename?: 'EmployeeDataType' }
      & Pick<EmployeeDataType, 'title' | 'contactInfo'>
    )> }
  )>>> }
);

export type TaskListQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TaskListQuery = (
  { __typename?: 'RootQuery' }
  & { getTaskList?: Maybe<(
    { __typename?: 'TaskListType' }
    & Pick<TaskListType, 'name' | 'pk'>
    & { tasks: Array<(
      { __typename?: 'TaskType' }
      & Pick<TaskType, 'pk' | 'description' | 'descriptionRendered' | 'acceptanceCriteria' | 'acceptanceCriteriaRendered' | 'help' | 'helpRendered'>
    )> }
  )> }
);

export type AllJobPackagesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllJobPackagesQuery = (
  { __typename?: 'RootQuery' }
  & { getAllJobPackages?: Maybe<Array<Maybe<(
    { __typename?: 'JobPackageType' }
    & Pick<JobPackageType, 'name' | 'preTrialDayDescription' | 'preTrialDayDescriptionRendered' | 'trialDayDescription' | 'trialDayDescriptionRendered' | 'pk'>
    & { taskList: Array<(
      { __typename?: 'TaskListType' }
      & Pick<TaskListType, 'pk' | 'name'>
      & { tasks: Array<(
        { __typename?: 'TaskType' }
        & Pick<TaskType, 'description' | 'acceptanceCriteria' | 'help' | 'pk'>
      )> }
    )>, checklistItems: Array<(
      { __typename?: 'ChecklistItemType' }
      & Pick<ChecklistItemType, 'description' | 'descriptionRendered' | 'pk'>
    )> }
  )>>> }
);

export type JobPackageQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type JobPackageQuery = (
  { __typename?: 'RootQuery' }
  & { getJobPackage?: Maybe<(
    { __typename?: 'JobPackageType' }
    & Pick<JobPackageType, 'name' | 'preTrialDayDescription' | 'preTrialDayDescriptionRendered' | 'trialDayDescription' | 'trialDayDescriptionRendered' | 'pk'>
    & { taskList: Array<(
      { __typename?: 'TaskListType' }
      & Pick<TaskListType, 'name' | 'pk'>
      & { tasks: Array<(
        { __typename?: 'TaskType' }
        & Pick<TaskType, 'pk' | 'description' | 'descriptionRendered' | 'acceptanceCriteria' | 'acceptanceCriteriaRendered' | 'help' | 'helpRendered'>
      )> }
    )>, checklistItems: Array<(
      { __typename?: 'ChecklistItemType' }
      & Pick<ChecklistItemType, 'description' | 'descriptionRendered' | 'position' | 'pk'>
    )> }
  )> }
);

export type AllTaskListsQueryVariables = Exact<{ [key: string]: never; }>;


export type AllTaskListsQuery = (
  { __typename?: 'RootQuery' }
  & { getAllTaskLists?: Maybe<Array<Maybe<(
    { __typename?: 'TaskListType' }
    & Pick<TaskListType, 'name' | 'pk'>
    & { tasks: Array<(
      { __typename?: 'TaskType' }
      & Pick<TaskType, 'pk' | 'description' | 'descriptionRendered' | 'acceptanceCriteria' | 'acceptanceCriteriaRendered' | 'help' | 'helpRendered'>
    )> }
  )>>> }
);

export type DeleteSingleServiceMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteSingleServiceMutation = (
  { __typename?: 'RootMutation' }
  & { deleteService?: Maybe<(
    { __typename?: 'DeleteServiceMutation' }
    & Pick<DeleteServiceMutation, 'found' | 'deletedInputId' | 'deletedId' | 'deletedRawId'>
  )> }
);

export type UpdateSingleServiceMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateServiceInput;
}>;


export type UpdateSingleServiceMutation = (
  { __typename?: 'RootMutation' }
  & { updateService?: Maybe<(
    { __typename?: 'UpdateServiceMutation' }
    & { service?: Maybe<(
      { __typename?: 'ServiceType' }
      & Pick<ServiceType, 'name' | 'url' | 'pk'>
    )> }
  )> }
);

export type CreateSingleServiceMutationVariables = Exact<{
  input: CreateServiceInput;
}>;


export type CreateSingleServiceMutation = (
  { __typename?: 'RootMutation' }
  & { createService?: Maybe<(
    { __typename?: 'CreateServiceMutation' }
    & { service?: Maybe<(
      { __typename?: 'ServiceType' }
      & Pick<ServiceType, 'name' | 'url' | 'pk'>
    )> }
  )> }
);

export type UserServicesQueryVariables = Exact<{
  user_id: Scalars['ID'];
}>;


export type UserServicesQuery = (
  { __typename?: 'RootQuery' }
  & { userServices?: Maybe<Array<Maybe<(
    { __typename?: 'UserServiceType' }
    & Pick<UserServiceType, 'isActive' | 'pk' | 'servicePk' | 'userPk' | 'name' | 'url'>
  )>>> }
);

export type MyServicesQueryVariables = Exact<{ [key: string]: never; }>;


export type MyServicesQuery = (
  { __typename?: 'RootQuery' }
  & { myServices?: Maybe<Array<Maybe<(
    { __typename?: 'UserServiceType' }
    & Pick<UserServiceType, 'isActive' | 'pk' | 'servicePk' | 'userPk' | 'name' | 'url' | 'iconPath'>
  )>>> }
);

export type AllServicesQueryVariables = Exact<{ [key: string]: never; }>;


export type AllServicesQuery = (
  { __typename?: 'RootQuery' }
  & { allServices?: Maybe<Array<Maybe<(
    { __typename?: 'ServiceType' }
    & Pick<ServiceType, 'name' | 'url' | 'pk'>
  )>>> }
);

export type ServiceQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type ServiceQuery = (
  { __typename?: 'RootQuery' }
  & { service?: Maybe<(
    { __typename?: 'ServiceType' }
    & Pick<ServiceType, 'name' | 'url' | 'pk'>
    & { activeForUsers?: Maybe<Array<Maybe<(
      { __typename?: 'UserType' }
      & Pick<UserType, 'pk' | 'firstName' | 'lastName' | 'isActive' | 'role'>
    )>>> }
  )> }
);

export type DeleteTrialDayMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteTrialDayMutation = (
  { __typename?: 'RootMutation' }
  & { deleteTrialDay?: Maybe<(
    { __typename?: 'TrialDayDeleteMutation' }
    & Pick<TrialDayDeleteMutation, 'found' | 'deletedInputId' | 'deletedId' | 'deletedRawId'>
  )> }
);

export type CreateTrialdayMutationVariables = Exact<{
  input: CreateTrialDayInput;
}>;


export type CreateTrialdayMutation = (
  { __typename?: 'RootMutation' }
  & { createTrialDay?: Maybe<(
    { __typename?: 'TrialDayCreateMutation' }
    & { trialDay?: Maybe<(
      { __typename?: 'TrialDayType' }
      & Pick<TrialDayType, 'date' | 'additionalInformation' | 'pk'>
      & { jobPackage: (
        { __typename?: 'JobPackageType' }
        & Pick<JobPackageType, 'name' | 'pk'>
      ), applicant: (
        { __typename?: 'UserType' }
        & Pick<UserType, 'firstName' | 'lastName' | 'email' | 'pk'>
      ), supervisors: Array<(
        { __typename?: 'UserType' }
        & Pick<UserType, 'firstName' | 'lastName' | 'email' | 'pk'>
      )>, tasks?: Maybe<Array<Maybe<(
        { __typename?: 'TaskType' }
        & Pick<TaskType, 'description' | 'acceptanceCriteria' | 'help' | 'pk'>
      )>>> }
    )> }
  )> }
);

export type UpdateTrialDayMutationVariables = Exact<{
  id: Scalars['ID'];
  input: UpdateTrialDayInput;
}>;


export type UpdateTrialDayMutation = (
  { __typename?: 'RootMutation' }
  & { updateTrialDay?: Maybe<(
    { __typename?: 'TrialDayUpdateMutation' }
    & { trialDay?: Maybe<(
      { __typename?: 'TrialDayType' }
      & Pick<TrialDayType, 'date' | 'additionalInformation' | 'pk'>
      & { jobPackage: (
        { __typename?: 'JobPackageType' }
        & Pick<JobPackageType, 'name' | 'pk'>
      ), applicant: (
        { __typename?: 'UserType' }
        & Pick<UserType, 'firstName' | 'lastName' | 'email' | 'pk'>
      ), supervisors: Array<(
        { __typename?: 'UserType' }
        & Pick<UserType, 'firstName' | 'lastName' | 'email' | 'pk'>
      )>, tasks?: Maybe<Array<Maybe<(
        { __typename?: 'TaskType' }
        & Pick<TaskType, 'description' | 'acceptanceCriteria' | 'help' | 'pk'>
      )>>> }
    )> }
  )> }
);

export type SendTrialdayNotificationMutationVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
}>;


export type SendTrialdayNotificationMutation = (
  { __typename?: 'RootMutation' }
  & { sendTrialDayNotification?: Maybe<(
    { __typename?: 'TrialDaySendNotificationMutation' }
    & Pick<TrialDaySendNotificationMutation, 'response'>
  )> }
);

export type AllTrialdaysQueryVariables = Exact<{ [key: string]: never; }>;


export type AllTrialdaysQuery = (
  { __typename?: 'RootQuery' }
  & { getAllTrialDays?: Maybe<Array<Maybe<(
    { __typename?: 'TrialDayType' }
    & Pick<TrialDayType, 'date' | 'additionalInformation' | 'pk'>
    & { jobPackage: (
      { __typename?: 'JobPackageType' }
      & Pick<JobPackageType, 'name' | 'pk'>
    ), applicant: (
      { __typename?: 'UserType' }
      & Pick<UserType, 'firstName' | 'lastName' | 'pk' | 'email'>
    ), supervisors: Array<(
      { __typename?: 'UserType' }
      & Pick<UserType, 'firstName' | 'lastName' | 'pk' | 'email'>
    )>, tasks?: Maybe<Array<Maybe<(
      { __typename?: 'TaskType' }
      & Pick<TaskType, 'description' | 'acceptanceCriteria' | 'help' | 'pk'>
    )>>> }
  )>>> }
);

export type TrialDayQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type TrialDayQuery = (
  { __typename?: 'RootQuery' }
  & { getTrialDay?: Maybe<(
    { __typename?: 'TrialDayType' }
    & Pick<TrialDayType, 'pk' | 'date' | 'additionalInformation' | 'additionalInformationRendered'>
    & { jobPackage: (
      { __typename?: 'JobPackageType' }
      & Pick<JobPackageType, 'pk' | 'name' | 'preTrialDayDescription' | 'preTrialDayDescriptionRendered' | 'trialDayDescription' | 'trialDayDescriptionRendered'>
      & { taskList: Array<(
        { __typename?: 'TaskListType' }
        & Pick<TaskListType, 'pk' | 'name'>
        & { tasks: Array<(
          { __typename?: 'TaskType' }
          & Pick<TaskType, 'pk' | 'description' | 'descriptionRendered' | 'acceptanceCriteria' | 'acceptanceCriteriaRendered' | 'help' | 'helpRendered'>
        )> }
      )>, checklistItems: Array<(
        { __typename?: 'ChecklistItemType' }
        & Pick<ChecklistItemType, 'description' | 'descriptionRendered' | 'pk'>
      )> }
    ), applicant: (
      { __typename?: 'UserType' }
      & Pick<UserType, 'isSuperuser' | 'firstName' | 'lastName' | 'isStaff' | 'isActive' | 'email' | 'role' | 'pk'>
    ), supervisors: Array<(
      { __typename?: 'UserType' }
      & Pick<UserType, 'isSuperuser' | 'firstName' | 'lastName' | 'isStaff' | 'isActive' | 'email' | 'role' | 'pk'>
    )>, taskList: (
      { __typename?: 'TaskListType' }
      & Pick<TaskListType, 'pk' | 'name'>
    ), tasks?: Maybe<Array<Maybe<(
      { __typename?: 'TaskType' }
      & Pick<TaskType, 'pk' | 'description' | 'descriptionRendered' | 'acceptanceCriteria' | 'acceptanceCriteriaRendered' | 'help' | 'helpRendered'>
    )>>> }
  )> }
);


export const ApplicantTrialDaysDocument = gql`
    query ApplicantTrialDays($id: ID!) {
  getApplicantTrialDays(applicant_id: $id) {
    pk
    date
    jobPackage {
      name
      preTrialDayDescription
      preTrialDayDescriptionRendered
      trialDayDescription
      trialDayDescriptionRendered
      taskList {
        pk
        name
        tasks {
          description
          acceptanceCriteria
          help
          pk
        }
      }
      checklistItems {
        description
        descriptionRendered
        pk
      }
      pk
    }
    applicant {
      isSuperuser
      firstName
      lastName
      isStaff
      isActive
      email
      role
      pk
    }
    supervisors {
      isSuperuser
      firstName
      lastName
      isStaff
      isActive
      email
      role
      pk
    }
    additionalInformation
    additionalInformationRendered
    tasks {
      pk
      description
      descriptionRendered
      acceptanceCriteria
      acceptanceCriteriaRendered
      help
      helpRendered
    }
  }
}
    `;
export type ApplicantTrialDaysProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ApplicantTrialDaysQuery, ApplicantTrialDaysQueryVariables>
    } & TChildProps;
export function withApplicantTrialDays<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ApplicantTrialDaysQuery,
  ApplicantTrialDaysQueryVariables,
  ApplicantTrialDaysProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ApplicantTrialDaysQuery, ApplicantTrialDaysQueryVariables, ApplicantTrialDaysProps<TChildProps, TDataName>>(ApplicantTrialDaysDocument, {
      alias: 'applicantTrialDays',
      ...operationOptions
    });
};

/**
 * __useApplicantTrialDaysQuery__
 *
 * To run a query within a React component, call `useApplicantTrialDaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useApplicantTrialDaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useApplicantTrialDaysQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useApplicantTrialDaysQuery(baseOptions: Apollo.QueryHookOptions<ApplicantTrialDaysQuery, ApplicantTrialDaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ApplicantTrialDaysQuery, ApplicantTrialDaysQueryVariables>(ApplicantTrialDaysDocument, options);
      }
export function useApplicantTrialDaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ApplicantTrialDaysQuery, ApplicantTrialDaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ApplicantTrialDaysQuery, ApplicantTrialDaysQueryVariables>(ApplicantTrialDaysDocument, options);
        }
export type ApplicantTrialDaysQueryHookResult = ReturnType<typeof useApplicantTrialDaysQuery>;
export type ApplicantTrialDaysLazyQueryHookResult = ReturnType<typeof useApplicantTrialDaysLazyQuery>;
export type ApplicantTrialDaysQueryResult = Apollo.QueryResult<ApplicantTrialDaysQuery, ApplicantTrialDaysQueryVariables>;
export const UserInfoDocument = gql`
    query UserInfo {
  whoami {
    isSuperuser
    firstName
    lastName
    isStaff
    isActive
    email
    role
    pk
  }
}
    `;
export type UserInfoProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<UserInfoQuery, UserInfoQueryVariables>
    } & TChildProps;
export function withUserInfo<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UserInfoQuery,
  UserInfoQueryVariables,
  UserInfoProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, UserInfoQuery, UserInfoQueryVariables, UserInfoProps<TChildProps, TDataName>>(UserInfoDocument, {
      alias: 'userInfo',
      ...operationOptions
    });
};

/**
 * __useUserInfoQuery__
 *
 * To run a query within a React component, call `useUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useUserInfoQuery(baseOptions?: Apollo.QueryHookOptions<UserInfoQuery, UserInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserInfoQuery, UserInfoQueryVariables>(UserInfoDocument, options);
      }
export function useUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserInfoQuery, UserInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserInfoQuery, UserInfoQueryVariables>(UserInfoDocument, options);
        }
export type UserInfoQueryHookResult = ReturnType<typeof useUserInfoQuery>;
export type UserInfoLazyQueryHookResult = ReturnType<typeof useUserInfoLazyQuery>;
export type UserInfoQueryResult = Apollo.QueryResult<UserInfoQuery, UserInfoQueryVariables>;
export const TokenDocument = gql`
    query token($email: String!, $password: String!) {
  tokenAuth(email: $email, password: $password) {
    payload
    refreshExpiresIn
    token
  }
}
    `;
export type TokenProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<TokenQuery, TokenQueryVariables>
    } & TChildProps;
export function withToken<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TokenQuery,
  TokenQueryVariables,
  TokenProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, TokenQuery, TokenQueryVariables, TokenProps<TChildProps, TDataName>>(TokenDocument, {
      alias: 'token',
      ...operationOptions
    });
};

/**
 * __useTokenQuery__
 *
 * To run a query within a React component, call `useTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTokenQuery({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useTokenQuery(baseOptions: Apollo.QueryHookOptions<TokenQuery, TokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TokenQuery, TokenQueryVariables>(TokenDocument, options);
      }
export function useTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TokenQuery, TokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TokenQuery, TokenQueryVariables>(TokenDocument, options);
        }
export type TokenQueryHookResult = ReturnType<typeof useTokenQuery>;
export type TokenLazyQueryHookResult = ReturnType<typeof useTokenLazyQuery>;
export type TokenQueryResult = Apollo.QueryResult<TokenQuery, TokenQueryVariables>;
export const InvitationDocument = gql`
    query Invitation($slug: String!) {
  invitation(slug: $slug) {
    user {
      isSuperuser
      firstName
      lastName
      isStaff
      isActive
      email
      role
      pk
    }
  }
}
    `;
export type InvitationProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<InvitationQuery, InvitationQueryVariables>
    } & TChildProps;
export function withInvitation<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  InvitationQuery,
  InvitationQueryVariables,
  InvitationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, InvitationQuery, InvitationQueryVariables, InvitationProps<TChildProps, TDataName>>(InvitationDocument, {
      alias: 'invitation',
      ...operationOptions
    });
};

/**
 * __useInvitationQuery__
 *
 * To run a query within a React component, call `useInvitationQuery` and pass it any options that fit your needs.
 * When your component renders, `useInvitationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useInvitationQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useInvitationQuery(baseOptions: Apollo.QueryHookOptions<InvitationQuery, InvitationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<InvitationQuery, InvitationQueryVariables>(InvitationDocument, options);
      }
export function useInvitationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<InvitationQuery, InvitationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<InvitationQuery, InvitationQueryVariables>(InvitationDocument, options);
        }
export type InvitationQueryHookResult = ReturnType<typeof useInvitationQuery>;
export type InvitationLazyQueryHookResult = ReturnType<typeof useInvitationLazyQuery>;
export type InvitationQueryResult = Apollo.QueryResult<InvitationQuery, InvitationQueryVariables>;
export const UseInvitationDocument = gql`
    mutation UseInvitation($password: String!, $slug: String!) {
  useInvitation(password: $password, slug: $slug) {
    user {
      isSuperuser
      firstName
      lastName
      isStaff
      isActive
      email
      role
      pk
    }
  }
}
    `;
export type UseInvitationMutationFn = Apollo.MutationFunction<UseInvitationMutation, UseInvitationMutationVariables>;
export type UseInvitationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<UseInvitationMutation, UseInvitationMutationVariables>
    } & TChildProps;
export function withUseInvitation<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UseInvitationMutation,
  UseInvitationMutationVariables,
  UseInvitationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UseInvitationMutation, UseInvitationMutationVariables, UseInvitationProps<TChildProps, TDataName>>(UseInvitationDocument, {
      alias: 'useInvitation',
      ...operationOptions
    });
};

/**
 * __useUseInvitationMutation__
 *
 * To run a mutation, you first call `useUseInvitationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUseInvitationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [useInvitationMutation, { data, loading, error }] = useUseInvitationMutation({
 *   variables: {
 *      password: // value for 'password'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useUseInvitationMutation(baseOptions?: Apollo.MutationHookOptions<UseInvitationMutation, UseInvitationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UseInvitationMutation, UseInvitationMutationVariables>(UseInvitationDocument, options);
      }
export type UseInvitationMutationHookResult = ReturnType<typeof useUseInvitationMutation>;
export type UseInvitationMutationResult = Apollo.MutationResult<UseInvitationMutation>;
export type UseInvitationMutationOptions = Apollo.BaseMutationOptions<UseInvitationMutation, UseInvitationMutationVariables>;
export const RequestResetDocument = gql`
    mutation RequestReset($email: String!) {
  requestReset(email: $email) {
    response
  }
}
    `;
export type RequestResetMutationFn = Apollo.MutationFunction<RequestResetMutation, RequestResetMutationVariables>;
export type RequestResetProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<RequestResetMutation, RequestResetMutationVariables>
    } & TChildProps;
export function withRequestReset<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RequestResetMutation,
  RequestResetMutationVariables,
  RequestResetProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, RequestResetMutation, RequestResetMutationVariables, RequestResetProps<TChildProps, TDataName>>(RequestResetDocument, {
      alias: 'requestReset',
      ...operationOptions
    });
};

/**
 * __useRequestResetMutation__
 *
 * To run a mutation, you first call `useRequestResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestResetMutation, { data, loading, error }] = useRequestResetMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRequestResetMutation(baseOptions?: Apollo.MutationHookOptions<RequestResetMutation, RequestResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestResetMutation, RequestResetMutationVariables>(RequestResetDocument, options);
      }
export type RequestResetMutationHookResult = ReturnType<typeof useRequestResetMutation>;
export type RequestResetMutationResult = Apollo.MutationResult<RequestResetMutation>;
export type RequestResetMutationOptions = Apollo.BaseMutationOptions<RequestResetMutation, RequestResetMutationVariables>;
export const ResetDocument = gql`
    query Reset($slug: String!) {
  reset(slug: $slug) {
    user {
      isSuperuser
      firstName
      lastName
      isStaff
      isActive
      email
      role
      pk
    }
  }
}
    `;
export type ResetProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ResetQuery, ResetQueryVariables>
    } & TChildProps;
export function withReset<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ResetQuery,
  ResetQueryVariables,
  ResetProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ResetQuery, ResetQueryVariables, ResetProps<TChildProps, TDataName>>(ResetDocument, {
      alias: 'reset',
      ...operationOptions
    });
};

/**
 * __useResetQuery__
 *
 * To run a query within a React component, call `useResetQuery` and pass it any options that fit your needs.
 * When your component renders, `useResetQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useResetQuery({
 *   variables: {
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useResetQuery(baseOptions: Apollo.QueryHookOptions<ResetQuery, ResetQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ResetQuery, ResetQueryVariables>(ResetDocument, options);
      }
export function useResetLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ResetQuery, ResetQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ResetQuery, ResetQueryVariables>(ResetDocument, options);
        }
export type ResetQueryHookResult = ReturnType<typeof useResetQuery>;
export type ResetLazyQueryHookResult = ReturnType<typeof useResetLazyQuery>;
export type ResetQueryResult = Apollo.QueryResult<ResetQuery, ResetQueryVariables>;
export const UseResetDocument = gql`
    mutation UseReset($password: String!, $slug: String!) {
  useReset(password: $password, slug: $slug) {
    user {
      isSuperuser
      firstName
      lastName
      isStaff
      isActive
      email
      role
      pk
    }
  }
}
    `;
export type UseResetMutationFn = Apollo.MutationFunction<UseResetMutation, UseResetMutationVariables>;
export type UseResetProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<UseResetMutation, UseResetMutationVariables>
    } & TChildProps;
export function withUseReset<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UseResetMutation,
  UseResetMutationVariables,
  UseResetProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UseResetMutation, UseResetMutationVariables, UseResetProps<TChildProps, TDataName>>(UseResetDocument, {
      alias: 'useReset',
      ...operationOptions
    });
};

/**
 * __useUseResetMutation__
 *
 * To run a mutation, you first call `useUseResetMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUseResetMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [useResetMutation, { data, loading, error }] = useUseResetMutation({
 *   variables: {
 *      password: // value for 'password'
 *      slug: // value for 'slug'
 *   },
 * });
 */
export function useUseResetMutation(baseOptions?: Apollo.MutationHookOptions<UseResetMutation, UseResetMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UseResetMutation, UseResetMutationVariables>(UseResetDocument, options);
      }
export type UseResetMutationHookResult = ReturnType<typeof useUseResetMutation>;
export type UseResetMutationResult = Apollo.MutationResult<UseResetMutation>;
export type UseResetMutationOptions = Apollo.BaseMutationOptions<UseResetMutation, UseResetMutationVariables>;
export const SupervisorDatesDocument = gql`
    query SupervisorDates($supervisor_id: ID!) {
  getSupervisorDates(supervisor_id: $supervisor_id) {
    date
    jobPackage {
      name
      preTrialDayDescription
      preTrialDayDescriptionRendered
      trialDayDescription
      trialDayDescriptionRendered
      taskList {
        pk
        name
        tasks {
          description
          acceptanceCriteria
          help
          pk
        }
      }
      checklistItems {
        description
        descriptionRendered
        pk
      }
      pk
    }
    applicant {
      isSuperuser
      firstName
      lastName
      isStaff
      isActive
      email
      role
      pk
    }
    supervisors {
      isSuperuser
      firstName
      lastName
      isStaff
      isActive
      email
      role
      pk
    }
    additionalInformation
    additionalInformationRendered
    pk
  }
}
    `;
export type SupervisorDatesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<SupervisorDatesQuery, SupervisorDatesQueryVariables>
    } & TChildProps;
export function withSupervisorDates<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SupervisorDatesQuery,
  SupervisorDatesQueryVariables,
  SupervisorDatesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, SupervisorDatesQuery, SupervisorDatesQueryVariables, SupervisorDatesProps<TChildProps, TDataName>>(SupervisorDatesDocument, {
      alias: 'supervisorDates',
      ...operationOptions
    });
};

/**
 * __useSupervisorDatesQuery__
 *
 * To run a query within a React component, call `useSupervisorDatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSupervisorDatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSupervisorDatesQuery({
 *   variables: {
 *      supervisor_id: // value for 'supervisor_id'
 *   },
 * });
 */
export function useSupervisorDatesQuery(baseOptions: Apollo.QueryHookOptions<SupervisorDatesQuery, SupervisorDatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SupervisorDatesQuery, SupervisorDatesQueryVariables>(SupervisorDatesDocument, options);
      }
export function useSupervisorDatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SupervisorDatesQuery, SupervisorDatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SupervisorDatesQuery, SupervisorDatesQueryVariables>(SupervisorDatesDocument, options);
        }
export type SupervisorDatesQueryHookResult = ReturnType<typeof useSupervisorDatesQuery>;
export type SupervisorDatesLazyQueryHookResult = ReturnType<typeof useSupervisorDatesLazyQuery>;
export type SupervisorDatesQueryResult = Apollo.QueryResult<SupervisorDatesQuery, SupervisorDatesQueryVariables>;
export const CreateUserDocument = gql`
    mutation CreateUser($input: CreateUserInput!) {
  createUser(input: $input) {
    user {
      pk
      isSuperuser
      firstName
      lastName
      isStaff
      isActive
      email
      role
      applicantData {
        heavenhrUrl
        comment
      }
      employeeData {
        title
        contactInfo
      }
    }
  }
}
    `;
export type CreateUserMutationFn = Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>;
export type CreateUserProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<CreateUserMutation, CreateUserMutationVariables>
    } & TChildProps;
export function withCreateUser<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateUserMutation,
  CreateUserMutationVariables,
  CreateUserProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateUserMutation, CreateUserMutationVariables, CreateUserProps<TChildProps, TDataName>>(CreateUserDocument, {
      alias: 'createUser',
      ...operationOptions
    });
};

/**
 * __useCreateUserMutation__
 *
 * To run a mutation, you first call `useCreateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutation, { data, loading, error }] = useCreateUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutation(baseOptions?: Apollo.MutationHookOptions<CreateUserMutation, CreateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument, options);
      }
export type CreateUserMutationHookResult = ReturnType<typeof useCreateUserMutation>;
export type CreateUserMutationResult = Apollo.MutationResult<CreateUserMutation>;
export type CreateUserMutationOptions = Apollo.BaseMutationOptions<CreateUserMutation, CreateUserMutationVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($pk: ID!, $input: UpdateUserInput!) {
  updateUser(id: $pk, input: $input) {
    user {
      pk
      isSuperuser
      firstName
      lastName
      isStaff
      isActive
      email
      role
      applicantData {
        heavenhrUrl
        comment
      }
      employeeData {
        title
        contactInfo
      }
    }
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;
export type UpdateUserProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>
    } & TChildProps;
export function withUpdateUser<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateUserMutation,
  UpdateUserMutationVariables,
  UpdateUserProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateUserMutation, UpdateUserMutationVariables, UpdateUserProps<TChildProps, TDataName>>(UpdateUserDocument, {
      alias: 'updateUser',
      ...operationOptions
    });
};

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      pk: // value for 'pk'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const InviteUserDocument = gql`
    mutation InviteUser($email: String!) {
  inviteUser(email: $email) {
    response
  }
}
    `;
export type InviteUserMutationFn = Apollo.MutationFunction<InviteUserMutation, InviteUserMutationVariables>;
export type InviteUserProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<InviteUserMutation, InviteUserMutationVariables>
    } & TChildProps;
export function withInviteUser<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  InviteUserMutation,
  InviteUserMutationVariables,
  InviteUserProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, InviteUserMutation, InviteUserMutationVariables, InviteUserProps<TChildProps, TDataName>>(InviteUserDocument, {
      alias: 'inviteUser',
      ...operationOptions
    });
};

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useInviteUserMutation(baseOptions?: Apollo.MutationHookOptions<InviteUserMutation, InviteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<InviteUserMutation, InviteUserMutationVariables>(InviteUserDocument, options);
      }
export type InviteUserMutationHookResult = ReturnType<typeof useInviteUserMutation>;
export type InviteUserMutationResult = Apollo.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<InviteUserMutation, InviteUserMutationVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($pk: ID!) {
  deleteUser(id: $pk) {
    found
    deletedInputId
    deletedId
    deletedRawId
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;
export type DeleteUserProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>
    } & TChildProps;
export function withDeleteUser<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteUserMutation,
  DeleteUserMutationVariables,
  DeleteUserProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteUserMutation, DeleteUserMutationVariables, DeleteUserProps<TChildProps, TDataName>>(DeleteUserDocument, {
      alias: 'deleteUser',
      ...operationOptions
    });
};

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      pk: // value for 'pk'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const AnonymizeUserDocument = gql`
    mutation anonymizeUser($email: String!) {
  anonymizeUser(email: $email) {
    response
  }
}
    `;
export type AnonymizeUserMutationFn = Apollo.MutationFunction<AnonymizeUserMutation, AnonymizeUserMutationVariables>;
export type AnonymizeUserProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<AnonymizeUserMutation, AnonymizeUserMutationVariables>
    } & TChildProps;
export function withAnonymizeUser<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AnonymizeUserMutation,
  AnonymizeUserMutationVariables,
  AnonymizeUserProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, AnonymizeUserMutation, AnonymizeUserMutationVariables, AnonymizeUserProps<TChildProps, TDataName>>(AnonymizeUserDocument, {
      alias: 'anonymizeUser',
      ...operationOptions
    });
};

/**
 * __useAnonymizeUserMutation__
 *
 * To run a mutation, you first call `useAnonymizeUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAnonymizeUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [anonymizeUserMutation, { data, loading, error }] = useAnonymizeUserMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAnonymizeUserMutation(baseOptions?: Apollo.MutationHookOptions<AnonymizeUserMutation, AnonymizeUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AnonymizeUserMutation, AnonymizeUserMutationVariables>(AnonymizeUserDocument, options);
      }
export type AnonymizeUserMutationHookResult = ReturnType<typeof useAnonymizeUserMutation>;
export type AnonymizeUserMutationResult = Apollo.MutationResult<AnonymizeUserMutation>;
export type AnonymizeUserMutationOptions = Apollo.BaseMutationOptions<AnonymizeUserMutation, AnonymizeUserMutationVariables>;
export const DeleteTaskListDocument = gql`
    mutation DeleteTaskList($id: ID!) {
  deleteTasklist(id: $id) {
    found
    deletedInputId
    deletedId
    deletedRawId
  }
}
    `;
export type DeleteTaskListMutationFn = Apollo.MutationFunction<DeleteTaskListMutation, DeleteTaskListMutationVariables>;
export type DeleteTaskListProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<DeleteTaskListMutation, DeleteTaskListMutationVariables>
    } & TChildProps;
export function withDeleteTaskList<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteTaskListMutation,
  DeleteTaskListMutationVariables,
  DeleteTaskListProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteTaskListMutation, DeleteTaskListMutationVariables, DeleteTaskListProps<TChildProps, TDataName>>(DeleteTaskListDocument, {
      alias: 'deleteTaskList',
      ...operationOptions
    });
};

/**
 * __useDeleteTaskListMutation__
 *
 * To run a mutation, you first call `useDeleteTaskListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTaskListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTaskListMutation, { data, loading, error }] = useDeleteTaskListMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTaskListMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTaskListMutation, DeleteTaskListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTaskListMutation, DeleteTaskListMutationVariables>(DeleteTaskListDocument, options);
      }
export type DeleteTaskListMutationHookResult = ReturnType<typeof useDeleteTaskListMutation>;
export type DeleteTaskListMutationResult = Apollo.MutationResult<DeleteTaskListMutation>;
export type DeleteTaskListMutationOptions = Apollo.BaseMutationOptions<DeleteTaskListMutation, DeleteTaskListMutationVariables>;
export const UpdateTaskListDocument = gql`
    mutation UpdateTaskList($id: ID!, $input: UpdateTaskListInput!) {
  updateTasklist(id: $id, input: $input) {
    taskList {
      pk
      name
      tasks {
        pk
        description
        acceptanceCriteria
        help
      }
    }
  }
}
    `;
export type UpdateTaskListMutationFn = Apollo.MutationFunction<UpdateTaskListMutation, UpdateTaskListMutationVariables>;
export type UpdateTaskListProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<UpdateTaskListMutation, UpdateTaskListMutationVariables>
    } & TChildProps;
export function withUpdateTaskList<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateTaskListMutation,
  UpdateTaskListMutationVariables,
  UpdateTaskListProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateTaskListMutation, UpdateTaskListMutationVariables, UpdateTaskListProps<TChildProps, TDataName>>(UpdateTaskListDocument, {
      alias: 'updateTaskList',
      ...operationOptions
    });
};

/**
 * __useUpdateTaskListMutation__
 *
 * To run a mutation, you first call `useUpdateTaskListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTaskListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTaskListMutation, { data, loading, error }] = useUpdateTaskListMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTaskListMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTaskListMutation, UpdateTaskListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTaskListMutation, UpdateTaskListMutationVariables>(UpdateTaskListDocument, options);
      }
export type UpdateTaskListMutationHookResult = ReturnType<typeof useUpdateTaskListMutation>;
export type UpdateTaskListMutationResult = Apollo.MutationResult<UpdateTaskListMutation>;
export type UpdateTaskListMutationOptions = Apollo.BaseMutationOptions<UpdateTaskListMutation, UpdateTaskListMutationVariables>;
export const CreateJobPackageDocument = gql`
    mutation CreateJobPackage($input: CreateJobPackageInput!) {
  createJobPackage(input: $input) {
    jobPackage {
      pk
      name
      preTrialDayDescription
      preTrialDayDescriptionRendered
      trialDayDescription
      trialDayDescriptionRendered
      taskList {
        name
        tasks {
          description
          descriptionRendered
          acceptanceCriteria
          acceptanceCriteriaRendered
          help
          helpRendered
          pk
        }
        pk
      }
      checklistItems {
        description
        descriptionRendered
        pk
      }
    }
  }
}
    `;
export type CreateJobPackageMutationFn = Apollo.MutationFunction<CreateJobPackageMutation, CreateJobPackageMutationVariables>;
export type CreateJobPackageProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<CreateJobPackageMutation, CreateJobPackageMutationVariables>
    } & TChildProps;
export function withCreateJobPackage<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateJobPackageMutation,
  CreateJobPackageMutationVariables,
  CreateJobPackageProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateJobPackageMutation, CreateJobPackageMutationVariables, CreateJobPackageProps<TChildProps, TDataName>>(CreateJobPackageDocument, {
      alias: 'createJobPackage',
      ...operationOptions
    });
};

/**
 * __useCreateJobPackageMutation__
 *
 * To run a mutation, you first call `useCreateJobPackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateJobPackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createJobPackageMutation, { data, loading, error }] = useCreateJobPackageMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateJobPackageMutation(baseOptions?: Apollo.MutationHookOptions<CreateJobPackageMutation, CreateJobPackageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateJobPackageMutation, CreateJobPackageMutationVariables>(CreateJobPackageDocument, options);
      }
export type CreateJobPackageMutationHookResult = ReturnType<typeof useCreateJobPackageMutation>;
export type CreateJobPackageMutationResult = Apollo.MutationResult<CreateJobPackageMutation>;
export type CreateJobPackageMutationOptions = Apollo.BaseMutationOptions<CreateJobPackageMutation, CreateJobPackageMutationVariables>;
export const UpdateJobPackageDocument = gql`
    mutation UpdateJobPackage($id: ID!, $input: UpdateJobPackageInput!) {
  updateJobPackage(id: $id, input: $input) {
    jobPackage {
      pk
      name
      preTrialDayDescription
      preTrialDayDescriptionRendered
      trialDayDescription
      trialDayDescriptionRendered
      taskList {
        name
        tasks {
          description
          descriptionRendered
          acceptanceCriteria
          acceptanceCriteriaRendered
          help
          helpRendered
          pk
        }
        pk
      }
      checklistItems {
        description
        descriptionRendered
        pk
      }
    }
  }
}
    `;
export type UpdateJobPackageMutationFn = Apollo.MutationFunction<UpdateJobPackageMutation, UpdateJobPackageMutationVariables>;
export type UpdateJobPackageProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<UpdateJobPackageMutation, UpdateJobPackageMutationVariables>
    } & TChildProps;
export function withUpdateJobPackage<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateJobPackageMutation,
  UpdateJobPackageMutationVariables,
  UpdateJobPackageProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateJobPackageMutation, UpdateJobPackageMutationVariables, UpdateJobPackageProps<TChildProps, TDataName>>(UpdateJobPackageDocument, {
      alias: 'updateJobPackage',
      ...operationOptions
    });
};

/**
 * __useUpdateJobPackageMutation__
 *
 * To run a mutation, you first call `useUpdateJobPackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateJobPackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateJobPackageMutation, { data, loading, error }] = useUpdateJobPackageMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateJobPackageMutation(baseOptions?: Apollo.MutationHookOptions<UpdateJobPackageMutation, UpdateJobPackageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateJobPackageMutation, UpdateJobPackageMutationVariables>(UpdateJobPackageDocument, options);
      }
export type UpdateJobPackageMutationHookResult = ReturnType<typeof useUpdateJobPackageMutation>;
export type UpdateJobPackageMutationResult = Apollo.MutationResult<UpdateJobPackageMutation>;
export type UpdateJobPackageMutationOptions = Apollo.BaseMutationOptions<UpdateJobPackageMutation, UpdateJobPackageMutationVariables>;
export const CreateTaskListDocument = gql`
    mutation CreateTaskList($input: CreateTaskListInput!) {
  createTasklist(input: $input) {
    taskList {
      pk
      name
      tasks {
        pk
        description
        acceptanceCriteria
        help
      }
    }
  }
}
    `;
export type CreateTaskListMutationFn = Apollo.MutationFunction<CreateTaskListMutation, CreateTaskListMutationVariables>;
export type CreateTaskListProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<CreateTaskListMutation, CreateTaskListMutationVariables>
    } & TChildProps;
export function withCreateTaskList<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateTaskListMutation,
  CreateTaskListMutationVariables,
  CreateTaskListProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateTaskListMutation, CreateTaskListMutationVariables, CreateTaskListProps<TChildProps, TDataName>>(CreateTaskListDocument, {
      alias: 'createTaskList',
      ...operationOptions
    });
};

/**
 * __useCreateTaskListMutation__
 *
 * To run a mutation, you first call `useCreateTaskListMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTaskListMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTaskListMutation, { data, loading, error }] = useCreateTaskListMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTaskListMutation(baseOptions?: Apollo.MutationHookOptions<CreateTaskListMutation, CreateTaskListMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTaskListMutation, CreateTaskListMutationVariables>(CreateTaskListDocument, options);
      }
export type CreateTaskListMutationHookResult = ReturnType<typeof useCreateTaskListMutation>;
export type CreateTaskListMutationResult = Apollo.MutationResult<CreateTaskListMutation>;
export type CreateTaskListMutationOptions = Apollo.BaseMutationOptions<CreateTaskListMutation, CreateTaskListMutationVariables>;
export const DeleteJobPackageDocument = gql`
    mutation DeleteJobPackage($id: ID!) {
  deleteJobPackage(id: $id) {
    found
    deletedInputId
    deletedId
    deletedRawId
  }
}
    `;
export type DeleteJobPackageMutationFn = Apollo.MutationFunction<DeleteJobPackageMutation, DeleteJobPackageMutationVariables>;
export type DeleteJobPackageProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<DeleteJobPackageMutation, DeleteJobPackageMutationVariables>
    } & TChildProps;
export function withDeleteJobPackage<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteJobPackageMutation,
  DeleteJobPackageMutationVariables,
  DeleteJobPackageProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteJobPackageMutation, DeleteJobPackageMutationVariables, DeleteJobPackageProps<TChildProps, TDataName>>(DeleteJobPackageDocument, {
      alias: 'deleteJobPackage',
      ...operationOptions
    });
};

/**
 * __useDeleteJobPackageMutation__
 *
 * To run a mutation, you first call `useDeleteJobPackageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteJobPackageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteJobPackageMutation, { data, loading, error }] = useDeleteJobPackageMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteJobPackageMutation(baseOptions?: Apollo.MutationHookOptions<DeleteJobPackageMutation, DeleteJobPackageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteJobPackageMutation, DeleteJobPackageMutationVariables>(DeleteJobPackageDocument, options);
      }
export type DeleteJobPackageMutationHookResult = ReturnType<typeof useDeleteJobPackageMutation>;
export type DeleteJobPackageMutationResult = Apollo.MutationResult<DeleteJobPackageMutation>;
export type DeleteJobPackageMutationOptions = Apollo.BaseMutationOptions<DeleteJobPackageMutation, DeleteJobPackageMutationVariables>;
export const AllTrialDaysDocument = gql`
    query AllTrialDays {
  getAllTrialDays {
    date
    jobPackage {
      name
      preTrialDayDescription
      preTrialDayDescriptionRendered
      trialDayDescription
      trialDayDescriptionRendered
      taskList {
        pk
        name
        tasks {
          description
          acceptanceCriteria
          help
          pk
        }
      }
      checklistItems {
        description
        descriptionRendered
        position
        pk
      }
      pk
    }
    applicant {
      isSuperuser
      firstName
      lastName
      isStaff
      isActive
      email
      role
      pk
    }
    supervisors {
      isSuperuser
      firstName
      lastName
      isStaff
      isActive
      email
      role
      pk
    }
    additionalInformation
    additionalInformationRendered
    pk
  }
}
    `;
export type AllTrialDaysProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AllTrialDaysQuery, AllTrialDaysQueryVariables>
    } & TChildProps;
export function withAllTrialDays<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AllTrialDaysQuery,
  AllTrialDaysQueryVariables,
  AllTrialDaysProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AllTrialDaysQuery, AllTrialDaysQueryVariables, AllTrialDaysProps<TChildProps, TDataName>>(AllTrialDaysDocument, {
      alias: 'allTrialDays',
      ...operationOptions
    });
};

/**
 * __useAllTrialDaysQuery__
 *
 * To run a query within a React component, call `useAllTrialDaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTrialDaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTrialDaysQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllTrialDaysQuery(baseOptions?: Apollo.QueryHookOptions<AllTrialDaysQuery, AllTrialDaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllTrialDaysQuery, AllTrialDaysQueryVariables>(AllTrialDaysDocument, options);
      }
export function useAllTrialDaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllTrialDaysQuery, AllTrialDaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllTrialDaysQuery, AllTrialDaysQueryVariables>(AllTrialDaysDocument, options);
        }
export type AllTrialDaysQueryHookResult = ReturnType<typeof useAllTrialDaysQuery>;
export type AllTrialDaysLazyQueryHookResult = ReturnType<typeof useAllTrialDaysLazyQuery>;
export type AllTrialDaysQueryResult = Apollo.QueryResult<AllTrialDaysQuery, AllTrialDaysQueryVariables>;
export const AllApplicantsDocument = gql`
    query AllApplicants {
  getAllApplicants {
    isSuperuser
    firstName
    lastName
    isStaff
    isActive
    email
    role
    pk
  }
}
    `;
export type AllApplicantsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AllApplicantsQuery, AllApplicantsQueryVariables>
    } & TChildProps;
export function withAllApplicants<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AllApplicantsQuery,
  AllApplicantsQueryVariables,
  AllApplicantsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AllApplicantsQuery, AllApplicantsQueryVariables, AllApplicantsProps<TChildProps, TDataName>>(AllApplicantsDocument, {
      alias: 'allApplicants',
      ...operationOptions
    });
};

/**
 * __useAllApplicantsQuery__
 *
 * To run a query within a React component, call `useAllApplicantsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllApplicantsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllApplicantsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllApplicantsQuery(baseOptions?: Apollo.QueryHookOptions<AllApplicantsQuery, AllApplicantsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllApplicantsQuery, AllApplicantsQueryVariables>(AllApplicantsDocument, options);
      }
export function useAllApplicantsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllApplicantsQuery, AllApplicantsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllApplicantsQuery, AllApplicantsQueryVariables>(AllApplicantsDocument, options);
        }
export type AllApplicantsQueryHookResult = ReturnType<typeof useAllApplicantsQuery>;
export type AllApplicantsLazyQueryHookResult = ReturnType<typeof useAllApplicantsLazyQuery>;
export type AllApplicantsQueryResult = Apollo.QueryResult<AllApplicantsQuery, AllApplicantsQueryVariables>;
export const UserDocument = gql`
    query User($id: ID!) {
  user(id: $id) {
    pk
    isSuperuser
    firstName
    lastName
    isStaff
    isActive
    email
    role
    applicantData {
      heavenhrUrl
      comment
    }
    employeeData {
      title
      contactInfo
    }
  }
}
    `;
export type UserProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<UserQuery, UserQueryVariables>
    } & TChildProps;
export function withUser<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UserQuery,
  UserQueryVariables,
  UserProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, UserQuery, UserQueryVariables, UserProps<TChildProps, TDataName>>(UserDocument, {
      alias: 'user',
      ...operationOptions
    });
};

/**
 * __useUserQuery__
 *
 * To run a query within a React component, call `useUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useUserQuery(baseOptions: Apollo.QueryHookOptions<UserQuery, UserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserQuery, UserQueryVariables>(UserDocument, options);
      }
export function useUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserQuery, UserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserQuery, UserQueryVariables>(UserDocument, options);
        }
export type UserQueryHookResult = ReturnType<typeof useUserQuery>;
export type UserLazyQueryHookResult = ReturnType<typeof useUserLazyQuery>;
export type UserQueryResult = Apollo.QueryResult<UserQuery, UserQueryVariables>;
export const AllUsersDocument = gql`
    query AllUsers {
  users {
    pk
    isSuperuser
    firstName
    lastName
    isStaff
    isActive
    email
    role
    applicantData {
      heavenhrUrl
      comment
    }
    employeeData {
      title
      contactInfo
    }
  }
}
    `;
export type AllUsersProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AllUsersQuery, AllUsersQueryVariables>
    } & TChildProps;
export function withAllUsers<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AllUsersQuery,
  AllUsersQueryVariables,
  AllUsersProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AllUsersQuery, AllUsersQueryVariables, AllUsersProps<TChildProps, TDataName>>(AllUsersDocument, {
      alias: 'allUsers',
      ...operationOptions
    });
};

/**
 * __useAllUsersQuery__
 *
 * To run a query within a React component, call `useAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllUsersQuery(baseOptions?: Apollo.QueryHookOptions<AllUsersQuery, AllUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllUsersQuery, AllUsersQueryVariables>(AllUsersDocument, options);
      }
export function useAllUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllUsersQuery, AllUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllUsersQuery, AllUsersQueryVariables>(AllUsersDocument, options);
        }
export type AllUsersQueryHookResult = ReturnType<typeof useAllUsersQuery>;
export type AllUsersLazyQueryHookResult = ReturnType<typeof useAllUsersLazyQuery>;
export type AllUsersQueryResult = Apollo.QueryResult<AllUsersQuery, AllUsersQueryVariables>;
export const TaskListDocument = gql`
    query TaskList($id: ID!) {
  getTaskList(id: $id) {
    name
    pk
    tasks {
      pk
      description
      descriptionRendered
      acceptanceCriteria
      acceptanceCriteriaRendered
      help
      helpRendered
    }
  }
}
    `;
export type TaskListProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<TaskListQuery, TaskListQueryVariables>
    } & TChildProps;
export function withTaskList<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TaskListQuery,
  TaskListQueryVariables,
  TaskListProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, TaskListQuery, TaskListQueryVariables, TaskListProps<TChildProps, TDataName>>(TaskListDocument, {
      alias: 'taskList',
      ...operationOptions
    });
};

/**
 * __useTaskListQuery__
 *
 * To run a query within a React component, call `useTaskListQuery` and pass it any options that fit your needs.
 * When your component renders, `useTaskListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTaskListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTaskListQuery(baseOptions: Apollo.QueryHookOptions<TaskListQuery, TaskListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TaskListQuery, TaskListQueryVariables>(TaskListDocument, options);
      }
export function useTaskListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TaskListQuery, TaskListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TaskListQuery, TaskListQueryVariables>(TaskListDocument, options);
        }
export type TaskListQueryHookResult = ReturnType<typeof useTaskListQuery>;
export type TaskListLazyQueryHookResult = ReturnType<typeof useTaskListLazyQuery>;
export type TaskListQueryResult = Apollo.QueryResult<TaskListQuery, TaskListQueryVariables>;
export const AllJobPackagesDocument = gql`
    query AllJobPackages {
  getAllJobPackages {
    name
    preTrialDayDescription
    preTrialDayDescriptionRendered
    trialDayDescription
    trialDayDescriptionRendered
    taskList {
      pk
      name
      tasks {
        description
        acceptanceCriteria
        help
        pk
      }
    }
    checklistItems {
      description
      descriptionRendered
      pk
    }
    pk
  }
}
    `;
export type AllJobPackagesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AllJobPackagesQuery, AllJobPackagesQueryVariables>
    } & TChildProps;
export function withAllJobPackages<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AllJobPackagesQuery,
  AllJobPackagesQueryVariables,
  AllJobPackagesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AllJobPackagesQuery, AllJobPackagesQueryVariables, AllJobPackagesProps<TChildProps, TDataName>>(AllJobPackagesDocument, {
      alias: 'allJobPackages',
      ...operationOptions
    });
};

/**
 * __useAllJobPackagesQuery__
 *
 * To run a query within a React component, call `useAllJobPackagesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllJobPackagesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllJobPackagesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllJobPackagesQuery(baseOptions?: Apollo.QueryHookOptions<AllJobPackagesQuery, AllJobPackagesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllJobPackagesQuery, AllJobPackagesQueryVariables>(AllJobPackagesDocument, options);
      }
export function useAllJobPackagesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllJobPackagesQuery, AllJobPackagesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllJobPackagesQuery, AllJobPackagesQueryVariables>(AllJobPackagesDocument, options);
        }
export type AllJobPackagesQueryHookResult = ReturnType<typeof useAllJobPackagesQuery>;
export type AllJobPackagesLazyQueryHookResult = ReturnType<typeof useAllJobPackagesLazyQuery>;
export type AllJobPackagesQueryResult = Apollo.QueryResult<AllJobPackagesQuery, AllJobPackagesQueryVariables>;
export const JobPackageDocument = gql`
    query JobPackage($id: ID!) {
  getJobPackage(id: $id) {
    name
    preTrialDayDescription
    preTrialDayDescriptionRendered
    trialDayDescription
    trialDayDescriptionRendered
    taskList {
      name
      pk
      tasks {
        pk
        description
        descriptionRendered
        acceptanceCriteria
        acceptanceCriteriaRendered
        help
        helpRendered
      }
    }
    checklistItems {
      description
      descriptionRendered
      position
      pk
    }
    pk
  }
}
    `;
export type JobPackageProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<JobPackageQuery, JobPackageQueryVariables>
    } & TChildProps;
export function withJobPackage<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  JobPackageQuery,
  JobPackageQueryVariables,
  JobPackageProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, JobPackageQuery, JobPackageQueryVariables, JobPackageProps<TChildProps, TDataName>>(JobPackageDocument, {
      alias: 'jobPackage',
      ...operationOptions
    });
};

/**
 * __useJobPackageQuery__
 *
 * To run a query within a React component, call `useJobPackageQuery` and pass it any options that fit your needs.
 * When your component renders, `useJobPackageQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useJobPackageQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useJobPackageQuery(baseOptions: Apollo.QueryHookOptions<JobPackageQuery, JobPackageQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<JobPackageQuery, JobPackageQueryVariables>(JobPackageDocument, options);
      }
export function useJobPackageLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<JobPackageQuery, JobPackageQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<JobPackageQuery, JobPackageQueryVariables>(JobPackageDocument, options);
        }
export type JobPackageQueryHookResult = ReturnType<typeof useJobPackageQuery>;
export type JobPackageLazyQueryHookResult = ReturnType<typeof useJobPackageLazyQuery>;
export type JobPackageQueryResult = Apollo.QueryResult<JobPackageQuery, JobPackageQueryVariables>;
export const AllTaskListsDocument = gql`
    query AllTaskLists {
  getAllTaskLists {
    name
    tasks {
      pk
      description
      descriptionRendered
      acceptanceCriteria
      acceptanceCriteriaRendered
      help
      helpRendered
    }
    pk
  }
}
    `;
export type AllTaskListsProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AllTaskListsQuery, AllTaskListsQueryVariables>
    } & TChildProps;
export function withAllTaskLists<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AllTaskListsQuery,
  AllTaskListsQueryVariables,
  AllTaskListsProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AllTaskListsQuery, AllTaskListsQueryVariables, AllTaskListsProps<TChildProps, TDataName>>(AllTaskListsDocument, {
      alias: 'allTaskLists',
      ...operationOptions
    });
};

/**
 * __useAllTaskListsQuery__
 *
 * To run a query within a React component, call `useAllTaskListsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTaskListsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTaskListsQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllTaskListsQuery(baseOptions?: Apollo.QueryHookOptions<AllTaskListsQuery, AllTaskListsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllTaskListsQuery, AllTaskListsQueryVariables>(AllTaskListsDocument, options);
      }
export function useAllTaskListsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllTaskListsQuery, AllTaskListsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllTaskListsQuery, AllTaskListsQueryVariables>(AllTaskListsDocument, options);
        }
export type AllTaskListsQueryHookResult = ReturnType<typeof useAllTaskListsQuery>;
export type AllTaskListsLazyQueryHookResult = ReturnType<typeof useAllTaskListsLazyQuery>;
export type AllTaskListsQueryResult = Apollo.QueryResult<AllTaskListsQuery, AllTaskListsQueryVariables>;
export const DeleteSingleServiceDocument = gql`
    mutation DeleteSingleService($id: ID!) {
  deleteService(id: $id) {
    found
    deletedInputId
    deletedId
    deletedRawId
  }
}
    `;
export type DeleteSingleServiceMutationFn = Apollo.MutationFunction<DeleteSingleServiceMutation, DeleteSingleServiceMutationVariables>;
export type DeleteSingleServiceProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<DeleteSingleServiceMutation, DeleteSingleServiceMutationVariables>
    } & TChildProps;
export function withDeleteSingleService<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteSingleServiceMutation,
  DeleteSingleServiceMutationVariables,
  DeleteSingleServiceProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteSingleServiceMutation, DeleteSingleServiceMutationVariables, DeleteSingleServiceProps<TChildProps, TDataName>>(DeleteSingleServiceDocument, {
      alias: 'deleteSingleService',
      ...operationOptions
    });
};

/**
 * __useDeleteSingleServiceMutation__
 *
 * To run a mutation, you first call `useDeleteSingleServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSingleServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSingleServiceMutation, { data, loading, error }] = useDeleteSingleServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSingleServiceMutation(baseOptions?: Apollo.MutationHookOptions<DeleteSingleServiceMutation, DeleteSingleServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteSingleServiceMutation, DeleteSingleServiceMutationVariables>(DeleteSingleServiceDocument, options);
      }
export type DeleteSingleServiceMutationHookResult = ReturnType<typeof useDeleteSingleServiceMutation>;
export type DeleteSingleServiceMutationResult = Apollo.MutationResult<DeleteSingleServiceMutation>;
export type DeleteSingleServiceMutationOptions = Apollo.BaseMutationOptions<DeleteSingleServiceMutation, DeleteSingleServiceMutationVariables>;
export const UpdateSingleServiceDocument = gql`
    mutation UpdateSingleService($id: ID!, $input: UpdateServiceInput!) {
  updateService(id: $id, input: $input) {
    service {
      name
      url
      pk
    }
  }
}
    `;
export type UpdateSingleServiceMutationFn = Apollo.MutationFunction<UpdateSingleServiceMutation, UpdateSingleServiceMutationVariables>;
export type UpdateSingleServiceProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<UpdateSingleServiceMutation, UpdateSingleServiceMutationVariables>
    } & TChildProps;
export function withUpdateSingleService<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateSingleServiceMutation,
  UpdateSingleServiceMutationVariables,
  UpdateSingleServiceProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateSingleServiceMutation, UpdateSingleServiceMutationVariables, UpdateSingleServiceProps<TChildProps, TDataName>>(UpdateSingleServiceDocument, {
      alias: 'updateSingleService',
      ...operationOptions
    });
};

/**
 * __useUpdateSingleServiceMutation__
 *
 * To run a mutation, you first call `useUpdateSingleServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSingleServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSingleServiceMutation, { data, loading, error }] = useUpdateSingleServiceMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSingleServiceMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSingleServiceMutation, UpdateSingleServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSingleServiceMutation, UpdateSingleServiceMutationVariables>(UpdateSingleServiceDocument, options);
      }
export type UpdateSingleServiceMutationHookResult = ReturnType<typeof useUpdateSingleServiceMutation>;
export type UpdateSingleServiceMutationResult = Apollo.MutationResult<UpdateSingleServiceMutation>;
export type UpdateSingleServiceMutationOptions = Apollo.BaseMutationOptions<UpdateSingleServiceMutation, UpdateSingleServiceMutationVariables>;
export const CreateSingleServiceDocument = gql`
    mutation CreateSingleService($input: CreateServiceInput!) {
  createService(input: $input) {
    service {
      name
      url
      pk
    }
  }
}
    `;
export type CreateSingleServiceMutationFn = Apollo.MutationFunction<CreateSingleServiceMutation, CreateSingleServiceMutationVariables>;
export type CreateSingleServiceProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<CreateSingleServiceMutation, CreateSingleServiceMutationVariables>
    } & TChildProps;
export function withCreateSingleService<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateSingleServiceMutation,
  CreateSingleServiceMutationVariables,
  CreateSingleServiceProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateSingleServiceMutation, CreateSingleServiceMutationVariables, CreateSingleServiceProps<TChildProps, TDataName>>(CreateSingleServiceDocument, {
      alias: 'createSingleService',
      ...operationOptions
    });
};

/**
 * __useCreateSingleServiceMutation__
 *
 * To run a mutation, you first call `useCreateSingleServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSingleServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSingleServiceMutation, { data, loading, error }] = useCreateSingleServiceMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSingleServiceMutation(baseOptions?: Apollo.MutationHookOptions<CreateSingleServiceMutation, CreateSingleServiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSingleServiceMutation, CreateSingleServiceMutationVariables>(CreateSingleServiceDocument, options);
      }
export type CreateSingleServiceMutationHookResult = ReturnType<typeof useCreateSingleServiceMutation>;
export type CreateSingleServiceMutationResult = Apollo.MutationResult<CreateSingleServiceMutation>;
export type CreateSingleServiceMutationOptions = Apollo.BaseMutationOptions<CreateSingleServiceMutation, CreateSingleServiceMutationVariables>;
export const UserServicesDocument = gql`
    query UserServices($user_id: ID!) {
  userServices(user_id: $user_id) {
    isActive
    pk
    servicePk
    userPk
    name
    url
  }
}
    `;
export type UserServicesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<UserServicesQuery, UserServicesQueryVariables>
    } & TChildProps;
export function withUserServices<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UserServicesQuery,
  UserServicesQueryVariables,
  UserServicesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, UserServicesQuery, UserServicesQueryVariables, UserServicesProps<TChildProps, TDataName>>(UserServicesDocument, {
      alias: 'userServices',
      ...operationOptions
    });
};

/**
 * __useUserServicesQuery__
 *
 * To run a query within a React component, call `useUserServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserServicesQuery({
 *   variables: {
 *      user_id: // value for 'user_id'
 *   },
 * });
 */
export function useUserServicesQuery(baseOptions: Apollo.QueryHookOptions<UserServicesQuery, UserServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserServicesQuery, UserServicesQueryVariables>(UserServicesDocument, options);
      }
export function useUserServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserServicesQuery, UserServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserServicesQuery, UserServicesQueryVariables>(UserServicesDocument, options);
        }
export type UserServicesQueryHookResult = ReturnType<typeof useUserServicesQuery>;
export type UserServicesLazyQueryHookResult = ReturnType<typeof useUserServicesLazyQuery>;
export type UserServicesQueryResult = Apollo.QueryResult<UserServicesQuery, UserServicesQueryVariables>;
export const MyServicesDocument = gql`
    query MyServices {
  myServices {
    isActive
    pk
    servicePk
    userPk
    name
    url
    iconPath
  }
}
    `;
export type MyServicesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<MyServicesQuery, MyServicesQueryVariables>
    } & TChildProps;
export function withMyServices<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  MyServicesQuery,
  MyServicesQueryVariables,
  MyServicesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, MyServicesQuery, MyServicesQueryVariables, MyServicesProps<TChildProps, TDataName>>(MyServicesDocument, {
      alias: 'myServices',
      ...operationOptions
    });
};

/**
 * __useMyServicesQuery__
 *
 * To run a query within a React component, call `useMyServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyServicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyServicesQuery(baseOptions?: Apollo.QueryHookOptions<MyServicesQuery, MyServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyServicesQuery, MyServicesQueryVariables>(MyServicesDocument, options);
      }
export function useMyServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyServicesQuery, MyServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyServicesQuery, MyServicesQueryVariables>(MyServicesDocument, options);
        }
export type MyServicesQueryHookResult = ReturnType<typeof useMyServicesQuery>;
export type MyServicesLazyQueryHookResult = ReturnType<typeof useMyServicesLazyQuery>;
export type MyServicesQueryResult = Apollo.QueryResult<MyServicesQuery, MyServicesQueryVariables>;
export const AllServicesDocument = gql`
    query AllServices {
  allServices {
    name
    url
    pk
  }
}
    `;
export type AllServicesProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AllServicesQuery, AllServicesQueryVariables>
    } & TChildProps;
export function withAllServices<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AllServicesQuery,
  AllServicesQueryVariables,
  AllServicesProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AllServicesQuery, AllServicesQueryVariables, AllServicesProps<TChildProps, TDataName>>(AllServicesDocument, {
      alias: 'allServices',
      ...operationOptions
    });
};

/**
 * __useAllServicesQuery__
 *
 * To run a query within a React component, call `useAllServicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllServicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllServicesQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllServicesQuery(baseOptions?: Apollo.QueryHookOptions<AllServicesQuery, AllServicesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllServicesQuery, AllServicesQueryVariables>(AllServicesDocument, options);
      }
export function useAllServicesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllServicesQuery, AllServicesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllServicesQuery, AllServicesQueryVariables>(AllServicesDocument, options);
        }
export type AllServicesQueryHookResult = ReturnType<typeof useAllServicesQuery>;
export type AllServicesLazyQueryHookResult = ReturnType<typeof useAllServicesLazyQuery>;
export type AllServicesQueryResult = Apollo.QueryResult<AllServicesQuery, AllServicesQueryVariables>;
export const ServiceDocument = gql`
    query Service($id: ID!) {
  service(id: $id) {
    name
    url
    pk
    activeForUsers {
      pk
      firstName
      lastName
      isActive
      role
    }
  }
}
    `;
export type ServiceProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<ServiceQuery, ServiceQueryVariables>
    } & TChildProps;
export function withService<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  ServiceQuery,
  ServiceQueryVariables,
  ServiceProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, ServiceQuery, ServiceQueryVariables, ServiceProps<TChildProps, TDataName>>(ServiceDocument, {
      alias: 'service',
      ...operationOptions
    });
};

/**
 * __useServiceQuery__
 *
 * To run a query within a React component, call `useServiceQuery` and pass it any options that fit your needs.
 * When your component renders, `useServiceQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useServiceQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useServiceQuery(baseOptions: Apollo.QueryHookOptions<ServiceQuery, ServiceQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ServiceQuery, ServiceQueryVariables>(ServiceDocument, options);
      }
export function useServiceLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ServiceQuery, ServiceQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ServiceQuery, ServiceQueryVariables>(ServiceDocument, options);
        }
export type ServiceQueryHookResult = ReturnType<typeof useServiceQuery>;
export type ServiceLazyQueryHookResult = ReturnType<typeof useServiceLazyQuery>;
export type ServiceQueryResult = Apollo.QueryResult<ServiceQuery, ServiceQueryVariables>;
export const DeleteTrialDayDocument = gql`
    mutation DeleteTrialDay($id: ID!) {
  deleteTrialDay(id: $id) {
    found
    deletedInputId
    deletedId
    deletedRawId
  }
}
    `;
export type DeleteTrialDayMutationFn = Apollo.MutationFunction<DeleteTrialDayMutation, DeleteTrialDayMutationVariables>;
export type DeleteTrialDayProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<DeleteTrialDayMutation, DeleteTrialDayMutationVariables>
    } & TChildProps;
export function withDeleteTrialDay<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  DeleteTrialDayMutation,
  DeleteTrialDayMutationVariables,
  DeleteTrialDayProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, DeleteTrialDayMutation, DeleteTrialDayMutationVariables, DeleteTrialDayProps<TChildProps, TDataName>>(DeleteTrialDayDocument, {
      alias: 'deleteTrialDay',
      ...operationOptions
    });
};

/**
 * __useDeleteTrialDayMutation__
 *
 * To run a mutation, you first call `useDeleteTrialDayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTrialDayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTrialDayMutation, { data, loading, error }] = useDeleteTrialDayMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteTrialDayMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTrialDayMutation, DeleteTrialDayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTrialDayMutation, DeleteTrialDayMutationVariables>(DeleteTrialDayDocument, options);
      }
export type DeleteTrialDayMutationHookResult = ReturnType<typeof useDeleteTrialDayMutation>;
export type DeleteTrialDayMutationResult = Apollo.MutationResult<DeleteTrialDayMutation>;
export type DeleteTrialDayMutationOptions = Apollo.BaseMutationOptions<DeleteTrialDayMutation, DeleteTrialDayMutationVariables>;
export const CreateTrialdayDocument = gql`
    mutation CreateTrialday($input: CreateTrialDayInput!) {
  createTrialDay(input: $input) {
    trialDay {
      date
      jobPackage {
        name
        pk
      }
      applicant {
        firstName
        lastName
        email
        pk
      }
      supervisors {
        firstName
        lastName
        email
        pk
      }
      additionalInformation
      pk
      tasks {
        description
        acceptanceCriteria
        help
        pk
      }
    }
  }
}
    `;
export type CreateTrialdayMutationFn = Apollo.MutationFunction<CreateTrialdayMutation, CreateTrialdayMutationVariables>;
export type CreateTrialdayProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<CreateTrialdayMutation, CreateTrialdayMutationVariables>
    } & TChildProps;
export function withCreateTrialday<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  CreateTrialdayMutation,
  CreateTrialdayMutationVariables,
  CreateTrialdayProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, CreateTrialdayMutation, CreateTrialdayMutationVariables, CreateTrialdayProps<TChildProps, TDataName>>(CreateTrialdayDocument, {
      alias: 'createTrialday',
      ...operationOptions
    });
};

/**
 * __useCreateTrialdayMutation__
 *
 * To run a mutation, you first call `useCreateTrialdayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTrialdayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTrialdayMutation, { data, loading, error }] = useCreateTrialdayMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateTrialdayMutation(baseOptions?: Apollo.MutationHookOptions<CreateTrialdayMutation, CreateTrialdayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateTrialdayMutation, CreateTrialdayMutationVariables>(CreateTrialdayDocument, options);
      }
export type CreateTrialdayMutationHookResult = ReturnType<typeof useCreateTrialdayMutation>;
export type CreateTrialdayMutationResult = Apollo.MutationResult<CreateTrialdayMutation>;
export type CreateTrialdayMutationOptions = Apollo.BaseMutationOptions<CreateTrialdayMutation, CreateTrialdayMutationVariables>;
export const UpdateTrialDayDocument = gql`
    mutation UpdateTrialDay($id: ID!, $input: UpdateTrialDayInput!) {
  updateTrialDay(id: $id, input: $input) {
    trialDay {
      date
      jobPackage {
        name
        pk
      }
      applicant {
        firstName
        lastName
        email
        pk
      }
      supervisors {
        firstName
        lastName
        email
        pk
      }
      additionalInformation
      pk
      tasks {
        description
        acceptanceCriteria
        help
        pk
      }
    }
  }
}
    `;
export type UpdateTrialDayMutationFn = Apollo.MutationFunction<UpdateTrialDayMutation, UpdateTrialDayMutationVariables>;
export type UpdateTrialDayProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<UpdateTrialDayMutation, UpdateTrialDayMutationVariables>
    } & TChildProps;
export function withUpdateTrialDay<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateTrialDayMutation,
  UpdateTrialDayMutationVariables,
  UpdateTrialDayProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateTrialDayMutation, UpdateTrialDayMutationVariables, UpdateTrialDayProps<TChildProps, TDataName>>(UpdateTrialDayDocument, {
      alias: 'updateTrialDay',
      ...operationOptions
    });
};

/**
 * __useUpdateTrialDayMutation__
 *
 * To run a mutation, you first call `useUpdateTrialDayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTrialDayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTrialDayMutation, { data, loading, error }] = useUpdateTrialDayMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateTrialDayMutation(baseOptions?: Apollo.MutationHookOptions<UpdateTrialDayMutation, UpdateTrialDayMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateTrialDayMutation, UpdateTrialDayMutationVariables>(UpdateTrialDayDocument, options);
      }
export type UpdateTrialDayMutationHookResult = ReturnType<typeof useUpdateTrialDayMutation>;
export type UpdateTrialDayMutationResult = Apollo.MutationResult<UpdateTrialDayMutation>;
export type UpdateTrialDayMutationOptions = Apollo.BaseMutationOptions<UpdateTrialDayMutation, UpdateTrialDayMutationVariables>;
export const SendTrialdayNotificationDocument = gql`
    mutation SendTrialdayNotification($id: ID) {
  sendTrialDayNotification(id: $id) {
    response
  }
}
    `;
export type SendTrialdayNotificationMutationFn = Apollo.MutationFunction<SendTrialdayNotificationMutation, SendTrialdayNotificationMutationVariables>;
export type SendTrialdayNotificationProps<TChildProps = {}, TDataName extends string = 'mutate'> = {
      [key in TDataName]: Apollo.MutationFunction<SendTrialdayNotificationMutation, SendTrialdayNotificationMutationVariables>
    } & TChildProps;
export function withSendTrialdayNotification<TProps, TChildProps = {}, TDataName extends string = 'mutate'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SendTrialdayNotificationMutation,
  SendTrialdayNotificationMutationVariables,
  SendTrialdayNotificationProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withMutation<TProps, SendTrialdayNotificationMutation, SendTrialdayNotificationMutationVariables, SendTrialdayNotificationProps<TChildProps, TDataName>>(SendTrialdayNotificationDocument, {
      alias: 'sendTrialdayNotification',
      ...operationOptions
    });
};

/**
 * __useSendTrialdayNotificationMutation__
 *
 * To run a mutation, you first call `useSendTrialdayNotificationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendTrialdayNotificationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendTrialdayNotificationMutation, { data, loading, error }] = useSendTrialdayNotificationMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSendTrialdayNotificationMutation(baseOptions?: Apollo.MutationHookOptions<SendTrialdayNotificationMutation, SendTrialdayNotificationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendTrialdayNotificationMutation, SendTrialdayNotificationMutationVariables>(SendTrialdayNotificationDocument, options);
      }
export type SendTrialdayNotificationMutationHookResult = ReturnType<typeof useSendTrialdayNotificationMutation>;
export type SendTrialdayNotificationMutationResult = Apollo.MutationResult<SendTrialdayNotificationMutation>;
export type SendTrialdayNotificationMutationOptions = Apollo.BaseMutationOptions<SendTrialdayNotificationMutation, SendTrialdayNotificationMutationVariables>;
export const AllTrialdaysDocument = gql`
    query AllTrialdays {
  getAllTrialDays {
    date
    jobPackage {
      name
      pk
    }
    applicant {
      firstName
      lastName
      pk
      email
    }
    supervisors {
      firstName
      lastName
      pk
      email
    }
    additionalInformation
    pk
    tasks {
      description
      acceptanceCriteria
      help
      pk
    }
  }
}
    `;
export type AllTrialdaysProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<AllTrialdaysQuery, AllTrialdaysQueryVariables>
    } & TChildProps;
export function withAllTrialdays<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  AllTrialdaysQuery,
  AllTrialdaysQueryVariables,
  AllTrialdaysProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, AllTrialdaysQuery, AllTrialdaysQueryVariables, AllTrialdaysProps<TChildProps, TDataName>>(AllTrialdaysDocument, {
      alias: 'allTrialdays',
      ...operationOptions
    });
};

/**
 * __useAllTrialdaysQuery__
 *
 * To run a query within a React component, call `useAllTrialdaysQuery` and pass it any options that fit your needs.
 * When your component renders, `useAllTrialdaysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAllTrialdaysQuery({
 *   variables: {
 *   },
 * });
 */
export function useAllTrialdaysQuery(baseOptions?: Apollo.QueryHookOptions<AllTrialdaysQuery, AllTrialdaysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AllTrialdaysQuery, AllTrialdaysQueryVariables>(AllTrialdaysDocument, options);
      }
export function useAllTrialdaysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AllTrialdaysQuery, AllTrialdaysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AllTrialdaysQuery, AllTrialdaysQueryVariables>(AllTrialdaysDocument, options);
        }
export type AllTrialdaysQueryHookResult = ReturnType<typeof useAllTrialdaysQuery>;
export type AllTrialdaysLazyQueryHookResult = ReturnType<typeof useAllTrialdaysLazyQuery>;
export type AllTrialdaysQueryResult = Apollo.QueryResult<AllTrialdaysQuery, AllTrialdaysQueryVariables>;
export const TrialDayDocument = gql`
    query TrialDay($id: ID!) {
  getTrialDay(id: $id) {
    pk
    date
    jobPackage {
      pk
      name
      preTrialDayDescription
      preTrialDayDescriptionRendered
      trialDayDescription
      trialDayDescriptionRendered
      taskList {
        pk
        name
        tasks {
          pk
          description
          descriptionRendered
          acceptanceCriteria
          acceptanceCriteriaRendered
          help
          helpRendered
        }
      }
      checklistItems {
        description
        descriptionRendered
        pk
      }
    }
    applicant {
      isSuperuser
      firstName
      lastName
      isStaff
      isActive
      email
      role
      pk
    }
    supervisors {
      isSuperuser
      firstName
      lastName
      isStaff
      isActive
      email
      role
      pk
    }
    additionalInformation
    additionalInformationRendered
    taskList {
      pk
      name
    }
    tasks {
      pk
      description
      descriptionRendered
      acceptanceCriteria
      acceptanceCriteriaRendered
      help
      helpRendered
    }
  }
}
    `;
export type TrialDayProps<TChildProps = {}, TDataName extends string = 'data'> = {
      [key in TDataName]: ApolloReactHoc.DataValue<TrialDayQuery, TrialDayQueryVariables>
    } & TChildProps;
export function withTrialDay<TProps, TChildProps = {}, TDataName extends string = 'data'>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  TrialDayQuery,
  TrialDayQueryVariables,
  TrialDayProps<TChildProps, TDataName>>) {
    return ApolloReactHoc.withQuery<TProps, TrialDayQuery, TrialDayQueryVariables, TrialDayProps<TChildProps, TDataName>>(TrialDayDocument, {
      alias: 'trialDay',
      ...operationOptions
    });
};

/**
 * __useTrialDayQuery__
 *
 * To run a query within a React component, call `useTrialDayQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrialDayQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrialDayQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useTrialDayQuery(baseOptions: Apollo.QueryHookOptions<TrialDayQuery, TrialDayQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrialDayQuery, TrialDayQueryVariables>(TrialDayDocument, options);
      }
export function useTrialDayLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrialDayQuery, TrialDayQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrialDayQuery, TrialDayQueryVariables>(TrialDayDocument, options);
        }
export type TrialDayQueryHookResult = ReturnType<typeof useTrialDayQuery>;
export type TrialDayLazyQueryHookResult = ReturnType<typeof useTrialDayLazyQuery>;
export type TrialDayQueryResult = Apollo.QueryResult<TrialDayQuery, TrialDayQueryVariables>;