import { FormInstance, notification } from "antd";
import React, { useEffect } from "react";
import { RouteComponentProps } from "react-router-dom";
import { compose } from "recompose";
import ApCard from "../../components/base/ap-card";
import { TaskItem, TasklistForm, TasklistFormData } from "../../components/tasks/TaskListForm";
import { apolloResponseErrorHandler } from "../../helper/errors";
import { withAuthentication } from "../../hoc/isAuthendicated";
import BaseLayout from "../../layouts/BaseLayout";
import { TaskListDocument, UpdateTaskListProps, useTaskListQuery, useUpdateTaskListMutation } from "../../models";

interface RouteParams {
    id: string
}

interface OuterProps {}

type Props = OuterProps & UpdateTaskListProps & RouteComponentProps<RouteParams>;

const EditTaskListView: React.FC<Props> = ({ ...props }) => {
    const formRef = React.useRef<FormInstance>();
    const { id }  = props.match.params;

    const query = useTaskListQuery({
        variables: {
            id: id
        },
        onCompleted: (data) => {
            if ( !formRef.current ) return
            formRef.current.setFieldsValue(data.getTaskList)
        }
    });

    const [ mutate ] = useUpdateTaskListMutation({
        refetchQueries: [{ query: TaskListDocument, variables: { id: id } }],
        onCompleted: () => {
            notification.success({
                key: "edit-tasklist-success",
                message: "Task List has been edited successfully"
            })
            props.history.push(`/task-lists/detail/${id}`)
        },
        onError: (err) => apolloResponseErrorHandler(err)
    })

    useEffect(() => {
        if ( query.data?.getTaskList && formRef.current ) formRef.current.setFieldsValue(query.data.getTaskList)
    }, [formRef.current])

    const mappedTasklist = (tasks: TaskItem[]) => (
        tasks.map(item => ({
            description: item.description,
            descriptionRendered: "<p></p>",
            acceptanceCriteria: item.acceptanceCriteria,
            acceptanceCriteriaRendered: "<p></p>",
            help: item.help,
            helpRendered: "<p></p>"
        }))
    )

    const onFormFinish = (formData: TasklistFormData) => {
        const tasklist = formData.tasks ? mappedTasklist(formData.tasks) : [];

        mutate({
            variables: {
                id: id,
                input: {
                    name: formData.name,
                    tasks: tasklist
                }
            }
        })
    }

    return (
        <BaseLayout>
            <ApCard title="Edit Task List">
                <TasklistForm 
                formType="edit"
                onFinish={onFormFinish} 
                ref={formRef as React.RefObject<FormInstance>}/>
            </ApCard>
        </BaseLayout>
    )
}

export default compose<Props, OuterProps>(
    withAuthentication()
)(EditTaskListView)