import Text from "antd/lib/typography/Text";
import React from "react";
import { TrialDayType } from "../../../models";
import ApCard from "../../base/ap-card";
import moment from "moment";
import { Link } from "react-router-dom";

interface OuterProps {
    trialDay: TrialDayType
}

type Props = OuterProps;

class TrialdayInformation extends React.Component<Props> {

    private renderCurrentInformation = () => {
        const { trialDay } = this.props;

        return (
            <ApCard title="Your Trialday">
                <Text className="trialday-information">
                    <p>Hello <strong>{trialDay.applicant.firstName}</strong>,<br/><br/>
                    welcome to your trial day, we are happy to have you here today.<br/>
                    During the trial day you are going to work on these <Link to="/tasks">tasks</Link>.<br/>
                    Feel free to contact your supervisors mentioned below whenever you have any questions.<br/>
                    On the right you can see the schedule for today.</p>
                    <p>You are going to need some applications like GitLab, which are linked as <Link to="/profile">services</Link> in your profile page</p>
                    <p>Best regards and have fun</p><p><em>Yours Webrunners-Team</em></p>
                </Text>
            </ApCard>
        )
    }

    private renderUpcommingInformation = () => {
        const { trialDay } = this.props;

        return (
            <ApCard title="Your Trialday">
                <Text className="trialday-information">
                    <p>Hello <strong>{trialDay.applicant.firstName}</strong>,<br/><br/>
                    we are happy that you have found us and are ready to master your trial day on <strong>{moment(trialDay.date).format("D.M.YYYY")}</strong> at <strong>10:00</strong> o'clock.<br/>
                    If you have any questions you can contact your supervisor at any time.</p>
                    <p>Best regards</p><p><em>Yours Webrunners-Team</em></p>
                </Text>
            </ApCard>
        )
    }

    render () {
        const { trialDay } = this.props

        const today = moment().format('D.M.YYYY');
        const trialDayDate = moment(trialDay.date).format('D.M.YYYY');

        if ( trialDayDate === today ) {
            return this.renderCurrentInformation()
        } else {
            return this.renderUpcommingInformation()
        }
    }
}

export default TrialdayInformation;