import React from "react";
import {Button, Modal} from "antd";
import OnboardingSteps from "./OnboardingSteps";
import { StepList } from "./content/StepList";

import "./OnboardingModal.less"

interface State {
    step: number
}

interface OuterProps {
    modalVisible: boolean
    toggleVisibility: () => void
    keyboardSupport: boolean
}

type Props = OuterProps;

class OnboardingModal extends React.Component<Props, State> {

    state: State = {
        step: 0
    }

    resetStep = () => {
        this.setState({
            step: 0
        });
    }

    incrementStep = () => {
        this.setState({
            step: this.state.step + 1
        })
    }

    decrementStep = () => {
        this.setState({
            step: this.state.step - 1
        })
    }

    changeModalVisibility = () => {
        this.props.toggleVisibility();
        this.resetStep();
    }

    private renderModalFooter = () => {
        const {step} = this.state;

        if ( step < StepList.length - 1 ) {
            if ( step === 0 ) {
                // Skip | Next
                return [
                    <Button size="large" key="skip" onClick={this.changeModalVisibility}>
                        Skip
                    </Button>,
                    <Button size="large" key="next" type="primary" onClick={this.incrementStep}>
                        Next
                    </Button>
                ]
            } else {
                // Skip | Previous | Next
                return [
                    <Button size="large" key="skip" onClick={this.changeModalVisibility}>
                        Skip
                    </Button>,
                    <Button size="large" key="prev" onClick={this.decrementStep}>
                        Previous
                    </Button>,
                    <Button size="large" key="next" type="primary" onClick={this.incrementStep}>
                        Next
                    </Button>
                ]
            }
        } else {
            // Previous | Done
            return [
                <Button size="large" key="prev" onClick={this.decrementStep}>
                    Previous
                </Button>,
                <Button size="large" key="skip" type="primary" onClick={this.changeModalVisibility}>
                    Done
                </Button>
            ]
        }
    }

    render () {
        const { modalVisible, toggleVisibility, keyboardSupport } = this.props;
        return (
            <Modal
            title={
                <img className="onboarding-logo" src={"webrunners_logo_colorized.svg"} alt="Webrunners Logo" />
            }
            className="onboarding-modal"
            visible={modalVisible}
            onCancel={toggleVisibility}
            keyboard={keyboardSupport}
            centered
            width={"90%"}
            footer={this.renderModalFooter()}
            afterClose={this.resetStep}
            >
                <OnboardingSteps step={this.state.step}/>
            </Modal>
        )
    }
}

export default OnboardingModal;